import React, { useEffect } from "react";
import {
  SectionTitle,
  SmallLinkText,
  SmallText,
  SubParent,
  TransactionContainer,
  VerySmallText,
  WalletIcon,
} from "./style";
import { IoIosWallet } from "react-icons/io";
import { theme } from "../../../../theme";
import moment from "moment";
import { selectPayment } from "../../../../ReduxStore/features/paymentSlice";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { selectAdminWallet } from "../../../../ReduxStore/features/Admin/WalletSlice";
import { getAdminFinancialReport } from "../../../../Apis/AdminApis/Wallet";

const Wallet = () => {
  const { gettingWalletBalance, walletBalance } = useAppSelector(selectPayment);
  const { gettingAdminFinancialReport, adminFinancialReport } =
    useAppSelector(selectAdminWallet);

  useEffect(() => {
    getAdminFinancialReport();
  }, []);

  return (
    <SubParent>
      <div className="wallet">
        <WalletIcon src="/assets/images/svg/wallet.svg" />
        <IoIosWallet className="wallet" />
      </div>
      <SectionTitle className="wallet-title">Wallet</SectionTitle>
      <div
        style={{
          padding: "10px",
          width: "100%",
          borderBottom: `1px solid ${theme.colors.borderColor}`,
        }}
      >
        <SmallText>Amount</SmallText>
        <SectionTitle>
          {gettingWalletBalance ? (
            <Loader size={10} color={theme?.colors?.primaryColor} />
          ) : (
            <>
              ₦ <span>{walletBalance ? walletBalance[0]?.balance : 0}</span>
            </>
          )}
        </SectionTitle>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "10px",
          width: "100%",
          // borderBottom: `1px solid ${theme.colors.borderColor}`,
        }}
      >
        <SmallText>Recent Transaction</SmallText>
        {gettingAdminFinancialReport ? (
          <Loader size={10} color={theme?.colors?.primaryColor} />
        ) : (
          adminFinancialReport?.itemsList?.map((report: any) => (
            <TransactionContainer>
              <div className="left-content">
                {report?.transactionType === "credit" && (
                  <img src="/assets/images/svg/credit.svg" alt="credit" />
                )}
                {report?.transactionType === "debit" && (
                  <img src="/assets/images/svg/debit.svg" alt="credit" />
                )}
                <VerySmallText>
                  {moment().format("MMM Do YYYY")} {report?.purpose}
                </VerySmallText>
              </div>
              <div className="right-content">
                <VerySmallText className="bold">
                  ₦ {report?.amount}
                </VerySmallText>
              </div>
            </TransactionContainer>
          ))
        )}
      </div>
      <SmallLinkText to="/reports">View Transaction History</SmallLinkText>
      {/* <PrimaryButton text="Withdraw" size="small" /> */}
      {/* <SmallLinkText to="#">Withdraw Settings</SmallLinkText> */}
    </SubParent>
  );
};

export default Wallet;
