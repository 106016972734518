import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setConfirmingEmailForReset,
  setResettingPassword,
} from "../../ReduxStore/features/authSlice";
import api from "../baseUrl";

export const confirmEmail = async (email: string, navigate: any) => {
  store.dispatch(setConfirmingEmailForReset(true));
  const body = {
    email,
  };
  await api
    .post("user/auth/start-reset-password", body)
    .then((res: any) => {
      const { message } = res?.data;
      toast.success(message);
      store.dispatch(setConfirmingEmailForReset(false));
      navigate("/auth/change-password");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setConfirmingEmailForReset(false));
    });
};

export const resetUserPassword = async (
  password: string,
  code: string,
  navigate: any
) => {
  store.dispatch(setResettingPassword(true));
  const body = {
    password,
    code,
  };
  await api
    .post("user/auth/complete-reset-password", body)
    .then((res: any) => {
      const { message } = res?.data;
      toast.success(message);
      store.dispatch(setResettingPassword(false));
      navigate("/");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setResettingPassword(false));
    });
};
