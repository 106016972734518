import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setBankAccounts,
  setCreatingAccount,
  setGettingBankAccounts,
  setGettingWallet,
  setWallet,
  setAdminFinancialReport,
  setGettingAdminFinancialReport,
} from "../../ReduxStore/features/Admin/WalletSlice";
import api from "../baseUrl";
import { handleModal } from "../../ReduxStore/features/navSlice";
import { setDeletingWallet, setEditingWallet } from "../../ReduxStore/features/SuperAdmin/WalletSlice";

export const getSingleUserWalletBalance = async (userId: string) => {
  const params = {
    userId,
  };
  api
    .get("admin/wallet/wallet-balance", { params })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getInitiateFundWallet = async (amount: number, currencyId: string) => {
  const body = {
    amount,
    currencyId,
  };
  api
    .post("admin/wallet/initiate-fund-wallet", body)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addBankAccount = async (accountNumber: string, bankName: string, accountName: string, visibility: string, description: string) => {
  store.dispatch(setCreatingAccount(true));
  const body = {
    accountNumber,
    bankName,
    accountName,
    visibility,
    description,
  };
  api
    .post("admin/wallet/bank-accounts", body)
    .then((res) => {
      toast.success("Successful");
      store.dispatch(setCreatingAccount(false));
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingAccount(false));
    });
};
export const deleteBankAccount = async (bankAccountId: string) => {
  store.dispatch(setDeletingWallet(true));
  const params = {
    bankAccountId,
  };
  api
    .delete("admin/wallet/bank-accounts", { params })
    .then((res) => {
      store.dispatch(setDeletingWallet(false));
      toast.success("successful");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingWallet(false));
    });
};

export const editBankAccount = async (
  bankAccountId: string,
  accountNumber: string,
  bankName: string,
  accountName: string,
  visibility: string,
  description: string
) => {
  store.dispatch(setEditingWallet(true));
  const body = {
    bankAccountId,
    accountNumber,
    bankName,
    accountName,
    visibility,
    description,
  };
  api
    .put("admin/wallet/bank-accounts", { body })
    .then((res) => {
      store.dispatch(setEditingWallet(false));
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingWallet(false));
    });
};

export const getUserBankAccount = async (visibility: string) => {
  store.dispatch(setGettingBankAccounts(true));
  const params: any = {
    visibility,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  api
    .get("admin/wallet/bank-accounts", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBankAccounts(data?.data));
      store.dispatch(setGettingBankAccounts(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBankAccounts(false));
    });
};
export const getCurrencies = async (status?: string) => {
  store.dispatch(setGettingWallet(true));
  const params = {
    status,
  };
  api
    .get("admin/wallet/currency", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setWallet(data?.data));
    })
    .catch((err) => {
      store.dispatch(setGettingBankAccounts(false));
    });
};

export const getAdminFinancialReport = async (transactionType?: string, status?: string) => {
  store.dispatch(setGettingAdminFinancialReport(true));
  const params = {
    transactionType,
    status,
  };
  api
    .get("admin/transactions", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdminFinancialReport(data?.data));
      store.dispatch(setGettingAdminFinancialReport(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBankAccounts(false));
      store.dispatch(setGettingAdminFinancialReport(false));
    });
};
