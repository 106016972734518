import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getSermonDetails,
  selectAdminChurchSermon,
} from "../../../../ReduxStore/features/Admin/SermonSlice";
import { useState, useEffect } from "react";
import {
  Minister,
  WrittenSermonContainer,
  WrittenSermonContainerWrap,
  Sermon,
} from "./styles";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const WrittenSermonCard = () => {
  const { gettingAdminChurchSermons, adminChurchSermons } = useAppSelector(
    selectAdminChurchSermon
  );
  const [writtenSermon, setWrittenSermon] = useState<any>();
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getSermonDetails({ name: "pageId", value: e.selected + 1 }));
  };

  useEffect(() => {
    const writtenSermonFilter = adminChurchSermons?.itemsList?.filter(
      (x: any) => x.sermonType === "text"
    );
    setWrittenSermon(writtenSermonFilter);
  }, [adminChurchSermons]);

  return (
    <>
      <WrittenSermonContainerWrap>
        {gettingAdminChurchSermons ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : writtenSermon?.length > 0 ? (
          writtenSermon?.map((item: any) => (
            <WrittenSermonContainer>
              <Minister>{item?.title}</Minister>
              <Sermon>{item.sermon}</Sermon>
            </WrittenSermonContainer>
          ))
        ) : (
          <NoRecordDisplay
            message="Written Sermon not Available"
            src="/assets/images/svg/working-remotly.svg"
          />
        )}
      </WrittenSermonContainerWrap>

      <Paginator
        currentPage={adminChurchSermons?.paginator?.currentPage}
        pageCount={adminChurchSermons?.paginator?.pageCount}
        itemCount={adminChurchSermons?.paginator?.itemCount}
        hasNextPage={adminChurchSermons?.paginator?.hasNextPage}
        hasPrevPage={adminChurchSermons?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default WrittenSermonCard;
