/* eslint-disable react/require-default-props */
import { FC, useRef } from "react";
import {
  PageCount,
  PaginationContainer,
  Table,
  TableBody,
  TableContainer,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TableTitle,
} from "./styles";
import Loader from "../Loaders/MoonLoader";
import NoRecordDisplay from "../Messages/NoRecordDisplay";
import ReactPaginate from "react-paginate";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

interface TableProps {
  data?: {
    columns: any;
    rows: any;
  };
  title?: string;
  loading?: Boolean;
  tableId?: string;
  tableRef?: any;
  page?: string;
  searchOptions?: any;
  noRecordText?: string;
  noRecord?: boolean;
  showFilter?: boolean;
  filter?: any;
  ChangePage?: any;
  scrollToTop?: any;
  // paginator
  itemCount?: any;
  perPage?: any;
  pageCount?: any;
  currentPage?: any;
  pagingCounter?: any;
  hasPrevPage?: any;
  hasNextPage?: any;
  prev?: any;
  next?: any;
}

const CustomTable: FC<TableProps> = ({
  data,
  title,
  loading,
  pageCount,
  currentPage,
  tableId,
  tableRef,
  page,
  searchOptions,
  noRecordText,
  noRecord,
  showFilter,
  filter,
  ChangePage,
  scrollToTop,
  itemCount,
  perPage,
  hasNextPage,
  hasPrevPage,
  next,
  pagingCounter,
  prev,
}) => {
  const tableScroll = useRef<any>();
  //   const dispatch = useAppDispatch();
  // const scrollToTop = () => {
  //   tableScroll.current.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };
  //   const ChangePage = (prop: any) => {
  //     const { selected } = prop;
  //     dispatch(setPage({ page, value: selected + 1 }));
  //   };

  return (
    <TableContainer ref={tableScroll}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TableTitle>{title}</TableTitle>
        {showFilter && filter}
      </div>
      {searchOptions}
      {!loading ? (
        <div style={{ width: "100%", overflowX: "auto" }}>
          {!noRecord ? (
            <>
              <Table id={tableId} ref={tableRef}>
                <TableHeader>
                  <TableRow>
                    {data?.columns?.map((column: any, index: number) => (
                      <TableHead
                        key={column + index}
                        // style={{ width: column.width }}
                      >
                        {column?.label}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {data?.rows?.map((row: any, index: number) => (
                    <TableRow key={row + index}>
                      {data?.columns?.map((column: any, index: number) => (
                        <TableData
                          key={column + index}
                          // style={{ width: column.width }}
                        >
                          {row[`${column.field}`]}
                        </TableData>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              ></div>
            </>
          ) : (
            <NoRecordDisplay
              src="/assets/images/svg/working-remotly.svg"
              message={noRecordText}
            />
          )}
        </div>
      ) : (
        // <Loader />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color="#B603C9" size="20px" />
        </div>
      )}
      {pageCount && (
        <PaginationContainer>
          <PageCount>Total Record: {itemCount}</PageCount>
          <ReactPaginate
            previousLabel={hasPrevPage && <PiCaretLeftBold />}
            nextLabel={hasNextPage && <PiCaretRightBold />}
            pageCount={pageCount}
            onPageChange={ChangePage}
            containerClassName={"paginationBttns"}
            activeClassName={"paginationActive"}
            marginPagesDisplayed={4}
            pageRangeDisplayed={4}
            onClick={scrollToTop}
            initialPage={currentPage - 1}
            previousClassName={"prev"}
            nextClassName={"next"}
          />
        </PaginationContainer>
      )}
    </TableContainer>
  );
};

export default CustomTable;
