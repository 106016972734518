/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import {
  HeaderContainer,
  HeaderContainerWrap,
  BibleBook,
  BookList,
  Selection,
  // BibleVersionInner,
  // VersionList,
} from "./styles";
import { FaSortDown } from "react-icons/fa";
import BibleBooks from "./BibleBooks";
// import BibleVersion from "./BibleVersion";
import BibleChapter from "./BibleChapter";
import {
  getBibleChapters,
  getBibleNames,
} from "../../../../Apis/UserApis/Bible";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectBible } from "../../../../ReduxStore/features/BibleSlice";

const WallHeader = () => {
  const { book } = useAppSelector(selectBible);
  const [bibleList, setBibleList] = useState(false);
  const [bibleChapter, setBibleChapter] = useState(false);
  const [bibleVersion, setBibleVersion] = useState(false);
  const [selectedName, setSelectedName] = useState("Genesis");
  const [selectedChapter, setSelectedChapter] = useState(1);

  useEffect(() => {
    getBibleNames(1, 66);
  }, []);

  useEffect(() => {
    getBibleChapters(book);
  }, [book]);

  return (
    <HeaderContainer>
      <HeaderContainerWrap>
        <>
          <BibleBook>
            <BookList>
              <Selection>{selectedName}</Selection>
            </BookList>
            <FaSortDown
              className="booksortdown"
              onClick={() => {
                setBibleList(!bibleList);
                setBibleVersion(false);
                setBibleChapter(false);
              }}
            />
          </BibleBook>
          {bibleList && (
            <BibleBooks
              close={setBibleList}
              setSelectedName={setSelectedName}
            />
          )}
        </>

        <>
          <BibleBook>
            <BookList>
              <Selection>{"Chapter  " + selectedChapter}</Selection>
            </BookList>
            <FaSortDown
              className="booksortdown"
              onClick={() => {
                setBibleList(false);
                setBibleChapter(!bibleChapter);
                setBibleVersion(false);
              }}
            />
          </BibleBook>
          {bibleChapter && (
            <BibleChapter
              close={setBibleChapter}
              setSelectedChapter={setSelectedChapter}
            />
          )}
        </>
        {/* <BibleVersionInner>
          <VersionList>
            <Selection>KJV</Selection>
          </VersionList>
          <FaSortDown
            className="versesortdown"
            onClick={() => {
              setBibleVersion(true);
              setBibleList(false);
            }}
          />
        </BibleVersionInner> */}
        {/* {bibleVersion && <BibleVersion setState={setBibleVersion} />} */}

        {/* <BibleFont>
          <Selection>Aa</Selection>
        </BibleFont> */}
      </HeaderContainerWrap>
    </HeaderContainer>
  );
};

export default WallHeader;
