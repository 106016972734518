import React from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { CreateDailyVerseInput } from "../../Utils/authInput";
import { selectStyles } from "../../Components/Form/inputs/styles";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  getDailyScripturesFormDetails,
  selectUserDailyScriptures,
} from "../../ReduxStore/features/User/DailyScripturesSlice";
import { createDailyScripture } from "../../Apis/UserApis/DailyScriptures";

const CreateDailySCripturesModal = () => {
  const { creatingDailyScripture, bibleName, chapter, verse, scriptureDate } =
    useAppSelector(selectUserDailyScriptures);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getDailyScripturesFormDetails({ name, value }));
  };

  const handleSelect = (value: any, name: string) => {
    dispatch(getDailyScripturesFormDetails({ name, value: value?.value }));
  };

  return (
    <CustomModal
      id={2}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {CreateDailyVerseInput?.map((input: any) => (
            <>
              {input?.type === "select" ? (
                <Select
                  placeholder={input.placeholder}
                  name={input?.name}
                  styles={selectStyles}
                  options={input?.options()}
                  onChange={(value: any) => handleSelect(value, input?.name)}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    min={input?.min}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Add"
            handleClick={(e: any) => {
              e.preventDefault();
              createDailyScripture(bibleName, chapter, verse, scriptureDate);
            }}
            loading={creatingDailyScripture}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateDailySCripturesModal;
