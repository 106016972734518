import React, { FC } from "react";
import { InputContainer, InputText, Label, ParentContainer } from "./styles";

interface TextInputI {
  placeholder?: any;
  value?: any;
  icon?: any;
  altIcon?: any;
  leftIcon?: any;
  name?: string;
  type?: string;
  readonly?: boolean;
  handleChange?: any;
  defaultValue?: any;
  iconState?: any;
  handleIconClick?: any;
  handleAltIconClick?: any;
  min?: any;
  required?: any;
  error?: any;
}

const TextInput: FC<TextInputI> = ({
  value,
  placeholder,
  icon,
  altIcon,
  name,
  type,
  readonly,
  leftIcon,
  handleChange,
  defaultValue,
  iconState,
  handleIconClick,
  handleAltIconClick,
  min,
  error,
  required,
}) => {
  return (
    <ParentContainer>
      <Label>{placeholder}:</Label>
      <InputContainer error={required && error}>
        <span>{leftIcon}</span>
        <InputText
          placeholder={placeholder}
          name={name}
          type={type}
          defaultValue={defaultValue}
          readOnly={readonly}
          onChange={handleChange}
          value={value}
          min={min}
        />
        {icon && !iconState && <span onClick={handleIconClick}>{icon}</span>}
        {altIcon && iconState && (
          <span onClick={handleAltIconClick}>{altIcon}</span>
        )}
      </InputContainer>
    </ParentContainer>
  );
};

export default TextInput;
