import { useEffect } from "react";
import { useAppSelector } from "../../../ReduxStore/app/hooks";
import { selectAdminUserManager } from "../../../ReduxStore/features/Admin/UserManagerSlice";
import { ManageUserTableRow } from "../../../Utils/Admin/tableRows";
import { manageUserTableCols } from "../../../Utils/SuperAdmin/tableCols";
import CustomTable from "../CustomRTable";
import {
  getAdminRoles,
  getAdminUsers,
} from "../../../Apis/AdminApis/userManager";
import { getAdminChurches } from "../../../Apis/AdminApis/ChurchManager";
import { getUserActiveChurch } from "../../../Apis/UserApis/userProfile";
import { selectUser } from "../../../ReduxStore/features/userSlice";

const ManageUserTable = () => {
  const { users, gettingUsers, creatingChurchAdmins } = useAppSelector(
    selectAdminUserManager
  );
  const rows = ManageUserTableRow(users?.itemsList);
  const { currentUser } = useAppSelector(selectUser);

  useEffect(() => {
    getAdminUsers(null, currentUser?.roles[0]?.churchId?._id);
    getAdminRoles();
    getAdminChurches();
    getUserActiveChurch();
  }, [creatingChurchAdmins, currentUser]);

  const data = {
    columns: manageUserTableCols,
    rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        loading={gettingUsers}
        pageCount={users?.paginator?.pageCount}
        itemCount={users?.paginator?.itemCount}
        hasNextPage={users?.paginator?.hasNextPage}
        hasPrevPage={users?.paginator?.hasPrevPage}
      />
    </>
  );
};

export default ManageUserTable;
