import React, { useEffect } from "react";
import { ActionSection, CardsContainer, SectionTitle } from "./style";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../../../Components/Form/inputs/TextInput";
import DonationCard from "../../../../Layouts/DonationsCard";
import { RiSearchLine } from "react-icons/ri";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { selectAdminDonations } from "../../../../ReduxStore/features/Admin/DonationsSlice";
import { getDonation } from "../../../../Apis/AdminApis/donations";
import CreateDonationModal from "../../../../Modals/Admin/CreateDonationModal";
import EditDonationModal from "../../../../Modals/Admin/EditDonationModal";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";

const Donations = () => {
  const { churchDonations, gettingChurchDonations } =
    useAppSelector(selectAdminDonations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getDonation();
  }, []);

  return (
    <>
      <SectionTitle>Donations</SectionTitle>
      <ActionSection>
        <PrimaryButton
          text="Setup Donation"
          size="medium"
          handleClick={(e: any) => {
            e.preventDefault();
            dispatch(handleModal(13));
          }}
        />
        <TextInput placeholder={"Search"} leftIcon={<RiSearchLine />} />
      </ActionSection>
      <CardsContainer>
        {gettingChurchDonations ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color={theme?.colors.primaryColor} size={20} />
          </div>
        ) : (
          <>
            {churchDonations?.itemsList?.length > 0
              ? churchDonations?.itemsList?.map((donation: any) => (
                  <DonationCard donationDetails={donation} />
                ))
              : null}
          </>
        )}
      </CardsContainer>
      <CreateDonationModal />
      <EditDonationModal />
    </>
  );
};

export default Donations;
