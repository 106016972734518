import { FC } from "react";

interface IAvatar {
  setState: any;
}

const Button: FC<IAvatar> = ({ setState }) => {
  return (
    <div>
      <input type="file" id="file" accept=".png,.svg,.jpeg,.peg,.webp" />
    </div>
  );
};

export default Button;
