import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PrimaryButton from "../Components/Form/Buttons/PrimaryButton";
import {
  incrementFeature,
  removeFeature,
} from "../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import { store } from "./../ReduxStore/app/store";

export const memberSignUpInputs = [
  {
    placeholder: "First Name",
    name: "firstName",
    type: "text",
  },
  {
    placeholder: "Last Name",
    name: "lastName",
    type: "text",
  },
  {
    placeholder: "Email",
    name: "registerEmail",
    type: "email",
  },
  {
    placeholder: "Phone",
    name: "phoneNo",
    type: "tel",
  },
  {
    placeholder: "Gender",
    name: "gender",
    type: "select",
    options: [
      {
        id: 1,
        name: "Male",
        value: "M",
      },
      {
        id: 2,
        name: "Female",
        value: "F",
      },
    ],
  },
  {
    placeholder: "Password",
    ActiveIcon: <AiFillEye />,
    InActiveIcon: <AiFillEyeInvisible />,
    name: "registerPassword",
    type: "password",
  },
];

export const adminSignUpInputs = [
  // {
  //   placeholder: "First Name",
  //   type: "text",
  //   name: "adminFirstName",
  // },
  // {
  //   placeholder: "Last Name",
  //   type: "text",
  //   name: "adminLastName",
  // },
  {
    placeholder: "Church Name",
    type: "text",
    name: "churchName",
  },
  {
    placeholder: "Church Denomination",
    name: "denomination",
    type: "select",
    options: [
      {
        id: 1,
        name: "Catholic",
        value: "Catholic",
      },
      {
        id: 2,
        name: "Anglican",
        value: "Anglican",
      },
      {
        id: 3,
        name: "Methodist",
        value: "Methodist",
      },
      {
        id: 4,
        name: "Pentecostal",
        value: "Pentecostal",
      },
    ],
  },
  {
    placeholder: "Church Email",
    type: "email",
    name: "adminEmail",
  },
  {
    placeholder: "Church Address",
    type: "text",
    name: "churchAddress",
  },
  {
    placeholder: "Closest Landmark",
    name: "landmark",
    type: "text",
  },
  {
    type: "country-select",
    countryName: "country",
    stateName: "state",
    cityName: "city",
  },
  // {
  //   placeholder: "Phone Number",
  //   type: "phone",
  //   name: "adminPhone",
  // },
  {
    placeholder: "Church Phone Number",
    type: "phone",
    name: "churchPhoneNo",
    ActiveIcon: "",
  },
  // {
  //   placeholder: "Password",
  //   ActiveIcon: <AiFillEye />,
  //   InActiveIcon: <AiFillEyeInvisible />,
  //   type: "password",
  //   name: "adminPassword",
  // },
];

export const AboutChurchInputs = [
  {
    placeholder: "Church Motto",
    type: "text",
    name: "churchMotto",
  },
  {
    text: "Church Logo",
    type: "file",
    name: "churchLogo",
  },
  {
    placeholder: "Name of Clergy/Priest",
    type: "text",
    name: "clergyName",
  },
  {
    placeholder: "Role of Clergy/Priest",
    type: "text",
    name: "clergyRole",
  },
  {
    placeholder: "Clergy Phone Number",
    type: "phone",
    name: "clergyPhoneNo",
  },
  // {
  //   text: "Add Clergy/Priest",
  //   type: "button",
  //   outlined: true,
  // },
];

export const loginInput = [
  {
    placeholder: "Email",
    type: "email",
    name: "email",
  },
  {
    placeholder: "Password",
    type: "password",
    name: "password",
    ActiveIcon: <AiFillEye />,
    InActiveIcon: <AiFillEyeInvisible />,
  },
];

export const churchVerificationInputs = [
  {
    placeholder: "Church Name",
    type: "text",
  },
  {
    placeholder: "Select Church Denomination",
    type: "text",
  },
  {
    placeholder: "Church Email",
    type: "text",
  },
  {
    placeholder: "Church Address",
    type: "text",
  },
  {
    placeholder: "Closest Landmark",
    type: "text",
  },
  {
    placeholder: "Select City",
    type: "text",
  },
  {
    placeholder: "Select State",
    type: "text",
  },
  {
    placeholder: "Country",
    type: "text",
  },
  {
    placeholder: "Church Phone Number",
    type: "phone",
  },
];

export const createDonationsInput = [
  {
    placeholder: "Title",
    type: "text",
    name: "title",
  },
  {
    placeholder: "Type Of Donation",
    type: "text",
    name: "typeOfDonation",
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "description",
  },
  {
    placeholder: "Target amount",
    type: "number",
    name: "targetAmount",
  },
  {
    placeholder: "Currency Id",
    type: "text",
    name: "currencyId",
  },
  {
    placeholder: "Bank Account Id",
    type: "text",
    name: "bankAccountId",
  },
  {
    placeholder: "Church Group Id",
    type: "text",
    name: "churchGroupId",
  },
];

export const internalSettingsInput = [
  {
    placeholder: "Default SignUp Subscription PackageId",
    type: "text",
    name: "defaultSignupSubscriptionPackageId",
    label: "Default Sign up Subscription Package Id",
    readonly: true,
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.internalSettings
        ?.defaultSignupSubscriptionPackageId;
    },
  },
  {
    placeholder: "Subscription Payment Method",
    type: "text",
    name: "subscriptionPaymentMethod",
    label: "Subscription Payment Method",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.internalSettings
        ?.subscriptionPaymentMethod;
    },
  },
  {
    placeholder: "0",
    type: "number",
    name: "subscriptionPaymentGracePeriodInGraceInDays",
    label: "Subscription Payment Grace Period InGrace InDays",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.internalSettings
        ?.subscriptionPaymentGracePeriodInGraceInDays;
    },
  },
  {
    placeholder: "Default Church Creator RoleId",
    type: "text",
    name: "defaultChurchCreatorRoleId",
    label: "Default Church Creator RoleId",
    readonly: true,
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.internalSettings
        ?.defaultChurchCreatorRoleId;
    },
  },
];
export const webhookInput = [
  {
    placeholder: "defaultSignupSubscriptionPackageId",
    type: "text",
    name: "defaultSignupSubscriptionPackageId",
    label: "Default Sign up Subscription Package Id",
  },
  {
    placeholder: "subscriptionPaymentMethod",
    type: "text",
    name: "subscriptionPaymentMethod",
    label: "Subscription Payment Method",
  },
  {
    placeholder: "subscriptionPaymentGracePeriodInGraceInDays",
    type: "text",
    name: "subscriptionPaymentGracePeriodInGraceInDays",
    label: "Subscription Payment Grace Period InGrace InDays",
  },
  {
    placeholder: "defaultChurchCreatorRoleId",
    type: "text",
    name: "defaultChurchCreatorRoleId",
    label: "Default Church Creator RoleId",
  },
];

export const CreateRoleInput = [
  {
    placeholder: "Role title",
    type: "text",
    name: "roleTitle",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.roleTitle;
    },
  },
  {
    placeholder: "Role description",
    type: "textarea",
    name: "roleDescription",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.roleDescription;
    },
  },

  // {
  //   placeholder: "Status",
  //   name: "roleStatus",
  //   type: "select",
  //   defaultValue: () => {
  //     return store.getState()?.superAdminConfigurations?.roleStatus;
  //   },
  //   options: [
  //     {
  //       id: 1,
  //       name: "Active",
  //       value: "active",
  //     },
  //     {
  //       id: 2,
  //       name: "Disabled",
  //       value: "disabled",
  //     },
  //   ],
  // },
];

export const editRoleInput = [
  {
    placeholder: "Role title",
    type: "text",
    name: "roleTitle",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.roleTitle;
    },
  },
  {
    placeholder: "Role description",
    type: "textarea",
    name: "roleDescription",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.roleDescription;
    },
  },

  {
    placeholder: "Status",
    name: "roleStatus",
    type: "select",
    defaultValue: () => {
      return store.getState()?.superAdminConfigurations?.roleStatus;
    },
    options: [
      {
        id: 1,
        name: "Active",
        value: "active",
      },
      {
        id: 2,
        name: "Disabled",
        value: "disabled",
      },
    ],
  },
];

export const editCurrencyInputs = [
  {
    placeholder: "Currency ID",
    type: "text",
    name: "currencyId",
    defaultValue: () => {
      return store.getState()?.superAdminWallet?.currencyId;
    },
    readonly: true,
  },
  {
    placeholder: "Currency Symbol",
    type: "text",
    name: "currencySymbol",
    defaultValue: () => {
      return store.getState()?.superAdminWallet?.currencySymbol;
    },
  },
  {
    placeholder: "Currency Name",
    type: "text",
    name: "currencyName",
    defaultValue: () => {
      return store.getState()?.superAdminWallet?.currencyName;
    },
  },
  {
    placeholder: "Currency Icon Url",
    type: "text",
    name: "currencyUrl",
    defaultValue: () => {
      return store.getState()?.superAdminWallet?.currencyUrl;
    },
  },
];

export const createCurrencyInputs = [
  {
    placeholder: "Currency Symbol",
    type: "text",
    name: "currencySymbol",
  },
  {
    placeholder: "Currency Name",
    type: "text",
    name: "currencyName",
  },
  {
    placeholder: "Currency Icon Url",
    type: "text",
    name: "currencyUrl",
  },
];

export const editSubscriptionInputs = [
  {
    placeholder: "Title",
    type: "text",
    name: "subscriptionTitle",
    defaultValue: () => {
      return store.getState()?.superAdminSubscriptions?.subscriptionTitle;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "subscriptionDescription",
    defaultValue: () => {
      return store.getState()?.superAdminSubscriptions?.subscriptionDescription;
    },
  },
  {
    placeholder: "Feature",
    type: "text",
    name: "subscriptionFeatures",
    isArray: true,
    defaultValue: () => {
      return store.getState()?.superAdminSubscriptions?.subscriptionFeatures;
    },
    canIncrement: true,
    incrementButton: (
      <PrimaryButton
        text="Add Feature"
        handleClick={(e: any) => {
          e.preventDefault();
          store.dispatch(incrementFeature());
        }}
        size="very-small"
      />
    ),
    decrementButton: (
      <PrimaryButton
        text="Remove Last"
        handleClick={(e: any) => {
          e.preventDefault();
          store.dispatch(removeFeature());
        }}
        size="very-small"
      />
    ),
  },
  {
    placeholder: "Amount",
    type: "number",
    name: "subscriptionAmount",
    defaultValue: () => {
      return store.getState()?.superAdminSubscriptions?.subscriptionAmount;
    },
  },
  {
    placeholder: "Currency",
    type: `select`,
    name: "currencyId",
    options: () => {
      const currencies = store
        ?.getState()
        ?.superAdminWallet?.Wallets?.itemsList?.map((currency: any) => {
          return {
            value: currency?._id,
            label: currency?.symbol,
          };
        });
      return currencies;
    },
  },
  {
    placeholder: "Duration In days",
    type: "number",
    name: "durationInDays",
    defaultValue: () => {
      return store.getState()?.superAdminSubscriptions?.durationInDays;
    },
  },
];

export const createSubscriptionInputs = [
  {
    placeholder: "Title",
    type: "text",
    name: "subscriptionTitle",
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "subscriptionDescription",
  },
  {
    placeholder: "Feature",
    type: "text",
    name: "subscriptionFeatures",
    canIncrement: true,
    incrementButton: (
      <PrimaryButton
        text="Add Feature"
        handleClick={(e: any) => {
          e.preventDefault();
          store.dispatch(incrementFeature());
        }}
        size="very-small"
      />
    ),
    decrementButton: (
      <PrimaryButton
        text="Remove Last"
        handleClick={(e: any) => {
          e.preventDefault();
          store.dispatch(removeFeature());
        }}
        size="very-small"
      />
    ),
  },
  {
    placeholder: "Amount",
    type: "number",
    name: "subscriptionAmount",
  },
  {
    placeholder: "Currency",
    type: `select`,
    name: "currencyId",
    options: () => {
      const currencies = store
        ?.getState()
        ?.superAdminWallet?.Wallets?.itemsList?.map((currency: any) => {
          return {
            value: currency?._id,
            label: currency?.symbol,
          };
        });
      return currencies;
    },
  },
  {
    placeholder: "Duration In days",
    type: "number",
    name: "durationInDays",
  },
];

export const CreatePrayerRequest = [
  {
    placeholder: "Title",
    type: "text",
    name: "prayerTitle",
    defaultValue: () => {
      return store.getState()?.userPrayerWall?.prayerTitle;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "prayerDescription",
    defaultValue: () => {
      return store.getState()?.userPrayerWall?.prayerDescription;
    },
  },
];

export const CreateTestimony = [
  {
    placeholder: "Title",
    type: "text",
    name: "testimonyTitle",
    defaultValue: () => {
      return store.getState()?.userPrayerWall?.testimonyTitle;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "testimonyDescription",
    defaultValue: () => {
      return store.getState()?.userPrayerWall?.testimonyDescription;
    },
  },
];

// admin
export const adminCreateUserInput = [
  {
    placeholder: "First Name",
    type: "text",
    name: "firstName",
  },
  {
    placeholder: "Last Name",
    type: "text",
    name: "lastName",
  },
  {
    placeholder: "Email",
    type: "text",
    name: "email",
  },
  {
    placeholder: "Phone Number",
    type: "phone",
    name: "phoneNo",
  },
  {
    placeholder: "Password",
    type: "password",
    name: "password",
    ActiveIcon: <AiFillEye />,
    InActiveIcon: <AiFillEyeInvisible />,
  },
  {
    placeholder: "Role",
    name: "roleId",
    type: "select",
    options: () => {
      return store.getState()?.adminUserManager?.selectRoles;
    },
  },
];

export const adminCreateAnnouncementInput = [
  {
    placeholder: "Title",
    type: "text",
    name: "announcementTitle",
    defaultValue: () => {
      return store.getState()?.userPrayerWall?.testimonyTitle;
    },
  },
  {
    placeholder: "Enter Announcement",
    type: "textarea",
    name: "announcementText",
  },
  {
    placeholder: "Send to",
    name: "sentTo",
    type: "select",
    options: [
      {
        id: 1,
        name: "Send to members",
        value: "members",
      },
      {
        id: 2,
        name: "Send to Groups",
        value: "groups",
      },
      {
        id: 3,
        name: "Send to All",
        value: "All",
      },
    ],
  },
];
export const adminEditAnnouncementInput = [
  {
    placeholder: "Title",
    type: "text",
    name: "announcementTitle",
    defaultValue: () => {
      return store.getState()?.adminAnnouncements?.announcementTitle;
    },
  },
  {
    placeholder: "Enter Announcement",
    type: "textarea",
    name: "announcementText",
    defaultValue: () => {
      return store.getState()?.adminAnnouncements?.announcementText;
    },
  },
  {
    placeholder: "Send to",
    name: "sentTo",
    type: "select",
    options: [
      {
        id: 1,
        name: "Send to members",
        value: "members",
      },
      {
        id: 2,
        name: "Send to Groups",
        value: "groups",
      },
      {
        id: 3,
        name: "Send to All",
        value: "All",
      },
    ],
  },
];

export const CreateChurchPrayerInput = [
  {
    placeholder: "Title",
    type: "text",
    name: "title",
    // defaultValue: () => {
    //   return store.getState()?.superAdminConfigurations?.roleTitle;
    // },
  },
  {
    placeholder: "Add a verse [optional]",
    type: "text",
    name: "prayerVerse",
    // defaultValue: () => {
    //   return store.getState()?.superAdminConfigurations?.roleTitle;
    // },
  },
  {
    placeholder: "Enter Prayer",
    type: "textarea",
    name: "prayer",
    // defaultValue: () => {
    //   return store.getState()?.superAdminConfigurations?.roleDescription;
    // },
  },
];
export const CreateDailyVerseInput = [
  {
    placeholder: "Select Date",
    type: "date",
    name: "scriptureDate",
  },
  {
    placeholder: "Bible Name",
    type: "select",
    name: "bibleName",
    options: () => {
      const filtered = store
        ?.getState()
        ?.Bible?.bibleNames?.itemsList?.map((book: any) => {
          return {
            value: book?._id,
            label: book?.book,
          };
        });
      return filtered;
    },
  },
  {
    placeholder: "Chapter",
    type: "select",
    name: "chapter",
    options: () => {
      const filtered = store
        ?.getState()
        ?.Bible?.bibleChapters?.itemsList?.map((book: any) => {
          return {
            value: book?.chapter,
            label: book?.chapter,
          };
        });
      return filtered;
    },
  },
  {
    placeholder: "Verse ",
    type: "number",
    min: 1,
    name: "verse",
    options: () => {},
  },
];

export const EditDailyVerseInput = [
  {
    placeholder: "Select Date",
    type: "date",
    name: "scriptureDate",
    defaultValue: () => {
      return new Date(store.getState()?.userDailyScriptures?.scriptureDate);
    },
  },
  {
    placeholder: "Bible Name",
    type: "select",
    name: "bibleName",
    defaultValue: () => {
      return store.getState()?.userDailyScriptures?.bibleName;
    },
    options: () => {
      const filtered = store
        ?.getState()
        ?.Bible?.bibleNames?.itemsList?.map((book: any) => {
          return {
            value: book?.bookId,
            label: book?.book,
          };
        });
      return filtered;
    },
  },
  {
    placeholder: "Chapter",
    type: "select",
    name: "chapter",
    defaultValue: () => {
      return store.getState()?.userDailyScriptures?.chapter;
    },
    options: () => {
      const filtered = store
        ?.getState()
        ?.Bible?.bibleChapters?.itemsList?.map((book: any) => {
          return {
            value: book?.chapter,
            label: book?.chapter,
          };
        });
      return filtered;
    },
  },
  {
    placeholder: "Verse ",
    type: "number",
    min: 1,
    name: "verse",
    defaultValue: () => {
      return store.getState()?.userDailyScriptures?.verse;
    },
    options: () => {},
  },
];

export const createBankAccountInput = [
  {
    placeholder: "Account No",
    type: "text",
    name: "accountNumber",
    defaultValue: () => {
      return store.getState()?.adminWallet?.accountNumber;
    },
  },
  {
    placeholder: "Bank Name",
    type: "text",
    name: "bankName",
    defaultValue: () => {
      return store.getState()?.adminWallet?.bankName;
    },
  },
  {
    placeholder: "Account Name",
    type: "text",
    name: "accountName",
    defaultValue: () => {
      return store.getState()?.adminWallet?.accountName;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "accountDescription",
    defaultValue: () => {
      return store.getState()?.adminWallet?.accountDescription;
    },
  },
  {
    placeholder: "Visibility",
    name: "visibility",
    type: "select",
    options: [
      {
        id: 1,
        name: "Visible",
        value: "visible",
      },
      {
        id: 2,
        name: "Hidden",
        value: "hidden",
      },
    ],
  },
];

export const editBankAccountInput = [
  {
    placeholder: "Account No",
    type: "text",
    name: "accountNumber",
    defaultValue: () => {
      return store.getState()?.adminWallet?.accountNumber;
    },
  },
  {
    placeholder: "Bank Name",
    type: "text",
    name: "bankName",
    defaultValue: () => {
      return store.getState()?.adminWallet?.bankName;
    },
  },
  {
    placeholder: "Account Name",
    type: "text",
    name: "accountName",
    defaultValue: () => {
      return store.getState()?.adminWallet?.accountName;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "accountDescription",
    defaultValue: () => {
      return store.getState()?.adminWallet?.accountDescription;
    },
  },
  {
    placeholder: "Visibility",
    name: "visibility",
    type: "select",
    options: [
      {
        id: 1,
        name: "Visible",
        value: "visible",
      },
      {
        id: 2,
        name: "Hidden",
        value: "hidden",
      },
    ],
  },
];

export const createDonationInput = [
  {
    placeholder: "Title",
    type: "text",
    name: "title",
    defaultValue: () => {
      return store.getState()?.adminDonations?.title;
    },
  },
  {
    placeholder: "Type of Donation",
    type: "text",
    name: "typeOfDonation",
    defaultValue: () => {
      return store.getState()?.adminDonations?.typeOfDonation;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "description",
    defaultValue: () => {
      return store.getState()?.adminDonations?.description;
    },
  },
  {
    placeholder: "Target Amount",
    type: "number",
    name: "targetAmount",
    defaultValue: () => {
      return store.getState()?.adminDonations?.targetAmount;
    },
  },
  {
    placeholder: "Currency Id",
    name: "currencyId",
    type: "select",
    options: [
      {
        id: 1,
        name: "NGN",
        value: "123425465768955432",
      },
      {
        id: 2,
        name: "USD",
        value: "354657453353765868",
      },
    ],
  },
  {
    placeholder: "Bank Account Id",
    name: "bankAccountId",
    type: "select",
    options: [
      {
        id: 1,
        name: "Bank Account 1",
        value: "123425465768955432",
      },
      {
        id: 2,
        name: "Account 2",
        value: "354657453353765868",
      },
    ],
  },
  {
    placeholder: "Curch Group Id",
    name: "churchGroupId",
    type: "select",
    options: [
      {
        id: 1,
        name: "Group1",
        value: "123425465768955432",
      },
      {
        id: 2,
        name: "Group2",
        value: "354657453353765868",
      },
    ],
  },
];

export const editDonationInput = [
  {
    placeholder: "Title",
    type: "text",
    name: "title",
    defaultValue: () => {
      return store.getState()?.adminDonations?.title;
    },
  },
  {
    placeholder: "Type of Donation",
    type: "text",
    name: "typeOfDonation",
    defaultValue: () => {
      return store.getState()?.adminDonations?.typeOfDonation;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "description",
    defaultValue: () => {
      return store.getState()?.adminDonations?.description;
    },
  },
  {
    placeholder: "Target Amount",
    type: "number",
    name: "targetAmount",
    defaultValue: () => {
      return store.getState()?.adminDonations?.targetAmount;
    },
  },
  {
    placeholder: "Currency Id",
    name: "currencyId",
    type: "select",
    options: [
      {
        id: 1,
        name: "NGN",
        value: "123425465768955432",
      },
      {
        id: 2,
        name: "USD",
        value: "354657453353765868",
      },
    ],
  },
  {
    placeholder: "Bank Account Id",
    name: "bankAccountId",
    type: "select",
    options: [
      {
        id: 1,
        name: "Bank Account 1",
        value: "123425465768955432",
      },
      {
        id: 2,
        name: "Account 2",
        value: "354657453353765868",
      },
    ],
  },
  {
    placeholder: "Church Group Id",
    name: "churchGroupId",
    type: "select",
    options: [
      {
        id: 1,
        name: "Group1",
        value: "123425465768955432",
      },
      {
        id: 2,
        name: "Group2",
        value: "354657453353765868",
      },
    ],
  },
];

export const editGroupInputs = [
  {
    placeholder: "Group Name",
    type: "text",
    name: "name",
    defaultValue: () => {
      return store.getState().adminChurchGroup.name;
    },
  },
  {
    placeholder: "Group Description",
    type: "textarea",
    name: "description",
    defaultValue: () => {
      return store.getState().adminChurchGroup.description;
    },
  },
];

export const createGroupInputs = [
  {
    placeholder: "Group Name",
    type: "text",
    name: "name",
  },
  {
    placeholder: "Group Description",
    type: "textarea",
    name: "description",
  },
  {
    placeholder: "Members",
    name: "memberId",
    type: "multi-select",
    options: () => {
      const filtered = store
        ?.getState()
        ?.adminManageChurches?.adminChurchMembers?.itemsList?.map(
          (member: any) => {
            return {
              value: member?.userId?._id,
              label: member?.userId?.lastName + member?.userId?.firstName,
            };
          }
        );
      return filtered;
    },
  },
  {
    placeholder: "Join Status",
    name: "joinStatus",
    type: "select",
    options: [
      {
        id: 1,
        name: "Public",
        value: "public",
      },
      {
        id: 2,
        name: "Private",
        value: "private",
      },
    ],
  },
];

export const createLiveStreamEventInputs = [
  {
    placeholder: "Title",
    type: "text",
    name: "title",
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "description",
  },
  {
    placeholder: "Auto Generate Link",
    name: "visibility",
    type: "select",
    options: [
      {
        id: 1,
        name: "Auto Generate Link",
        value: true,
      },
      {
        id: 2,
        name: "False",
        value: false,
      },
    ],
  },
  {
    placeholder: "Link Provider List",
    name: "memberId",
    type: "multi-select",
    options: () => {
      const filtered = store
        ?.getState()
        ?.adminManageChurches?.adminChurchMembers?.itemsList?.map(
          (member: any) => {
            return {
              value: member?.userId?._id,
              label: member?.userId?.lastName + member?.userId?.firstName,
            };
          }
        );
      return filtered;
    },
  },
  {
    placeholder: "Visibility",
    name: "visibility",
    type: "select",
    options: [
      {
        id: 1,
        name: "Visible",
        value: "visible",
      },
      {
        id: 2,
        name: "Hidden",
        value: "hidden",
      },
    ],
  },
  {
    placeholder: "Start Date",
    type: "date",
    name: "startTime",
  },
];

export const editLiveStreamEventInputs = [
  {
    placeholder: "Title",
    type: "text",
    name: "title",
    defaultValue: () => {
      return store.getState().adminLiveStreams.selectedLiveStreamEvent?.title;
    },
  },
  {
    placeholder: "Description",
    type: "textarea",
    name: "description",
    defaultValue: () => {
      return store.getState().adminLiveStreams.selectedLiveStreamEvent
        ?.description;
    },
  },
  {
    placeholder: "Visibility",
    name: "visibility",
    type: "select",
    options: [
      {
        id: 1,
        name: "Visible",
        value: "visible",
      },
      {
        id: 2,
        name: "Hidden",
        value: "hidden",
      },
    ],
    defaultValue: () => {},
  },
  {
    placeholder: "Start Date",
    type: "date",
    name: "startDate",
    defaultValue: () => {},
  },
];

export const createLiveStreamLinkInput = [
  {
    placeholder: "Live Stream ID",
    type: "text",
    name: "livestreamId",
  },
  {
    placeholder: "Live Stream Provider ID",
    type: "text",
    name: "livestreamProviderId",
  },
  {
    placeholder: "Live Stream Link",
    type: "text",
    name: "link",
  },
];
export const editLiveStreamLinkInput = [
  {
    placeholder: "Live Stream ID",
    type: "text",
    name: "livestreamId",
    defaultValue: () => {
      return store.getState()?.adminLiveStreams?.livestreamId;
    },
  },
  {
    placeholder: "Live Stream Provider ID",
    type: "text",
    name: "livestreamProviderId",
    defaultValue: () => {
      return store.getState()?.adminLiveStreams?.livestreamProviderId;
    },
  },
  {
    placeholder: "Live Stream Link",
    type: "text",
    name: "link",
    defaultValue: () => {
      return store.getState()?.adminLiveStreams?.link;
    },
  },
];

export const addLocationInputs = [
  {
    placeholder: "Address",
    type: "text",
    name: "locationAddress",
    defaultValue: () => {
      return store.getState()?.adminActivities?.locationAddress;
    },
  },
  {
    placeholder: "Medium",
    name: "locationMedium",
    type: "select",
    options: () => {
      const filtered = store
        ?.getState()
        ?.adminActivities?.eventMediums?.itemsList?.map((medium: any) => {
          return {
            value: medium?._id,
            label: medium?.name,
          };
        });
      return filtered;
    },
  },
];

export const advertStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const adminCurrencies = () => {
  const filtered = store
    ?.getState()
    ?.adminWallet?.Wallets?.itemsList?.map((currency: any) => {
      return {
        value: currency?._id,
        label: currency?.symbol,
      };
    });
  return filtered;
};

export const inviteGroupMemberInput = [
  {
    placeholder: "Choose Member",
    name: "inviteeEmail",
    type: "select",
    options: () => {
      const filtered = store
        ?.getState()
        ?.adminManageChurches?.adminChurchMembers?.itemsList?.map(
          (member: any) => {
            return {
              value: member?.userId?.email,
              label: member?.userId?.lastName + member?.userId?.firstName,
            };
          }
        );
      return filtered;
    },
  },
];

export const churchReflectionInput = [
  {
    placeholder: "Title",
    name: "reflectionTitle",
    type: "text",
  },
  {
    placeholder: "Date",
    name: "reflectionDate",
    type: "date",
  },
  {
    placeholder: "Reflection",
    name: "text",
    type: "textarea",
  },
  {
    placeholder: "status",
    name: "reflectionStatus",
    type: "select",
    options: () => {
      return [
        { label: "Active", value: "active" },
        { label: "Archive", value: "archive" },
      ];
    },
  },
];

export const editChurchReflectionInput = [
  {
    placeholder: "Title",
    name: "reflectionTitle",
    type: "text",
    defaultValue: () => {
      return store.getState()?.adminChurchPrayerWall?.reflectionTitle;
    },
  },
  {
    placeholder: "Date",
    name: "reflectionDate",
    type: "date",
    defaultValue: () => {
      // return store.getState()?.adminChurchPrayerWall?.text;
    },
  },
  {
    placeholder: "Reflection",
    name: "text",
    type: "textarea",
    defaultValue: () => {
      return store.getState()?.adminChurchPrayerWall?.text;
    },
  },
  {
    placeholder: "status",
    name: "reflectionStatus",
    type: "select",
    defaultValue: () => {
      return store.getState()?.adminChurchPrayerWall?.reflectionStatus;
    },
    options: () => {
      return [
        { label: "Active", value: "active" },
        { label: "Archive", value: "archive" },
      ];
    },
  },
];

// posts

export const createUserPostInputs = [
  {
    placeholder: "Title",
    name: "title",
    type: "text",
  },
  {
    placeholder: "Description",
    name: "description",
    type: "textarea",
  },
  {
    placeholder: "Add Media",
    name: "file",
    type: "file",
  },
];

export const editUserPostInputs = [
  {
    placeholder: "Title",
    name: "title",
    type: "text",
    defaultValue: () => {
      return store.getState()?.userPosts?.title;
    },
  },
  {
    placeholder: "Description",
    name: "description",
    type: "textarea",
    defaultValue: () => {
      return store.getState()?.userPosts?.description;
    },
  },
  {
    placeholder: "Add Media",
    name: "file",
    type: "file",
    defaultValue: () => {
      return store.getState()?.userPosts?.file;
    },
  },
];
