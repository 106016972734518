
import WallHeader from "./WallHeader";
import {
  IndexWrap,
} from "./styles";
import BibleReading from "./BibleReading";

const Bible = () => {
  return (
    <IndexWrap>
      <div className="left-content" style={{ width: "100%" }}>
        <WallHeader />
        <BibleReading />
      </div>
    </IndexWrap>
  );
};

export default Bible;
