import React from "react";
import { LeftContentContainer, UserProfile } from "./style";
import { useAppSelector } from "../../../../../ReduxStore/app/hooks";
import { selectUserProfile } from "../../../../../ReduxStore/features/User/UserProfileSlice";
import ImageCarousel from "../../../../../Components/Reusables/Carousel/ImageCarousel";
import VideoCarousel from "../../../../../Components/Reusables/Carousel/VideoCarousel";

const LeftContent = () => {
  const { userProfile } = useAppSelector(selectUserProfile);

  return (
    <LeftContentContainer>
      <UserProfile>
        <img
          src="https://th.bing.com/th/id/R.4e9a9213eb6cacc05b42ead4c364aef8?rik=e%2frEilzC8bkv3g&pid=ImgRaw&r=0"
          alt="User Avatar"
        />
        <h3>
          {userProfile?.userId?.firstName + " " + userProfile?.userId?.lastName}
        </h3>
        <p>{userProfile?.userId?.userType}</p>
      </UserProfile>
    </LeftContentContainer>
  );
};

export default LeftContent;
