import React, { FC, useEffect } from "react";
import { CenterContentContainer } from "./styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxStore/app/hooks";
import {
  getPostFormDetails,
  selectUserPost,
} from "../../../../../ReduxStore/features/User/UserPostSlice";
import SinglePostCard from "../../../../../Layouts/PostCard";

interface CenterContentProps {
  postFunction: any;
  postType: string;
}

const CenterContent: FC<CenterContentProps> = ({ postFunction, postType }) => {
  const {
    approvedPosts,
    // gettingApprovedPost,
    page,
    likingAndDislikingUserPOst,
    commentingUserPost,
    updatingUserPost,
    creatingUserPost,
  } = useAppSelector(selectUserPost);
  const dispatch = useAppDispatch();

  useEffect(() => {
    postFunction();
  }, [
    page,
    likingAndDislikingUserPOst,
    commentingUserPost,
    updatingUserPost,
    creatingUserPost,
  ]);

  const handleScroll = () => {
    console.log(window.innerHeight);
    console.log(document.documentElement.scrollTop);
    console.log(document.documentElement.scrollHeight);

    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      dispatch(getPostFormDetails({ name: "page", value: page + 1 }));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <CenterContentContainer>
      {approvedPosts?.docs?.map((post: any, index: number) => (
        <SinglePostCard
          username={post?.userId?.firstName + " " + post?.userId?.lastName}
          avatar={
            "https://th.bing.com/th/id/R.4e9a9213eb6cacc05b42ead4c364aef8?rik=e%2frEilzC8bkv3g&pid=ImgRaw&r=0"
          }
          content={post?.description}
          media={post?.contentUrl}
          likes={post?.likes}
          disLikes={post?.dislikes}
          comments={post?.comments}
          hashTags={post?.hashtags}
          contentType={post?.contentType}
          title={post?.title}
          createdAt={post?.createdAt}
          postId={post?._id}
          postType={postType}
        />
      ))}
    </CenterContentContainer>
  );
};

export default CenterContent;
