import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface GroupsState {
  gettingChurchGroups: boolean;
  creatingGroup: boolean;
  editingGroup: boolean;
  deletingGroup: boolean;
  deletingGroupMembers: boolean;
  churchGroups: any;
  selectedGroup: any;
  gettingGroupDetails: boolean;
  groupDetails: any;

  // create Groups state
  groupId: "";
  groupStatus: string;
  membersCount: number;
  name: string;
  description: string;
  joinStatus: string;
  memberId: any[];
  inviteeEmail: string;
  invitingMember: boolean;

  // action state
  creatingChurchGroup: boolean;
}

const initialState: GroupsState = {
  gettingChurchGroups: false,
  deletingGroup: false,
  deletingGroupMembers: false,
  editingGroup: false,
  creatingGroup: false,
  churchGroups: null,
  selectedGroup: null,
  gettingGroupDetails: false,
  groupDetails: null,

  // create Groups state
  groupId: "",
  groupStatus: "",
  membersCount: 0,
  name: "",
  description: "",
  joinStatus: "public",
  memberId: [],
  inviteeEmail: ``,
  invitingMember: false,

  // action state
  creatingChurchGroup: false,
};

export const GroupsSlice = createSlice({
  name: "Groups",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurchGroups: (state, action) => {
      state.gettingChurchGroups = action.payload;
    },
    setChurchGroups: (state, action) => {
      state.churchGroups = action.payload;
    },

    setGettingGroupsDetails: (state, action) => {
      state.gettingGroupDetails = action.payload;
    },
    setInvitingGroupMember: (state, action) => {
      state.invitingMember = action.payload;
    },
    setGroupsDetails: (state, action) => {
      state.groupDetails = action.payload;
      state.name = action.payload?.churchGroupDetails?.name;
      state.description = action.payload?.churchGroupDetails?.description;
    },
    setCreatingChurchGroup: (state, action) => {
      state.creatingChurchGroup = action.payload;
    },
    setEditingChurchGroup: (state, action) => {
      state.editingGroup = action.payload;
    },
    setDeletingChurchGroup: (state, action) => {
      state.deletingGroup = action.payload;
    },
    setDeletingChurchGroupMember: (state, action) => {
      state.deletingGroupMembers = action.payload;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
      state.groupId = action.payload?._id;
      state.groupStatus = action.payload?.status;
      state.membersCount = action.payload?.membersCount;
    },

    getGroupDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingChurchGroups,
  setInvitingGroupMember,
  setChurchGroups,
  getGroupDetails,
  setCreatingChurchGroup,
  setDeletingChurchGroup,
  setSelectedGroup,
  setEditingChurchGroup,
  setGettingGroupsDetails,
  setDeletingChurchGroupMember,
  setGroupsDetails,
} = GroupsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.Groupser.value)`
export const selectAdminGroups = (state: RootState) => state.adminChurchGroup;

export default GroupsSlice.reducer;
