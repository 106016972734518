import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getWalletFormDetails,
  selectAdminWallet,
} from "../../ReduxStore/features/Admin/WalletSlice";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import { TextArea } from "../../Components/Form/inputs/styles";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { createDonationInput } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { editBankAccount } from "../../Apis/AdminApis/Wallet";

const CreateDonationModal = () => {
  const {
    accountName,
    bankName,
    accountNumber,
    accountVisibility,
    accountDescription,
    editingWallet,
    selectedWallet,
  } = useAppSelector(selectAdminWallet);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };
  return (
    <CustomModal
      id={13}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {createDonationInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  value={input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options}
                  name={input?.name}
                  handleSelect={handleSelect}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              editBankAccount(
                selectedWallet._id,
                accountNumber,
                bankName,
                accountName,
                accountVisibility,
                accountDescription
              );
            }}
            loading={editingWallet}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateDonationModal;
