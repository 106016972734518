import styled from "styled-components";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getAdvertDetails,
  selectAdminChurchAdvert,
} from "../../../../ReduxStore/features/Admin/AdvertSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import PostCard from "./PostCard";
import { deleteAdvert, getAdverts } from "../../../../Apis/AdminApis/Adverts";
import Paginator from "../../../../Components/Reusables/Pagintaor";
import { useEffect } from "react";
// import api from "../../../../Apis/baseUrl";

const PubAdContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;
export const Ad1Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  grid-gap: 20px;

  span {
    font-size: 12px;
  }
`;

const PublishedAds = () => {
  const {
    adminChurchAdverts,
    gettingAdminChurchAdverts,
    deletingChurchAdvert,
    page,
    pageSize,
  } = useAppSelector(selectAdminChurchAdvert);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getAdvertDetails({ name: "page", value: e.selected + 1 }));
  };

  useEffect(() => {
    getAdverts("", page, pageSize);
  }, [page]);

  return (
    <PubAdContainer>
      {gettingAdminChurchAdverts ? (
        <Loader size={20} color={theme.colors.primaryColor} />
      ) : (
        <>
          {adminChurchAdverts?.itemsList?.length > 0 ? (
            <Ad1Container>
              {adminChurchAdverts?.itemsList?.map((advert: any) => (
                <PostCard
                  link={advert?.link}
                  imageUrl={advert?.imageUrl}
                  title={advert?.title}
                  startDate={advert?.startDate}
                  endDate={advert?.endDate}
                  description={advert?.description}
                  key={advert?._id}
                  handleDelete={() => deleteAdvert(advert?._id)}
                  deleting={deletingChurchAdvert}
                />
              ))}
            </Ad1Container>
          ) : (
            <NoRecordDisplay
              message="No Published Advert Available"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}
      <Paginator
        currentPage={adminChurchAdverts?.paginator?.currentPage}
        pageCount={adminChurchAdverts?.paginator?.pageCount}
        itemCount={adminChurchAdverts?.paginator?.itemCount}
        hasNextPage={adminChurchAdverts?.paginator?.hasNextPage}
        hasPrevPage={adminChurchAdverts?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </PubAdContainer>
  );
};

export default PublishedAds;
