import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import { TextArea, selectStyles } from "../../Components/Form/inputs/styles";
import { createGroupInputs } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import {
  getGroupDetails,
  selectAdminGroups,
} from "../../ReduxStore/features/Admin/GroupSlice";
import { createGroup } from "../../Apis/AdminApis/churchGroup";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import { getUserActiveChurch } from "../../Apis/UserApis/userProfile";
import Select from "react-select";
import { getAdminChurcheMembers } from "../../Apis/AdminApis/ChurchManager";

const CreateGroupModal = () => {
  const dispatch = useAppDispatch();
  const { name, description, joinStatus, memberId, creatingChurchGroup } =
    useAppSelector(selectAdminGroups);
  const { userProfile } = useAppSelector(selectUserProfile);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  const handleMultiSelect = (value: any, name: any) => {
    const realValue = value.map((item: any) => {
      return item?.value;
    });
    dispatch(getGroupDetails({ name, value: realValue }));
  };

  useEffect(() => {
    getUserActiveChurch();
  }, []);

  useEffect(() => {
    getAdminChurcheMembers(userProfile?.churchId?._id);
  }, [userProfile]);

  return (
    <CustomModal
      id={16}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {createGroupInputs?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options}
                  name={input?.name}
                  handleSelect={handleSelect}
                />
              ) : input?.type === "multi-select" ? (
                <Select
                  isMulti
                  options={input?.options()}
                  name={input?.name}
                  onChange={(e: any) => handleMultiSelect(e, input?.name)}
                  styles={selectStyles}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={(e: any) => {
              e.preventDefault();
              createGroup(
                userProfile?.churchId?._id,
                name,
                joinStatus,
                description,
                memberId
              );
            }}
            loading={creatingChurchGroup}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateGroupModal;
