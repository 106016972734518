import React, { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { selectUserPost } from "../../../../ReduxStore/features/User/UserPostSlice";
import {
  getApprovedPosts,
  getPostsByChurch,
} from "../../../../Apis/UserApis/Post";
import { selectUser } from "../../../../ReduxStore/features/userSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import { CardsContainer } from "../Prayers/style";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import SinglePostCard from "../../../../Layouts/PostCard";
import Paginator from "../../../../Components/Reusables/Pagintaor";
import { getPostFormDetails } from "../../../../ReduxStore/features/Admin/AdminPostSlice";

const AllPosts = () => {
  const { postsByChurch, gettingPostsByChurch, page, pageSize } =
    useAppSelector(selectUserPost);
  const { currentUser } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getPostFormDetails({ name: "page", value: e.selected + 1 }));
  };

  useEffect(() => {
    getPostsByChurch(currentUser?.roles[0]?.churchId?._id, pageSize, page);
  }, [page]);

  return (
    <>
      {gettingPostsByChurch ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader color={theme?.colors.primaryColor} size={20} />
        </div>
      ) : (
        <>
          {postsByChurch?.docs?.length > 0 ? (
            <CardsContainer>
              {postsByChurch?.docs?.map((post: any) => (
                <SinglePostCard
                  username={
                    post?.userId?.firstName + " " + post?.userId?.lastName
                  }
                  avatar={
                    "https://th.bing.com/th?id=OIP.L8bs33mJBAUBA01wBfJnjQHaHa&w=250&h=250&c=8&rs=1&qlt=30&o=6&pid=3.1&rm=2"
                  }
                  content={post?.description}
                  media={post?.contentUrl}
                  likes={post?.likes}
                  disLikes={post?.dislikes}
                  comments={post?.comments}
                  hashTags={post?.hashtags}
                  contentType={post?.contentType}
                  title={post?.title}
                  createdAt={post?.createdAt}
                  postId={post?._id}
                />
              ))}
            </CardsContainer>
          ) : (
            <NoRecordDisplay
              message="No Post at the moment"
              src="/assets/images/svg/working-remotly.svg"
            />
          )}
        </>
      )}

      <Paginator
        currentPage={postsByChurch?.page}
        pageCount={postsByChurch?.totalPages}
        itemCount={postsByChurch?.totalDocs}
        hasNextPage={postsByChurch?.hasNextPage}
        hasPrevPage={postsByChurch?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default AllPosts;
