import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setCreatingChurchAdmins,
  setDeletingChurchAdmin,
  setGettingRoles,
  setGettingUsers,
  setRoles,
  setUsers,
} from "../../ReduxStore/features/Admin/UserManagerSlice";
import api from "../baseUrl";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const getAdminUsers = async (
  userId?: string | null,
  churchId?: string,
  status?: string,
  lastUpdatedBy?: number,
  createdAt?: number
) => {
  store.dispatch(setGettingUsers(true));
  const params: any = {
    userId,
    churchId,
    status,
    lastUpdatedBy,
    createdAt,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  api
    .get("admin/roles/admin-users", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setUsers(data?.data));
      store.dispatch(setGettingUsers(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingUsers(false));
    });
};

export const getAdminRoles = async () => {
  store.dispatch(setGettingRoles(true));
  api
    .get("admin/roles/retrieve-app-roles")
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setRoles(data?.data));
      store.dispatch(setGettingRoles(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingRoles(false));
    });
};

export const createAdminUsers = async (
  roleId?: string,
  churchId?: string,
  email?: string,
  password?: string,
  firstName?: string,
  lastName?: string,
  phoneNo?: string
) => {
  store.dispatch(setCreatingChurchAdmins(true));
  const body: any = {
    roleId,
    churchId,
    email,
    password,
    firstName,
    lastName,
    phoneNo,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  api
    .post("admin/roles/add-admin-to-church", body)
    .then((res) => {
      store.dispatch(setCreatingChurchAdmins(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingChurchAdmins(false));
    });
};

export const deleteAdminUsers = async (
  roleId?: string,
  churchRoleId?: string
) => {
  store.dispatch(setDeletingChurchAdmin(true));
  const body: any = {
    roleId,
    churchRoleId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  api
    .delete("admin/roles/remove-admin-from-church", { data: body })
    .then((res) => {
      store.dispatch(setDeletingChurchAdmin(false));
      toast.success("Successful");
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingChurchAdmin(false));
    });
};
