import { useEffect } from "react";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { TextArea } from "../../Components/Form/inputs/styles";
import { adminCreateAnnouncementInput } from "../../Utils/authInput";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import {
  getAdminAnnouncementFormDetails,
  selectAdminAnnouncements,
} from "../../ReduxStore/features/Admin/AnnouncementSlice";
import { CreateAnnouncement } from "../../Apis/AdminApis/churchAnnouncement";
import { getAdminChurch } from "../../Apis/AdminApis/ChurchActivities";
import { selectAdminActivities } from "../../ReduxStore/features/Admin/ActivitiesSlice";

const CreateAnnouncementModal = () => {
  const { announcementTitle, groupId, announcementText, creatingAnnouncement } =
    useAppSelector(selectAdminAnnouncements);
  const { adminChurch } = useAppSelector(selectAdminActivities);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminAnnouncementFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminAnnouncementFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminAnnouncementFormDetails({ name, value }));
  };

  useEffect(() => {
    getAdminChurch();
  }, []);

  return (
    <CustomModal
      id={8}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {adminCreateAnnouncementInput?.map((input) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options}
                  name={input?.name}
                  handleSelect={handleSelect}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={(e: any) => {
              e.preventDefault();
              CreateAnnouncement(
                adminChurch?._id,
                groupId,
                announcementTitle,
                announcementText
              );
            }}
            loading={creatingAnnouncement}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateAnnouncementModal;
