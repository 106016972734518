import React, { useState } from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import {
  CreatePostIcon,
  HomeContainer,
  PostType,
  PostTypeContainer,
  PostTypeWrapper,
} from "./styles";
// import OtherContents from "./Others";
import CenterContent from "./CenterContent";
import { BsPlusCircleFill } from "react-icons/bs";
import CreateUserPostsModal from "../../../../Modals/user/CreatePostModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import { selectUserPost } from "../../../../ReduxStore/features/User/UserPostSlice";
import {
  getApprovedPosts,
  getMyPosts,
  getPostsByChurch,
  getTrendingPosts,
} from "../../../../Apis/UserApis/Post";
import { selectUser } from "../../../../ReduxStore/features/userSlice";
import EditUserPostModal from "../../../../Modals/user/EditPostModal";

const Home = () => {
  const { page, pageSize } = useAppSelector(selectUserPost);
  const { currentUser } = useAppSelector(selectUser);

  const [currentPost, setCurrentPost] = useState(1);
  const dispatch = useAppDispatch();

  return (
    <>
      <PostTypeContainer>
        <PostTypeWrapper>
          <PostType
            className={`${currentPost === 1 && "active"}`}
            onClick={() => setCurrentPost(1)}
          >
            All
          </PostType>
          <PostType
            className={`${currentPost === 2 && "active"}`}
            onClick={() => setCurrentPost(2)}
          >
            Trending
          </PostType>
          <PostType
            className={`${currentPost === 3 && "active"}`}
            onClick={() => setCurrentPost(3)}
          >
            MyPosts
          </PostType>
          <PostType
            className={`${currentPost === 4 && "active"}`}
            onClick={() => setCurrentPost(4)}
          >
            MyChurch
          </PostType>
        </PostTypeWrapper>
      </PostTypeContainer>
      <HomeContainer>
        <LeftContent />
        {currentPost === 1 && (
          <CenterContent
            postFunction={() => getApprovedPosts(pageSize, page)}
            postType=""
          />
        )}
        {currentPost === 2 && (
          <CenterContent
            postFunction={() => getTrendingPosts(pageSize, page)}
            postType=""
          />
        )}
        {currentPost === 3 && (
          <CenterContent
            postFunction={() =>
              getMyPosts(currentUser?.roles[0]?.churchId?._id, pageSize, page)
            }
            postType="myPost"
          />
        )}
        {currentPost === 4 && (
          <CenterContent
            postFunction={() =>
              getPostsByChurch(
                currentUser?.roles[0]?.churchId?._id,
                pageSize,
                page
              )
            }
            postType=""
          />
        )}
        <RightContent />
      </HomeContainer>
      {/* <OtherContents /> */}
      <CreatePostIcon onClick={() => dispatch(handleModal(4))}>
        <BsPlusCircleFill />
      </CreatePostIcon>

      {/* modal */}
      <CreateUserPostsModal />
      <EditUserPostModal />
    </>
  );
};

export default Home;
