import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setCurrentSubscription,
  setGettingCurrentSubscriptions,
  setGettingPaymentHistory,
  setGettingSubscriptionHistory,
  setGettingSubscriptions,
  setPaymentHistory,
  setSubscriptionHistory,
  setSubscriptions,
} from "../../ReduxStore/features/SuperAdmin/subcriptionsSlice";
import api from "../baseUrl";

export const getUserSubscription = async (
  pageId?: string,
  startDate?: number,
  endDate?: number
) => {
  store.dispatch(setGettingSubscriptions(true));
  const params: any = {
    pageId,
    startDate,
    endDate,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  api
    .get("admin/subscription", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setSubscriptions(data?.data));
      store.dispatch(setGettingSubscriptions(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSubscriptions(false));
    });
};
export const getSingleSubscription = async (subscriptionPackageId: string) => {
  const params = {
    subscriptionPackageId,
  };

  api
    .get("admin/subscription/single", { params })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getPaymentHistory = async (
  subscriberId: string,
  startDate: number,
  endDate: number
) => {
  store.dispatch(setGettingPaymentHistory(true));

  const params: any = {
    subscriberId,
    startDate,
    endDate,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  api
    .get("admin/subscription/payment-history", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentHistory(data?.data));
      store.dispatch(setGettingPaymentHistory(false));
    })
    .catch((err) => {
      store.dispatch(setGettingPaymentHistory(false));
    });
};

export const createPaymentOrder = async (subscriptionPackageId: string) => {
  const body = {
    subscriptionPackageId,
  };

  api
    .post("admin/subscription/create-payment-order", body)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const submitPaymentDetails = async (
  subscriptionPaymentHistoryId: string,
  transactionReference: string,
  paymentMethodTransactionReference: string,
  paymentMethod: string
) => {
  const body = {
    subscriptionPaymentHistoryId,
    transactionReference,
    paymentMethodTransactionReference,
    paymentMethod,
  };

  api
    .post("admin/subscription/submit-payment", body)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCurrentSubscription = async () => {
  store.dispatch(setGettingCurrentSubscriptions(true));
  await api
    .get("admin/subscription/current")
    .then((res) => {
      const { data } = res;
      store.dispatch(setCurrentSubscription(data?.data));
      store.dispatch(setGettingCurrentSubscriptions(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingCurrentSubscriptions(false));
    });
};

export const getSubscriptionHistory = async (
  startDate?: string,
  endDate?: string
) => {
  store.dispatch(setGettingSubscriptionHistory(true));
  const params = {
    startDate,
    endDate,
  };
  await api
    .get("admin/subscription/payment-history", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setSubscriptionHistory(data?.data));
      store.dispatch(setGettingSubscriptionHistory(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSubscriptionHistory(false));
    });
};
