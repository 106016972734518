import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserManagerState {
  gettingUsers: boolean;
  users: any;
  gettingRoles: boolean;
  Roles: any;
  selectRoles: any;
  gettingChurchAdmins: boolean;
  creatingChurchAdmins: boolean;
  deletingChurchAdmin: boolean;
  churchAdmins: any;

  //   add user state
  roleId: string;
  churchId: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
}

const initialState: UserManagerState = {
  gettingRoles: false,
  Roles: null,
  selectRoles: null,
  gettingChurchAdmins: false,
  creatingChurchAdmins: false,
  deletingChurchAdmin: false,
  churchAdmins: null,
  gettingUsers: false,
  users: null,

  //   add user state
  roleId: "",
  churchId: "",
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  phoneNo: "",
};

export const UserManagerSlice = createSlice({
  name: "UserManager",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingRoles: (state, action) => {
      state.gettingRoles = action.payload;
    },
    setRoles: (state, action) => {
      state.Roles = action.payload;
      const filtered = action.payload?.itemsList?.map(
        (role: any, index: number) => {
          return {
            id: index,
            name: role?.title,
            value: role?._id,
          };
        },
      );
      state.selectRoles = filtered;
    },
    setGettingChurchAdmins: (state, action) => {
      state.gettingChurchAdmins = action.payload;
    },
    setCreatingChurchAdmins: (state, action) => {
      state.creatingChurchAdmins = action.payload;
    },
    setDeletingChurchAdmin: (state, action) => {
      state.deletingChurchAdmin = action.payload;
    },
    setChurchAdmins: (state, action) => {
      state.churchAdmins = action.payload;
    },
    setGettingUsers: (state, action) => {
      state.gettingUsers = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    getUserManagerFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingRoles,
  setRoles,
  setGettingChurchAdmins,
  setChurchAdmins,
  setGettingUsers,
  setUsers,
  getUserManagerFormDetails,
  setCreatingChurchAdmins,
  setDeletingChurchAdmin,
} = UserManagerSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminUserManager = (state: RootState) =>
  state.adminUserManager;

export default UserManagerSlice.reducer;
