import React from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { adminCreateUserInput } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import {
  getUserManagerFormDetails,
  selectAdminUserManager,
} from "../../ReduxStore/features/Admin/UserManagerSlice";
import { createAdminUsers } from "../../Apis/AdminApis/userManager";
import { selectUser } from "../../ReduxStore/features/userSlice";

const CreateUserModal = () => {
  const {
    roleId,
    email,
    password,
    firstName,
    lastName,
    phoneNo,
    creatingChurchAdmins,
  } = useAppSelector(selectAdminUserManager);
  const { currentUser } = useAppSelector(selectUser);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getUserManagerFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getUserManagerFormDetails({ name, value }));
  };

  return (
    <CustomModal
      id={7}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {adminCreateUserInput?.map((input: any) => (
            <>
              {input?.type === "select" ? (
                <SelectBox
                  options={input?.options()}
                  name={input?.name}
                  handleSelect={handleSelect}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Add"
            handleClick={(e: any) => {
              e.preventDefault();
              createAdminUsers(
                roleId,
                currentUser?.roles[0]?.churchId?._id,
                email,
                password,
                firstName,
                lastName,
                phoneNo
              );
            }}
            loading={creatingChurchAdmins}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateUserModal;
