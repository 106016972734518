import React from "react";
import CustomModal from "./CustomModal";
import FormWrapper from "./../Layouts/FormWrapper";
import TextInput from "./../Components/Form/inputs/TextInput";
import PrimaryButton from "./../Components/Form/Buttons/PrimaryButton";
import { createCurrencyInputs } from "../Utils/authInput";
import FileUpload from "./../Components/Form/inputs/FileUpload";
import { useAppSelector } from "../ReduxStore/app/hooks";
import {
  getWalletFormDetails,
  selectSuperAdminWallet,
} from "../ReduxStore/features/SuperAdmin/WalletSlice";
import { useAppDispatch } from "./../ReduxStore/app/hooks";
import { createCurrency } from "../Apis/SuperAdminApis/wallet";

const CreateCurrencyModal = () => {
  const { currencyName, currencySymbol, currencyUrl, editingWallet } =
    useAppSelector(selectSuperAdminWallet);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getWalletFormDetails({ name, value }));
  };

  return (
    <CustomModal
      id={4}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {createCurrencyInputs?.map((input) => (
            <>
              {input?.type === "file" ? (
                <FileUpload text="Currency Icon" />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={(e: any) => {
              e.preventDefault();
              createCurrency(currencySymbol, currencyName, currencyUrl);
            }}
            loading={editingWallet}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateCurrencyModal;
