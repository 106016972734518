import React from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { createLiveStreamLinkInput } from "../../Utils/authInput";
import { TextArea, selectStyles } from "../../Components/Form/inputs/styles";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import {
  getAdminLiveStreamFormDetails,
  selectAdminLiveStream,
} from "../../ReduxStore/features/Admin/LivestreamSlice";
import Select from "react-select";

const CreateLiveStreamLink = () => {
  const { creatingLiveStreamLinks } = useAppSelector(selectAdminLiveStream);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminLiveStreamFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminLiveStreamFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminLiveStreamFormDetails({ name, value }));
  };

  const handleMultiSelect = (value: any, name: any) => {
    const realValue = value.map((item: any) => {
      return item?.value;
    });
    dispatch(getAdminLiveStreamFormDetails({ name, value: realValue }));
  };

  return (
    <CustomModal
      id={23}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {createLiveStreamLinkInput?.map((input: any) => (
              <>
                {input?.type === "textarea" ? (
                  <TextArea
                    placeholder={input.placeholder}
                    name={input?.name}
                    onChange={handleTextAreaChange}
                    // value={input?.defaultValue()}
                  />
                ) : input?.type === "select" ? (
                  <SelectBox
                    options={input?.options}
                    name={input?.name}
                    handleSelect={handleSelect}
                  />
                ) : input?.type === "multi-select" ? (
                  <Select
                    isMulti
                    options={input?.options()}
                    name={input?.name}
                    onChange={(e: any) => handleMultiSelect(e, input?.name)}
                    styles={selectStyles}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <TextInput
                      placeholder={input.placeholder}
                      type={input?.type}
                      name={input?.name}
                      handleChange={handleChange}
                      //   defaultValue={input?.defaultValue()}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
          <PrimaryButton
            text="Create"
            handleClick={(e: any) => {
              e.preventDefault();
              //   AddEventMedium(eventMediumName);
            }}
            loading={creatingLiveStreamLinks}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateLiveStreamLink;
