import React, { FC } from "react";
import { FilePickerContainer } from "./styles";

interface IFilePicker {
  text: string;
  name?: string;
  handleChange?: any;
  ImageUrl?: string;
}
const FilePicker: FC<IFilePicker> = ({
  text,
  name,
  handleChange,
  ImageUrl,
}) => {
  return (
    <div>
      <FilePickerContainer>
        <label htmlFor="file">{text}</label>
        <input
          type="file"
          id="file"
          accept=".png,.svg,.jpeg,.peg,.webp,.jfif"
          name={name}
          onChange={handleChange}
        />
      </FilePickerContainer>
      {ImageUrl && (
        <img
          src={ImageUrl}
          style={{ width: `100%`, height: " 300px", marginTop: "10px" }}
          alt="upload"
        />
      )}
    </div>
  );
};

export default FilePicker;
