import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../Components/Form/inputs/TextInput";
import FormWrapper from "../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import { selectAuth } from "../../ReduxStore/features/authSlice";
import {
  getFormDetails,
  selectForm,
} from "../../ReduxStore/features/formSlice";
import AuthMain from "./AuthMain";
import { AuthLogo, AuthTitle, AuthLink, AuthSmallText } from "./style";
import { toast } from "react-toastify";
import { resetUserPassword } from "../../Apis/Auth/resetPassword";

const ChangePassword = () => {
  const { resetPassword, resetCode } = useAppSelector(selectForm);
  const { resettingPassword } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (resetPassword && resetCode) {
      resetUserPassword(resetPassword, resetCode, navigate);
    } else {
      toast.error("Please fill in the new password and the code");
    }
  };

  return (
    <AuthMain placeItemsCenter={true}>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Reset Password</AuthTitle>
      <FormWrapper>
        <TextInput
          placeholder="Enter New Password"
          name="resetPassword"
          value={resetPassword}
          handleChange={handleChange}
          type="password"
        />
        <TextInput
          placeholder="Enter Code"
          name="resetCode"
          value={resetCode}
          handleChange={handleChange}
        />
        <PrimaryButton
          text="Reset"
          loading={resettingPassword}
          handleClick={handleSubmit}
        />
      </FormWrapper>
      <AuthSmallText>
        Already have an account? <AuthLink to="/">Login here</AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default ChangePassword;
