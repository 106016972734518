import React, { FC } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "styled-components";
import { theme } from "../theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 343px;
  height: 177px;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.051));
  padding: 20px;
`;

const TopItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: ${theme.colors.secondaryColor};
`;

const Desc = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.secondaryColor};
  margin-top: 10px;
`;

const Status = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
`;

const BottonItems = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    font-size: 18px;
    color: ${theme.colors.textGreycolor};
    cursor: pointer;
  }
`;

interface ILiveStream {
  title: string;
  desc: string;
  status: string;
  setAdd?: any;
  setEdit?: any;
  setDelete?: any;
}

const LiveStreamCard: FC<ILiveStream> = ({
  setEdit,
  title,
  desc,
  status,
  setDelete,
}) => {
  return (
    <Container>
      <TopItems>
        <Title>{title}</Title>
        <Desc>{desc}</Desc>
        <Status
          className={`${status === "visible" ? "text-green" : "text-red"}`}
        >
          {status}
        </Status>
      </TopItems>
      <BottonItems>
        <FiEdit onClick={setEdit} />
        <RiDeleteBin6Line onClick={setDelete} />
      </BottonItems>
    </Container>
  );
};

export default LiveStreamCard;
