import React, { useEffect } from "react";
import { Books } from "./styles";
import styled from "styled-components";
import { theme } from "../../../../theme";

// import { BibleVerse } from "../Home/styles";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import {
  getBibleFormDetails,
  selectBible,
} from "../../../../ReduxStore/features/BibleSlice";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";

const BibleChaptersContainer = styled.ul`
  display: block;
  padding: 10px;
  max-height: 300px;
  width: 500px;
  border-radius: 10px;
  background: ${theme.colors.bgWhite};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

interface I {
  setSelectedChapter: any;
  close: any;
}

const BibleVerses: React.FC<I> = ({ setSelectedChapter, close }) => {
  const { bibleChapters } = useAppSelector(selectBible);
  const dispatch = useAppDispatch();

  useEffect(() => {}, [bibleChapters]);

  return (
    <Books>
      <BibleChaptersContainer>
        {bibleChapters?.map((chapter: any) => (
          <li
            className="list"
            key={chapter?.bookId}
            onClick={() => {
              setSelectedChapter(chapter?.chapter);
              dispatch(
                getBibleFormDetails({
                  name: "chapter",
                  value: chapter?.chapter,
                }),
              );
              close(false);
            }}
            style={{ listStyle: "none" }}
          >
            {chapter?.chapter}
            {/* {verse.chapter}:{verse.verse} */}
          </li>
        ))}
      </BibleChaptersContainer>
    </Books>
  );
};

export default BibleVerses;
