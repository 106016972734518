import React, { useState } from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { createUserPostInputs } from "../../Utils/authInput";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { TextArea } from "../../Components/Form/inputs/styles";
import {
  getPostFormDetails,
  selectUserPost,
} from "../../ReduxStore/features/User/UserPostSlice";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { createPost } from "../../Apis/UserApis/Post";
import { VideoAudioContainer } from "../../Pages/Private/Admin/Media/style";
import { MdOutlineAttachFile } from "react-icons/md";
import { theme } from "../../theme";

const CreateUserPostsModal = () => {
  const dispatch = useDispatch();
  const [fileSource, setFileSource] = useState();
  const [fileType, setFileType] = useState("");
  const { contentType, title, description, hashtags, file, creatingUserPost } =
    useAppSelector(selectUserPost);

  const handleFileUpload = (e: any) => {
    e.preventDefault();
    const file = e?.target?.files[0];
    const name = e?.target?.name;
    if (file) {
      const extractedText = file.type.split("/")[0];
      setFileType(extractedText);
      dispatch(getPostFormDetails({ name, value: file }));
      dispatch(
        getPostFormDetails({ name: "contentType", value: extractedText })
      );
      const reader: any = new FileReader();
      reader.onload = (e: any) => {
        const src = e.target.result;
        setFileSource(src);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPostFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const tags = value.match(/#[a-zA-Z0-9_]+/g) || [];
    dispatch(getPostFormDetails({ name: "hashtags", value: tags }));
    dispatch(getPostFormDetails({ name, value }));
  };

  const handleCreatePost = () => {
    createPost(contentType, title, description, hashtags, file);
  };

  return (
    <CustomModal
      id={4}
      content={
        <FormWrapper
          style={{
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {createUserPostInputs?.map((input: any) => (
            <>
              {input?.type === "file" ? (
                <>
                  {fileSource && fileType === "video" ? (
                    <VideoAudioContainer>
                      <video
                        src={fileSource}
                        controls
                        typeof="video/mp4"
                        autoPlay
                      ></video>
                    </VideoAudioContainer>
                  ) : fileSource && fileType === "audio" ? (
                    <audio
                      src={fileSource}
                      controls
                      style={{ width: "340px" }}
                    />
                  ) : (
                    <img src={fileSource} width={340} />
                  )}
                  <div>
                    <label
                      htmlFor="file"
                      style={{
                        width: "90%",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    >
                      <span>
                        <MdOutlineAttachFile
                          size={20}
                          color={theme.colors.primaryColor}
                        />
                      </span>
                      <span>Attach File</span>
                    </label>
                    <input
                      type="file"
                      accept={
                        ".mp4, .avi, .mkv, .mov, .wmv, .flv, .webm, .mpeg, .mpg, .mp3, .wav, .aac, .flac, .ogg, .wma, .m4a, .alac, .aiff, .opus,.png, .jpg, .jpeg, .gif, .svg, .webp"
                      }
                      style={{ display: "none" }}
                      id="file"
                      name={input?.name}
                      onChange={handleFileUpload}
                    />
                  </div>
                </>
              ) : input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  style={{ width: "100%" }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    min={input?.min}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Upload Post"
            handleClick={(e: any) => {
              e.preventDefault();
              handleCreatePost();
            }}
            loading={creatingUserPost}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateUserPostsModal;
