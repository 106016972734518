import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../Components/Form/inputs/TextInput";
import FormWrapper from "../../Layouts/FormWrapper";
import AuthMain from "./AuthMain";
import { AuthLogo, AuthTitle, AuthLink, AuthSmallText } from "./style";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getFormDetails,
  selectForm,
} from "../../ReduxStore/features/formSlice";
import { selectAuth } from "../../ReduxStore/features/authSlice";
import { toast } from "react-toastify";
import { confirmEmail } from "../../Apis/Auth/resetPassword";

const ResetPassword = () => {
  const { resetEmail } = useAppSelector(selectForm);
  const { confirmingEmailForReset } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (resetEmail) {
      confirmEmail(resetEmail, navigate);
    } else {
      toast.error("Please enter your email to receive the reset code");
    }
  };

  return (
    <AuthMain placeItemsCenter={true}>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Reset Password</AuthTitle>
      <FormWrapper>
        <TextInput
          placeholder="Email"
          name="resetEmail"
          value={resetEmail}
          handleChange={handleChange}
        />
        <PrimaryButton
          text="Send Reset Email"
          handleClick={handleSubmit}
          loading={confirmingEmailForReset}
        />
      </FormWrapper>
      <AuthSmallText>
        Already have an account? <AuthLink to="/">Login here</AuthLink>
      </AuthSmallText>
    </AuthMain>
  );
};

export default ResetPassword;
