import React, { FC } from "react";
import FaTimesButton from "../../../../Components/Form/Buttons/FaTimesButton";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { FaTimes } from "react-icons/fa";
import { AiFillInfoCircle } from "react-icons/ai";
// import PrimarySelector from "../../../../Components/Form/inputs/PrimarySelector";
// import TextInput from "../../../../Components/Form/inputs/TextInput";
import { AdsContainar, AdsContainerCaption, TestimonyInstructionContainer, TestimonyInstruction } from "./style";
import "./styles.css";
import FormWrapper from "../../../../Layouts/FormWrapper";
import { TextArea } from "../../../../Components/Form/inputs/styles";
import { useAppDispatch, useAppSelector } from "../../../../ReduxStore/app/hooks";
import { getPrayerWallFormDetails, selectUserPrayerWall } from "../../../../ReduxStore/features/User/PrayerWallSlice";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import { CreateTestimony } from "../../../../Utils/authInput";
import TextInput from "../../../../Components/Form/inputs/TextInput";
import { createTestimony } from "../../../../Apis/UserApis/prayerRequests";

interface IAdd {
  setState: any;
}
const AddTestimony: FC<IAdd> = ({ setState }) => {
  const { userProfile } = useAppSelector(selectUserProfile);
  const { creatingTestimonies, testimonyTitle, testimonyDescription } = useAppSelector(selectUserPrayerWall);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPrayerWallFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getPrayerWallFormDetails({ name, value }));
  };

  return (
    <AdsContainar>
      <FaTimesButton size="small" faTimes={<FaTimes />} handleClick={() => setState(false)} />
      <AdsContainerCaption>Add a Testimony</AdsContainerCaption>

      <FormWrapper>
        {CreateTestimony?.map((input) => (
          <>
            {input?.type === "textarea" ? (
              <TextArea placeholder={input.placeholder} name={input?.name} onChange={handleTextAreaChange} value={input?.defaultValue()} />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <TextInput placeholder={input.placeholder} type={input?.type} name={input?.name} handleChange={handleChange} value={input?.defaultValue()} />
              </div>
            )}
          </>
        ))}
      </FormWrapper>

      <TestimonyInstructionContainer>
        <AiFillInfoCircle />
        <TestimonyInstruction>Your Testimony is subject to approval from the church admin</TestimonyInstruction>
      </TestimonyInstructionContainer>
      <div className="selectContainer">
        <PrimaryButton
          text="Add"
          size="small"
          handleClick={(e: any) => {
            e.preventDefault();
            createTestimony(userProfile?.churchId?._id, userProfile?.userId?._id, testimonyTitle, testimonyDescription).then(() => setState(false));
          }}
          loading={creatingTestimonies}
        />
      </div>
    </AdsContainar>
  );
};

export default AddTestimony;
