import { useState, useEffect, FC } from "react";
import { MdInsertPhoto } from "react-icons/md";
import { FileUploadContainer } from "./styles";
import styled from "styled-components";

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoPreview = styled.video`
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
`;

interface IFilePicker {
  text: string;
  handleChange?: any;
  accepts?: string;
  imgUrl?: any;
  name?: any;
}
const FileUpload: FC<IFilePicker> = ({
  text,
  accepts,
  handleChange,
  imgUrl,
  name,
}) => {
  const [imgSrc, setImageSrc] = useState<any>();

  console.log(imgUrl);

  useEffect(() => {
    if (imgUrl) {
      const url = URL.createObjectURL(imgUrl);
      setImageSrc(url);
    }
  }, [imgUrl]);

  return (
    <FileUploadContainer htmlFor="file">
      {imgUrl ? (
        <>
          {imgUrl?.type.split("/")[0] === "image" && (
            <ImagePreview src={imgSrc} alt={imgSrc} />
          )}
          {imgUrl?.type.split("/")[0] === "video" && (
            <VideoPreview src={imgSrc} controls />
          )}
        </>
      ) : (
        <>
          <div>
            <MdInsertPhoto />
          </div>
          <p>{text}</p>
        </>
      )}

      <input
        type="file"
        id="file"
        accept={accepts || ".png,.svg,.jpeg,.peg,.webp"}
        onChange={handleChange}
        name={name}
      />
    </FileUploadContainer>
  );
};

export default FileUpload;
