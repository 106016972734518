import React, { useEffect, useState } from "react";
import { ContentHeader, ContentWrapper, RightContentContainer } from "./style";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxStore/app/hooks";
import {
  getAdvertDetails,
  selectAdminChurchAdvert,
} from "../../../../../ReduxStore/features/Admin/AdvertSlice";
import Loader from "../../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../../theme";
import PostCard from "../../../Admin/PostAds/PostCard";
import NoRecordDisplay from "../../../../../Components/Messages/NoRecordDisplay";
import { getAdverts } from "../../../../../Apis/AdminApis/Adverts";
import ImageCarousel from "../../../../../Components/Reusables/Carousel/ImageCarousel";
import VideoCarousel from "../../../../../Components/Reusables/Carousel/VideoCarousel";

const imageData = [
  "https://th.bing.com/th/id/OIP.QOZmA9QHepri9FIxH6lBHAHaD4?rs=1&pid=ImgDetMain",
  "https://th.bing.com/th/id/OIP.tWRrZ1h8qRX-V0K4f_Ft7gHaEK?w=768&h=432&rs=1&pid=ImgDetMain",
  "https://th.bing.com/th/id/R.3c3f157785f6524c2fde5c3da4331265?rik=5e3%2f0T5LmpkXiA&pid=ImgRaw&r=0",
];

const videoData = [
  "https://the-faithful.s3.eu-west-1.amazonaws.com/1719374850920_0517%281%29.mp4",
  "https://the-faithful.s3.eu-west-1.amazonaws.com/1719196175136_Alessia%20Cara%20-%20Scars%20To%20Your%20Beautiful%20%28Official%20Audio%29%20%282%29.mp3",
];

const RightContent = () => {
  const { adminChurchAdverts, gettingAdminChurchAdverts, page, pageSize } =
    useAppSelector(selectAdminChurchAdvert);
  const dispatch = useAppDispatch();
  const [imageAds, setImageAds] = useState<any>(null);
  const [videoAds, setVideoAds] = useState<any>(null);

  useEffect(() => {
    const filterImageAds = adminChurchAdverts?.itemsList.map((item: any) => {
      if (item.imageUrl) return item.imageUrl;
    });
    if (filterImageAds?.length > 0)
      setImageAds(filterImageAds.filter((x: any) => x !== undefined));
    console.log(imageAds);

    const filterVideoAds = adminChurchAdverts?.itemsList.map((item: any) => {
      if (item.videoUrl) return item.videoUrl;
    });
    if (filterVideoAds?.length > 0)
      setVideoAds(filterVideoAds.filter((x: any) => x !== undefined));
    console.log(filterVideoAds);
  }, [adminChurchAdverts]);

  const handlePagination = (e: any) => {
    dispatch(getAdvertDetails({ name: "page", value: e.selected + 1 }));
  };

  useEffect(() => {
    getAdverts("", page, pageSize);
  }, [page]);

  return (
    <RightContentContainer>
      <ContentHeader>Ads</ContentHeader>
      <ContentWrapper>
        {gettingAdminChurchAdverts ? (
          <Loader size={20} color={theme.colors.primaryColor} />
        ) : (
          <ContentWrapper>
            {imageAds?.length > 0 && (
              <ImageCarousel
                images={imageAds}
                autoPlay={true}
                interval={5000}
              />
            )}
            {videoAds?.length > 0 && <VideoCarousel videoSources={videoAds} />}
          </ContentWrapper>
        )}
      </ContentWrapper>
    </RightContentContainer>
  );
};

export default RightContent;
