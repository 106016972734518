import { useEffect } from "react";
import { ActionSection, CardsContainer, SectionTitle } from "./style";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import TextInput from "../../../../Components/Form/inputs/TextInput";
import AccountCard from "../../../../Layouts/AccountCard";
import { RiSearchLine } from "react-icons/ri";
import { getUserBankAccount } from "../../../../Apis/AdminApis/Wallet";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getWalletFormDetails,
  selectAdminWallet,
} from "../../../../ReduxStore/features/Admin/WalletSlice";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import CreateBankAccountModal from "./../../../../Modals/Admin/CreateBankAccountModal";
import EditBankAccountModal from "../../../../Modals/Admin/EditBankAccountModal";

const Accounts = () => {
  const {
    gettingBankAccounts,
    bankAccounts,
    visibility,
    creatingAccount,
    deletingWallet,
    editingWallet,
  } = useAppSelector(selectAdminWallet);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUserBankAccount(visibility);
  }, [visibility, creatingAccount, deletingWallet, editingWallet]);

  return (
    <>
      <SectionTitle>Accounts</SectionTitle>
      <ActionSection>
        <PrimaryButton
          text="Add Bank Account"
          size="medium"
          handleClick={(e: any) => {
            e.preventDefault();
            dispatch(handleModal(11));
          }}
        />
        <TextInput
          name="visibility"
          placeholder={"Search"}
          leftIcon={<RiSearchLine />}
          handleChange={(e: any) => {
            dispatch(
              getWalletFormDetails({
                name: e.target.name,
                value: e.target.value,
              })
            );
          }}
        />
      </ActionSection>
      <CardsContainer>
        {gettingBankAccounts ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color={theme?.colors.primaryColor} size={20} />
          </div>
        ) : (
          <>
            {bankAccounts?.itemsList?.length > 0
              ? bankAccounts?.itemsList?.map((acc: any, index: number) => (
                  <AccountCard accountyDetails={acc} key={index} />
                ))
              : null}
          </>
        )}
      </CardsContainer>
      <CreateBankAccountModal />
      <EditBankAccountModal />
    </>
  );
};

export default Accounts;
