import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ActivitiesState {
  gettingEventMedium: boolean;
  editingEventMedium: boolean;
  creatingEventMedium: boolean;
  creatingEvent: boolean;
  creatingActivity: boolean;
  deletingEventMedium: boolean;
  eventMediums: any;
  gettingEventLocation: boolean;
  editingEventLocation: boolean;
  creatingEventLocation: boolean;
  deletingEventLocation: boolean;
  eventLocations: any;
  gettingChurchActivities: boolean;
  gettingChurchEvents: boolean;
  churchActivities: any;
  churchEvents: any;
  deletingActivities: boolean;
  deletingEvents: boolean;
  selectedActiviy: any;
  selectedEvent: any;
  gettingAdminChurch: boolean;
  adminChurch: any;

  //   event medium states
  eventMediumId: string;
  eventMediumName: string;
  locationAddress: string;
  locationMedium: string;
  locationId: string;

  // create event states
  eventTitle: string;
  eventMessage: string;
  eventStartDate: number;
  eventEndDate: number;
  eventAllDay: boolean;
  eventRepeat: boolean;
  eventLocation: string;
  eventLivestream: boolean;
  eventFileUrl: string;
  eventGroupIds: string[];
  eventInviteType: string;

  // create activity states
  activityTitle: string;
  activityMessage: string;
  activityStartDate: number;
  activityEndDate: number;
  activityAllDay: boolean;
  activityRepeat: boolean;
  activityLocation: string;
  activityLivestream: boolean;
  activityFileUrl: string;
  activityGroupIds: string[];
  activityInviteType: string;
}

const initialState: ActivitiesState = {
  gettingEventMedium: false,
  editingEventMedium: false,
  creatingEventMedium: false,
  creatingEvent: false,
  creatingActivity: false,
  deletingEventMedium: false,
  eventMediums: null,
  gettingEventLocation: false,
  editingEventLocation: false,
  creatingEventLocation: false,
  deletingEventLocation: false,
  eventLocations: null,
  gettingChurchActivities: false,
  gettingChurchEvents: false,
  churchActivities: null,
  churchEvents: null,
  deletingActivities: false,
  deletingEvents: false,
  selectedActiviy: null,
  selectedEvent: null,
  gettingAdminChurch: false,
  adminChurch: null,

  //   event medium states
  eventMediumId: "",
  eventMediumName: "",
  locationAddress: "",
  locationMedium: "",
  locationId: "",

  // create event states
  eventTitle: "",
  eventMessage: "",
  eventStartDate: 0,
  eventEndDate: 0,
  eventAllDay: false,
  eventRepeat: false,
  eventLocation: "",
  eventLivestream: false,
  eventFileUrl: "",
  eventGroupIds: [],
  eventInviteType: ``,

  // create activity states
  activityTitle: "",
  activityMessage: "",
  activityStartDate: 0,
  activityEndDate: 0,
  activityAllDay: false,
  activityRepeat: false,
  activityLocation: "",
  activityLivestream: false,
  activityFileUrl: "",
  activityGroupIds: [],
  activityInviteType: ``,
};

export const ActivitiesSlice = createSlice({
  name: "Activities",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurchEvents: (state, action) => {
      state.gettingChurchEvents = action.payload;
    },
    setChurchEvents: (state, action) => {
      state.churchEvents = action.payload;
    },
    setGettingChurchActivities: (state, action) => {
      state.gettingChurchActivities = action.payload;
    },
    setChurchActivities: (state, action) => {
      state.churchActivities = action.payload;
    },
    setGettingAdminChurch: (state, action) => {
      state.gettingAdminChurch = action.payload;
    },
    setAdminChurch: (state, action) => {
      state.adminChurch = action.payload;
    },
    setDeletingChurchActivity: (state, action) => {
      state.deletingActivities = action.payload;
    },
    setDeletingChurchEvent: (state, action) => {
      state.deletingEvents = action.payload;
    },
    setGettingEventMedium: (state, action) => {
      state.gettingEventMedium = action.payload;
    },
    setEventMediums: (state, action) => {
      state.eventMediums = action.payload;
    },
    setCreatingEventMedium: (state, action) => {
      state.creatingEventMedium = action.payload;
    },
    setCreatingEvent: (state, action) => {
      state.creatingEvent = action.payload;
    },
    setCreatingActivity: (state, action) => {
      state.creatingActivity = action.payload;
    },
    setEditingEventMedium: (state, action) => {
      state.editingEventMedium = action.payload;
    },
    setDeletingEventMedium: (state, action) => {
      state.deletingEventMedium = action.payload;
    },
    setSelectedEventMedium: (state, action) => {
      state.eventMediumId = action.payload._id;
      state.eventMediumName = action.payload.name;
    },
    setGettingEventLocation: (state, action) => {
      state.gettingEventLocation = action.payload;
    },
    setEventLocations: (state, action) => {
      state.eventLocations = action.payload;
    },
    setCreatingEventLocation: (state, action) => {
      state.creatingEventLocation = action.payload;
    },
    setEditingEventLocation: (state, action) => {
      state.editingEventLocation = action.payload;
    },
    setDeletingEventLocation: (state, action) => {
      state.deletingEventLocation = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
      state.eventTitle = action.payload?.title;
      state.eventMessage = action.payload?.message;
      state.eventStartDate = action.payload?.startDate;
      state.eventEndDate = action.payload?.endDate;
      state.eventAllDay = action.payload?.allDay;
      state.eventRepeat = action.payload?.repeat;
      state.eventLocation = action.payload?.location;
      state.eventLivestream = action.payload?.liveStream;
      state.eventFileUrl = action.payload?.bannerUrl;
      state.eventInviteType = action.payload?.inviteType;
    },
    setSelectedActivity: (state, action) => {
      state.selectedActiviy = action.payload;
      state.activityTitle = action.payload?.title;
      state.activityMessage = action.payload?.message;
      state.activityStartDate = action.payload?.startDate;
      state.activityEndDate = action.payload?.endDate;
      state.activityAllDay = action.payload?.allDay;
      state.activityRepeat = action.payload?.repeat;
      state.activityLocation = action.payload?.location;
      state.activityLivestream = action.payload?.liveStream;
      state.activityFileUrl = action.payload?.bannerUrl;
      state.activityInviteType = action.payload?.inviteType;
    },
    setSelectedEventLocation: (state, action) => {
      state.locationAddress = action.payload.address;
      state.locationMedium = action.payload.medium;
      state.locationId = action.payload._id;
    },

    getAdminActivitiesDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setCreatingEventMedium,
  setDeletingEventMedium,
  setEditingEventMedium,
  setCreatingActivity,
  setEventMediums,
  setGettingEventMedium,
  setSelectedEventMedium,
  setCreatingEventLocation,
  setDeletingEventLocation,
  setEditingEventLocation,
  setEventLocations,
  setGettingEventLocation,
  setSelectedEventLocation,
  getAdminActivitiesDetails,
  setCreatingEvent,
  setGettingChurchActivities,
  setChurchActivities,
  setGettingChurchEvents,
  setChurchEvents,
  setDeletingChurchEvent,
  setDeletingChurchActivity,
  setSelectedEvent,
  setSelectedActivity,
  setAdminChurch,
  setGettingAdminChurch
} = ActivitiesSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.Activitieser.value)`
export const selectAdminActivities = (state: RootState) => state.adminActivities;

export default ActivitiesSlice.reducer;
