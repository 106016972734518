import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
// import FormWrapper from "../../Layouts/FormWrapper";
import {
  AdDurationWrap,
  Duration,
  FormContainer,
  PubButtonContainer,
  PubButtonWrap,
} from "../../Pages/Private/Admin/PostAds/style";
import Select from "react-select";
import { selectStyles } from "../../Components/Form/inputs/styles";
import { FaArrowRight } from "react-icons/fa";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import {
  getAdminActivitiesDetails,
  selectAdminActivities,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { ToggleSwitch } from "../../Pages/Private/Admin/Subscription/Switch";
import {
  getEventLocations,
  CreateActivity,
} from "../../Apis/AdminApis/ChurchActivities";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import { getUserActiveChurch } from "../../Apis/UserApis/userProfile";
import { getGroupList } from "../../Apis/AdminApis/churchGroup";
import { selectAdminGroups } from "../../ReduxStore/features/Admin/GroupSlice";
import FilePicker from "../../Components/Form/inputs/FilePicker";
import { handleModal } from "../../ReduxStore/features/navSlice";
import { toast } from "react-toastify";

const inviteTypeOptions = [
  {
    label: "Groups",
    value: "Group",
  },
  {
    label: "Members",
    value: "Members",
  },
];

const CreateActivityModal = () => {
  const {
    activityTitle,
    activityMessage,
    activityStartDate,
    activityEndDate,
    activityAllDay,
    activityRepeat,
    activityLocation,
    activityLivestream,
    activityFileUrl,
    activityGroupIds,
    eventLocations,
    creatingActivity,
    activityInviteType,
  } = useAppSelector(selectAdminActivities);
  const { userProfile } = useAppSelector(selectUserProfile);
  const [locations, setLocation] = useState();
  const [groups, setGroups] = useState();
  const { churchGroups } = useAppSelector(selectAdminGroups);
  const dispatch = useAppDispatch();
  // const [files, setFiles] = useState();

  const handleSwitch = (e: any, name: string) => {
    e.preventDefault();
    dispatch(
      getAdminActivitiesDetails({
        name,
        value: e.target.checked,
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  const handleSelect = (e: any, name: any) => {
    dispatch(getAdminActivitiesDetails({ name, value: e.value }));
  };

  const handleMultiSelect = (value: any, name: any) => {
    const realValue = value.map((item: any) => {
      return item?.value;
    });
    dispatch(getAdminActivitiesDetails({ name, value: realValue }));
  };

  function handleFileUpload(changeEvent: any) {
    const reader = new FileReader();

    reader.onload = async function (onLoadEvent: any) {
      const formData = new FormData();

      formData.append("file", onLoadEvent.target.result);
      formData.append("upload_preset", "my-uploads");

      const data = await fetch(
        "https://api.cloudinary.com/v1_1/dztnevagf/image/upload",
        {
          method: "POST",
          body: formData,
        }
      ).then((response) => {
        return response.json();
      });

      if (data?.secure_url) {
        dispatch(
          getAdminActivitiesDetails({
            name: `activityFileUrl`,
            value: data?.secure_url,
          })
        );
      } else {
        toast.error(`File not uploaded, try again`);
      }
    };
    reader.readAsDataURL(changeEvent.target.files[0]);
  }

  const handleUpload = (e: any) => {
    e.preventDefault();
    CreateActivity(
      activityTitle,
      activityMessage,
      activityStartDate,
      activityEndDate,
      activityAllDay,
      activityRepeat,
      activityLocation,
      activityLivestream,
      activityFileUrl,
      activityInviteType === `Group` ? activityGroupIds : [],
      activityInviteType
    );
  };

  useEffect(() => {
    const Locations = eventLocations?.itemsList?.map((item: any) => {
      return {
        value: item._id,
        label: item.address,
      };
    });
    setLocation(Locations);
  }, [eventLocations]);
  useEffect(() => {
    const group = churchGroups?.map((item: any) => {
      return {
        value: item.churchGroupId,
        label: item.name,
      };
    });
    setGroups(group);
  }, [churchGroups]);

  useEffect(() => {
    getEventLocations(userProfile?.churchId?._id);
  }, [userProfile]);

  useEffect(() => {
    getUserActiveChurch();
  }, []);

  useEffect(() => {
    getGroupList();
  }, []);
  

  return (
    <CustomModal
      id={22}
      content={
        <FormContainer>
          <form>
            <input
              className="titleinput"
              placeholder="Add Title"
              value={activityTitle}
              name="activityTitle"
              onChange={handleChange}
            />
            <Select
              options={inviteTypeOptions}
              placeholder="Invite groups or members"
              // name={input?.name}
              onChange={(e: any) => handleSelect(e, "activityInviteType")}
              styles={selectStyles}
            />
            <Select
              isMulti
              options={groups}
              placeholder="Groups"
              // name={input?.name}
              onChange={(e: any) => handleMultiSelect(e, "activityGroupIds")}
              styles={selectStyles}
              isDisabled={activityInviteType === `Members` ? true : false}
            />
            <AdDurationWrap>
              <Duration>
                <input
                  type="datetime-local"
                  className="date"
                  placeholder="State Date"
                  defaultValue={activityStartDate}
                  name="activityStartDate"
                  onChange={handleChange}
                />
                <span>
                  <FaArrowRight color="grey" />
                </span>
                <input
                  type="datetime-local"
                  className="date"
                  placeholder="End Date"
                  defaultValue={activityEndDate}
                  name="activityEndDate"
                  onChange={handleChange}
                />
                {/* <span className="smalldate">1d</span> */}
              </Duration>
            </AdDurationWrap>
            <FilePicker
              text="Upload activity poster"
              handleChange={handleFileUpload}
            />
            <p style={{ width: `100%` }}>{activityFileUrl}</p>
            <Select
              options={locations}
              placeholder="Location"
              // name={input?.name}
              onChange={(e: any) => handleSelect(e, "activityLocation")}
              styles={selectStyles}
              defaultValue={activityLocation}
            />
            <textarea
              className="detailinput"
              placeholder="Enter details for this activity"
              value={activityMessage}
              name="activityMessage"
              onChange={handleTextAreaChange}
            />
            <Duration style={{ flexDirection: `row` }}>
              <div>
                <ToggleSwitch
                  switchState={activityAllDay}
                  handleChange={(e: any) => handleSwitch(e, "activityAllDay")}
                  id="activityAllDayId"
                />
                <span>All day</span>
              </div>
              <div>
                <ToggleSwitch
                  switchState={activityRepeat}
                  handleChange={(e: any) => handleSwitch(e, "activityRepeat")}
                  id="activityRepeatId"
                />
                <span>Repeat</span>
              </div>
              <div>
                <ToggleSwitch
                  switchState={activityLivestream}
                  handleChange={(e: any) =>
                    handleSwitch(e, "activityLivestream")
                  }
                  id="activityLivestreamId"
                />
                <span>LiveStream</span>
              </div>
            </Duration>
            <PubButtonWrap>
              <PubButtonContainer>
                <PrimaryButton
                  text="Publish"
                  size="small"
                  handleClick={handleUpload}
                  loading={creatingActivity}
                />
                <PrimaryButton
                  text="Close"
                  size="small"
                  outlined={true}
                  handleClick={(e: any) => {
                    e.preventDefault();
                    dispatch(handleModal(0));
                  }}
                  loading={creatingActivity}
                />
              </PubButtonContainer>
            </PubButtonWrap>
          </form>
        </FormContainer>
      }
    />
  );
};

export default CreateActivityModal;
