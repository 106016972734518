import {
  getAllMyChurchGroups,
  getChatMessages,
  getJoinedGroups,
} from "../../../../Apis/UserApis/userChurchGroup";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserCgroup } from "../../../../ReduxStore/features/User/UserGroupSlice";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import ChatSection from "./ChatSection";
import { useEffect } from "react";

const App: React.FC = () => {
  const { userProfile } = useAppSelector(selectUserProfile);
  const { churchGroupId, sendingMessage, exitingGroup, requestingToJoinGroup } =
    useAppSelector(selectUserCgroup);

  useEffect(() => {
    getAllMyChurchGroups(userProfile?.churchId?._id);
    getJoinedGroups();
    setInterval(() => {
      churchGroupId && getChatMessages(churchGroupId);
    }, 1000);
  }, [
    userProfile,
    churchGroupId,
    sendingMessage,
    exitingGroup,
  requestingToJoinGroup,
  ]);

  return <ChatSection />;
};

export default App;
