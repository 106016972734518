import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import { setCreatingPrayerRequests } from "../../ReduxStore/features/User/PrayerWallSlice";
import api from "../baseUrl";
import {
  setApprovingTestimony,
  setChurchPrayerRequest,
  setChurchPrayers,
  setChurchTestimonies,
  setGettingChurchPrayerRequest,
  setGettingChurchPrayers,
  setGettingChurchTestimonies,
  setUpdatingChurchPrayers,
} from "../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const createPayerRequest = async (
  churchId: string,
  userId: string,
  title: string,
  description: string
) => {
  store.dispatch(setCreatingPrayerRequests(true));
  const body: any = {
    churchId,
    userId,
    title,
    description,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("user/prayerrequest/create", body)
    .then((res) => {
      store.dispatch(setCreatingPrayerRequests(false));
      toast.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingPrayerRequests(false));
    });
};

export const getAllChurchPrayerRequest = async (
  churchId?: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingChurchPrayerRequest(true));
  const params: any = {
    churchId,
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/prayerrequest", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setChurchPrayerRequest(data));
      store.dispatch(setGettingChurchPrayerRequest(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchPrayerRequest(false));
    });
};

export const likeOrDislikeRequest = async (prayerRequestId: string) => {
  const body = {
    prayerRequestId,
  };
  await api
    .post("user/prayerrequest/likeOrDislike", body)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllChurchTestimonies = async (
  pageId?: number,
  perPage?: number,
  churchId?: string
) => {
  store.dispatch(setGettingChurchTestimonies(true));
  const params: any = {
    pageId,
    perPage,
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/testimonies", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setChurchTestimonies(data));
      store.dispatch(setGettingChurchTestimonies(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchTestimonies(false));
    });
};

// export const createTestimony = async (
//   churchId: string,
//   userId: string,
//   title: string,
//   testimony: string
// ) => {
//   store.dispatch(setCreatingTestimonies(true));
//   const body: any = {
//     churchId,
//     userId,
//     title,
//     testimony,
//   };
//   Object.keys(body)?.forEach((key) => {
//     if (body[key] === null) {
//       delete body[key];
//     }
//   });
//   await api
//     .post("user/testimony/create", body)
//     .then((res) => {
//       store.dispatch(setCreatingTestimonies(false));
//       toast.success("Successful");
//     })
//     .catch((err) => {
//       if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
//       if (err?.message === "Network Error") toast.error(err?.message);
//       store.dispatch(setCreatingTestimonies(false));
//     });
// };

export const approveTestimony = async (id: string, churchId: string) => {
  store.dispatch(setApprovingTestimony(true));
  const body: any = {
    id,
    churchId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .patch(`admin/testimonies/${id}`, body)
    .then(() => {
      toast.success("Successful");
      store.dispatch(setApprovingTestimony(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails ||
            err?.response?.data?.data ||
            err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setApprovingTestimony(false));
    });
};

export const getChurchPrayers = async (
  status?: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingChurchPrayers(true));
  const params: any = {
    status,
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/Church-prayers", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setChurchPrayers(data));
      store.dispatch(setGettingChurchPrayers(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchPrayers(false));
    });
};

export const createChurchPrayer = async (
  title: string,
  text: string,
  status?: string
) => {
  store.dispatch(setUpdatingChurchPrayers(true));
  const body: any = {
    status,
    title,
    text,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null || body[key] === "") {
      delete body[key];
    }
  });
  await api
    .post("admin/Church-prayers", body)
    .then((res) => {
      // const { data } = res?.data;
      store.dispatch(setUpdatingChurchPrayers(false));
      toast.success("Prayer created successfully");
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails ||
            err?.response?.data?.data ||
            err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingChurchPrayers(false));
    });
};
export const editChurchPrayers = async (id: string) => {
  store.dispatch(setUpdatingChurchPrayers(true));
  await api
    .get(`admin/Church-prayers/${id}`)
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setUpdatingChurchPrayers(false));
      toast.success(data?.message);
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      store.dispatch(setUpdatingChurchPrayers(false));
    });
};

export const deleteChurchPrayers = async (id: string) => {
  store.dispatch(setUpdatingChurchPrayers(true));

  await api
    .delete(`admin/Church-prayers/${id}`)
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setUpdatingChurchPrayers(false));
      toast.success(data?.message);
    })
    .catch((err) => {
      store.dispatch(setUpdatingChurchPrayers(false));
    });
};
