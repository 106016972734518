import moment from "moment";

export const validateemail = (value: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

export const validatePassword = (psw: string) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(psw);
};

export const updateArrayElement = (
  array: string[],
  index: number,
  newElement: any
) => {
  Object.freeze(array);
  var newArray = [...array];
  newArray[index] = newElement;
  return newArray;
};

export const filterCurrentData = (data: any) => {
  const today = moment().format("DD MM YYYY");
  const filterByExpiration = data?.filter((items: any) => {
    return moment(items.createdAt).format("DD MM YYYY") === today;
  });
  return filterByExpiration;
};

export const getToday = () => {
  const today: any = new Date();
  return today;
};

export const getImagePreviewFile = (imgData: any) => {
  const url = URL.createObjectURL(imgData[0]);
  return url;
};
