import React, { FC } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "styled-components";
import { theme } from "../theme";
import { media } from "../Screens";
import {
  selectAdminDonations,
  setSelectedDonation,
} from "../ReduxStore/features/Admin/DonationsSlice";
import { useAppDispatch, useAppSelector } from "../ReduxStore/app/hooks";
import Loader from "../Components/Loaders/MoonLoader";
import { deleteDonation } from "../Apis/AdminApis/donations";
import { handleModal } from "../ReduxStore/features/navSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  min-height: 206px;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.102));
  padding: 20px;
  border-radius: 10px;
  border-top: 2px solid ${theme.colors.primaryColor};

  ${media.phone} {
    width: 90vw;
  }
`;
const TopItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const DonationReason = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.secondaryColor};
`;
const TargetAmount = styled.div`
  line-height: 23px;
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.secondaryColor};
`;
const DestinationAccount = styled.div`
  line-height: 23px;
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.secondaryColor};
`;
const Text = styled.div`
  font-size: 14px;
  line-height: 23px;
  color: ${theme.colors.secondaryColor};
`;
const BottomItems = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;

interface IDonationCard {
  donationDetails?: any;
}

const DonationCard: FC<IDonationCard> = ({ donationDetails }) => {
  const { deletingDonation } = useAppSelector(selectAdminDonations);
  const dispatch = useAppDispatch();

  return (
    <Container>
      <TopItems>
        <DonationReason>{donationDetails?.title}</DonationReason>
        <DestinationAccount>
          {donationDetails?.typeOfDonation}
        </DestinationAccount>
        <TargetAmount>
          Target Amount: {donationDetails?.currencyId?.symbol + " "}
          {donationDetails?.targetAmount}
        </TargetAmount>
        <Text>{donationDetails?.description}</Text>
      </TopItems>
      <BottomItems>
        <FiEdit
          onClick={() => {
            dispatch(handleModal(14));
            dispatch(setSelectedDonation(donationDetails));
          }}
        />
        {deletingDonation ? (
          <Loader />
        ) : (
          <RiDeleteBin6Line
            onClick={() => deleteDonation(donationDetails?._id)}
          />
        )}
      </BottomItems>
    </Container>
  );
};

export default DonationCard;
