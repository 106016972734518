import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signupAsMember } from "../../../Apis/Auth/signupApis";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import SelectBox from "../../../Components/Form/inputs/SelectBox";
import TextInput from "../../../Components/Form/inputs/TextInput";
import {
  validateemail,
  validatePassword,
} from "../../../Components/Helpers/Generals";
import FormWrapper from "../../../Layouts/FormWrapper";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getFormDetails,
  selectForm,
} from "../../../ReduxStore/features/formSlice";
import { memberSignUpInputs } from "../../../Utils/authInput";
import AuthMain from "../AuthMain";
import {
  AuthLogo,
  AuthTitle,
  AuthLink,
  AuthSmallText,
  AuthSubTitle,
} from "../style";
import CountryStateCitySelector from '../../../Components/Form/Selector/CountryStateCitySelector';

const SignupAsMember = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    registerEmail,
    registerPassword,
    phoneNo,
    firstName,
    lastName,
    gender,
    loading,
  } = useAppSelector(selectForm);
  const [viewPassword, setViewPassword] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSignup = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      registerEmail &&
      registerPassword &&
      phoneNo &&
      firstName &&
      lastName &&
      gender
    ) {
      if (validateemail(registerEmail) && validatePassword(registerPassword)) {
        signupAsMember(
          firstName,
          lastName,
          registerEmail,
          phoneNo,
          registerPassword,
          gender,
          dispatch,
          navigate,
          "user"
        );
      } else {
        toast.warning("Enter valid email and password");
      }
    } else {
      toast.warning("Please fill all fields");
    }
  };

  return (
    <AuthMain>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Sign Up</AuthTitle>
      <AuthSubTitle>Register as a church member by</AuthSubTitle>
      <FormWrapper>
        {memberSignUpInputs?.map((input) => (
          <>
            {input?.type !== "select" ? (
              <TextInput
                placeholder={input?.placeholder}
                icon={input?.ActiveIcon}
                type={input?.type === "password" && viewPassword ? "text" :  input?.type}
                name={input?.name}
                handleChange={handleChange}
                altIcon={input?.InActiveIcon}
                iconState={viewPassword}
                handleIconClick={() => setViewPassword(true)}
                handleAltIconClick={() => setViewPassword(false)}
              />
            ) : (
              <SelectBox
                options={input?.options}
                name={input?.name}
                handleSelect={handleSelect}
              />
            )}
           
          </>
        ))}
        <AuthSmallText>
          By signing up you accept the{" "}
          <AuthLink to="#">Terms of service</AuthLink> and{" "}
          <AuthLink to="#">Privay Policy</AuthLink>
        </AuthSmallText>
        <PrimaryButton
          text="Next"
          handleClick={handleSignup}
          loading={loading}
        />
        <AuthSmallText>
          Already have an account? <AuthLink to="/">Login here</AuthLink>
        </AuthSmallText>
      </FormWrapper>
    </AuthMain>
  );
};

export default SignupAsMember;
