import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  getAdvertDetails,
  setAdminChurchAdverts,
  setCreatingAdminChurchAdverts,
  setDeletingAdminChurchAdverts,
  setGettingAdminChurchAdverts,
} from "../../ReduxStore/features/Admin/AdvertSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";
import axios from "axios";

export const getAdverts = async (
  status?: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingAdminChurchAdverts(true));
  const params: any = {
    status,
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === (null || "")) {
      delete params[key];
    }
  });
  await api
    .get("admin/ads", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setAdminChurchAdverts(data));
      store.dispatch(setGettingAdminChurchAdverts(false));
    })
    .catch((err) => {
      store.dispatch(setGettingAdminChurchAdverts(false));
      if (err?.response)
        toast.error(err?.response?.data?.data || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getSingleAdvert = async (advertId: string) => {
  store.dispatch(setGettingAdminChurchAdverts(true));
  const params: any = {
    advertId,
  };
  await api
    .get("admin/adverts/single", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setAdminChurchAdverts(data));
      store.dispatch(setGettingAdminChurchAdverts(false));
    })
    .catch((err) => {
      store.dispatch(setGettingAdminChurchAdverts(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const completeAdsPayment = async (
  imageUrl: string,
  description: string,
  startDate: string,
  endDate: string,
  advertType: string,
  paymentRef: string
) => {
  store.dispatch(setGettingAdminChurchAdverts(true));
  const body: any = {
    imageUrl,
    description,
    startDate,
    endDate,
    advertType,
    paymentRef,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .post("admin/ads", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      const { data } = res?.data;
      toast.success(data?.message || "Successful");
      store.dispatch(setCreatingAdminChurchAdverts(false));
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      store.dispatch(setCreatingAdminChurchAdverts(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const createAdvert = async (advertFee: number, email?: string) => {
  store.dispatch(setCreatingAdminChurchAdverts(true));
  await axios
    .post(
      "https://api.paystack.co/transaction/initialize",
      {
        email,
        amount: advertFee * 100, // Paystack amount is in kobo
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        bearer: "subaccount",
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
        },
      }
    )
    .then(async (res: any) => {
      store.dispatch(
        getAdvertDetails({
          name: "paymentRef",
          value: res?.data?.data?.reference,
        })
      );
      console.log(res?.data);
      window.open(`${res?.data?.data?.authorization_url}`, "_blank");
      toast.success("Please come back your publish after payment");
      toast.info("Please do not reload this page.");
      store.dispatch(setCreatingAdminChurchAdverts(false));
    });
};

export const deleteAdvert = async (adsId: string) => {
  store.dispatch(setDeletingAdminChurchAdverts(true));
  const params: any = {
    adsId,
  };
  await api
    .delete("admin/ads", { params })
    .then((res) => {
      toast.success("Successful");
      store.dispatch(setDeletingAdminChurchAdverts(false));
    })
    .catch((err) => {
      store.dispatch(setDeletingAdminChurchAdverts(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getAdsSettings = async () => {
  let res: any = await api.get("admin/ads/setting");
  return res?.data;
};
