import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setGettingWalletBalance,
  setMakingPayment,
  setwalletBalance,
} from "../../ReduxStore/features/paymentSlice";
import api from "../baseUrl";
import axios from "axios";
import {
  setGettingTransactionInvoices,
  setMakingPayments,
  setTransactionInvoices,
} from "../../ReduxStore/features/User/DonationSlice";

export const paystackFunction = async (
  amount: number,
  paymentType: string,
  email: string
) => {
  const body = {
    amount,
    purposeOfPayment: paymentType,
  };
  store.dispatch(setMakingPayments(true));
  store.dispatch(setMakingPayment(true));
  await api
    .post("user/payment-invoices", body)
    .then(async (res) => {
      const { data } = res;
      // return data;
      // calling paystack
      try {
        const response = await axios.post(
          "https://api.paystack.co/transaction/initialize",
          {
            email,
            reference: data?.data?.invoiceReference,
            amount: data?.data?.amount * 100, // Paystack amount is in kobo
            publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
            bearer: "subaccount",
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
            },
          }
        );
        window.open(`${response?.data?.data?.authorization_url}`, "_self");
        store.dispatch(setMakingPayments(false));
        store.dispatch(setMakingPayment(false));
      } catch (error: any) {
        toast.error(error?.message);
        store.dispatch(setMakingPayments(false));
        store.dispatch(setMakingPayment(false));
      }
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setMakingPayments(false));
      store.dispatch(setMakingPayment(false));
    });
};
export const completePaystackPayment = async (
  amount: number,
  email: string,
  reference: string
) => {
  store.dispatch(setMakingPayments(true));
  store.dispatch(setMakingPayment(true));

  // calling paystack
  try {
    const response = await axios.post(
      "https://api.paystack.co/transaction/initialize",
      {
        email,
        reference,
        amount: amount * 100, // Paystack amount is in kobo
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        bearer: "subaccount",
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
        },
      }
    );
    window.open(`${response?.data?.data?.authorization_url}`, "_self");
    store.dispatch(setMakingPayment(false));
    store.dispatch(setMakingPayment(false));
  } catch (error: any) {
    toast.error(error?.message);
    store.dispatch(setMakingPayments(false));
    store.dispatch(setMakingPayment(false));
  }
};

export const verifyPayment = async (reference: string) => {
  try {
    const response = await api.get(`user/payment-invoices/verify/${reference}`);

    if (response) {
      console.log(response);
      toast.success(response?.data?.data);
      store.dispatch(setMakingPayment(false));
    } else {
      toast.error("payment not veryfied");
    }
  } catch (err: any) {
    if (err?.response)
      toast.error(
        err?.response?.data?.errorDetails || err?.response?.data?.data
      );
    if (err?.message === "Network Error") toast.error(err?.message);
    store.dispatch(setGettingWalletBalance(false));
  }
};

export const getTransactionInvoices = async (
  status?: string,
  purpose?: string
) => {
  store.dispatch(setGettingTransactionInvoices(true));
  const params = {
    status,
    purpose,
  };
  await api
    .get("user/transaction/invoices", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setTransactionInvoices(data?.data));
      store.dispatch(setGettingTransactionInvoices(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingTransactionInvoices(false));
    });
};

export const getUserWalletBalance = async () => {
  store.dispatch(setGettingWalletBalance(true));
  await api
    .get("user/wallet/wallet-balance")
    .then((res) => {
      const { data } = res;
      store.dispatch(setwalletBalance(data?.data));
      store.dispatch(setGettingWalletBalance(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingWalletBalance(false));
    });
};

export const checkVerifyPaymentMade = async () => {
  const body = {
    amount: 100,
    purposeOfPayment: "church-verification",
  };

  try {
    await api.post("user/payment-invoices", body);
  } catch (err: any) {
    return err?.response?.data?.errorDetails;
  }
};
