import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setCreatingLiveStreamEvent,
  setCreatingLiveStreamLinks,
  setDeletingLiveStreamEvent,
  setDeletingLiveStreamLink,
  setEditingLiveStreamEvent,
  setGettingLiveStreamEvent,
  setGettingLiveStreamLinks,
  setLiveStreamEvents,
  setLiveStreamLinks,
} from "../../ReduxStore/features/Admin/LivestreamSlice";
import api from "../baseUrl";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const getLivestreamLinks = async (status?: string) => {
  store.dispatch(setGettingLiveStreamLinks(true));
  const params: any = {
    status,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === (null || "--")) {
      delete params[key];
    }
  });
  await api
    .get("admin/Livestream-provider", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setLiveStreamLinks(data?.data));
      store.dispatch(setGettingLiveStreamLinks(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingLiveStreamLinks(false));
    });
};

export const createLivestreamLinks = async (
  livestreamId: string,
  livestreamProviderId: string,
  link: boolean,
) => {
  store.dispatch(setCreatingLiveStreamLinks(true));
  const body: any = {
    livestreamId,
    livestreamProviderId,
    link,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("admin/livestream-links", body)
    .then((res) => {
      toast.success("successful");
      store.dispatch(setCreatingLiveStreamLinks(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingLiveStreamLinks(false));
    });
};

export const deleteLivestreamLinks = async (id: string) => {
  store.dispatch(setDeletingLiveStreamLink(true));
  await api
    .delete(`admin/livestream-link/${id}`)
    .then((res) => {
      toast.success("successful");
      store.dispatch(setDeletingLiveStreamLink(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingLiveStreamLink(false));
    });
};

export const getLivestreamEvent = async (visibility?: string) => {
  store.dispatch(setGettingLiveStreamEvent(true));
  const params: any = {
    visibility,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === (null || "--")) {
      delete params[key];
    }
  });
  await api
    .get("admin/Livestreams", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setLiveStreamEvents(data?.data));
      store.dispatch(setGettingLiveStreamEvent(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingLiveStreamEvent(false));
    });
};

export const createLivestreamsEvent = async (
  title: string,
  autoGenerateLink: boolean,
  startTime: Date,
  visibility: string,
  linkProviderList: any,
  description: string,
) => {
  store.dispatch(setCreatingLiveStreamEvent(true));
  const body: any = {
    title,
    autoGenerateLink,
    startTime,
    visibility,
    linkProviderList,
    description,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .post("admin/livestreams", body)
    .then((res) => {
      toast.success("successful");
      store.dispatch(setCreatingLiveStreamEvent(false));
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingLiveStreamEvent(false));
    });
};
export const editLivestreamsEvent = async (
  id: string,
  title: string,
  startTime: Date,
  visibility: string,
  description: string,
) => {
  store.dispatch(setEditingLiveStreamEvent(true));
  const body: any = {
    title,
    startTime,
    visibility,
    description,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .patch(`admin/livestreams/${id}`, body)
    .then((res) => {
      toast.success("successful");
      store.dispatch(setEditingLiveStreamEvent(false));
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingLiveStreamEvent(false));
    });
};

export const deleteLivestreamEvent = async (id: string) => {
  store.dispatch(setDeletingLiveStreamEvent(true));
  await api
    .delete(`admin/livestreams/${id}`)
    .then((res) => {
      toast.success("successful");
      store.dispatch(setDeletingLiveStreamEvent(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingLiveStreamEvent(false));
    });
};
