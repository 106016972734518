import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DonationsState {
  gettingChurchDonations: boolean;
  creatingDonation: boolean;
  editingDonation: boolean;
  deletingDonation: boolean;
  churchDonations: any;
  selectedDonation: any;

  // create donations state
  title: string;
  typeOfDonation: string;
  description: string;
  targetAmount: number;
  currencyId: string;
  bankAccountId: string;
  churchGroupId: string;

  // action state
  creatingChurchDonation: boolean;
}

const initialState: DonationsState = {
  gettingChurchDonations: false,
  deletingDonation: false,
  editingDonation: false,
  creatingDonation: false,
  churchDonations: null,
  selectedDonation: null,

  // create donations state
  title: "",
  typeOfDonation: "",
  description: "",
  targetAmount: 0,
  currencyId: "",
  bankAccountId: "",
  churchGroupId: "",

  // action state
  creatingChurchDonation: false,
};

export const DonationsSlice = createSlice({
  name: "Donations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurchDonations: (state, action) => {
      state.gettingChurchDonations = action.payload;
    },
    setChurchDonations: (state, action) => {
      state.churchDonations = action.payload;
    },
    setCreatingChurchDonation: (state, action) => {
      state.creatingChurchDonation = action.payload;
    },
    setEditingChurchDonation: (state, action) => {
      state.editingDonation = action.payload;
    },
    setDeletingChurchDonation: (state, action) => {
      state.deletingDonation = action.payload;
    },
    setSelectedDonation: (state, action) => {
      state.selectedDonation = action.payload;
      state.title = action.payload.title;
      state.typeOfDonation = action.payload.typeOfDonation;
      state.description = action.payload.description;
      state.targetAmount = action.payload.targetAmount;
      state.currencyId = action.payload.currencyId;
      state.bankAccountId = action.payload.bankAccountId;
      state.churchGroupId = action.payload.churchGroupId;
    },

    getDonationDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingChurchDonations,
  setChurchDonations,
  getDonationDetails,
  setCreatingChurchDonation,
  setDeletingChurchDonation,
  setSelectedDonation,
  setEditingChurchDonation,
} = DonationsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.Donationser.value)`
export const selectAdminDonations = (state: RootState) => state.adminDonations;

export default DonationsSlice.reducer;
