import React, { useState } from "react";
import {
  UserProfileContainer,
  GroupContainer,
  FirstSection,
  SecondSection,
  ActiveGroupContainer,
  Avatar,
  SwitchChurch,
} from "./styles";
import {
  FaUser,
  FaChurch,
  FaBirthdayCake,
  FaEnvelope,
  FaPhoneAlt,
} from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import EditableName from "./EditableName";
import EditableChurch from "./EditableChurch";
import EditableDateOfBirth from "./EditableDateofBirth";
import EditableEmail from "./EditableEmail";
import EditablePhone from "./EditablePhone";
import UserAvatar1 from "./UserAvatar1";
import ChurchList from "./ChurchList";
import { useEffect } from "react";
import { getUserActiveChurch } from "../../../../Apis/UserApis/userProfile";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getUserProfileFormDetails,
  selectUserProfile,
} from "../../../../ReduxStore/features/User/UserProfileSlice";
import SingleProfileDetail from "./SingleProfileDetail";
import { getJoinedGroups } from "../../../../Apis/UserApis/userChurchGroup";
import {
  getUserGroupDetails,
  selectUserCgroup,
} from "../../../../ReduxStore/features/User/UserGroupSlice";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const [churchList, setChurchList] = useState(false);
  const { userProfile, editingUserProfile } = useAppSelector(selectUserProfile);
  const { joinedGroups } = useAppSelector(selectUserCgroup);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getUserProfileFormDetails({ name, value }));
  };

  useEffect(() => {
    getUserActiveChurch();
    getJoinedGroups();
  }, [editingUserProfile]);

  return (
    <UserProfileContainer>
      <>
        <Avatar>
          <UserAvatar1
            name={
              userProfile?.userId?.lastName +
              " " +
              userProfile?.userId?.firstName
            }
            avatarUrl="assets/images/img1.png"
          />
        </Avatar>
        <SwitchChurch>
          <button
            className="switchchurchbutton"
            onClick={() => {
              setChurchList(true);
            }}
          >
            Switch Church
          </button>
          {churchList && <ChurchList setState={setChurchList} />}
        </SwitchChurch>

        <SingleProfileDetail
          cardIcon={<FaUser fontSize={19} className="fauser" />}
          editForm={
            <EditableName
              defaultValue={userProfile?.userId?.firstName}
              name="firstName"
              handleChange={handleChange}
            />
          }
          profileProp="First Name"
          profileValue={userProfile?.userId?.firstName}
          showEditPen={true}
        />
        <SingleProfileDetail
          cardIcon={<FaUser fontSize={19} className="fauser" />}
          editForm={
            <EditableName
              defaultValue={userProfile?.userId?.lastName}
              name="lastName"
              handleChange={handleChange}
            />
          }
          profileProp="Last Name"
          profileValue={userProfile?.userId?.lastName}
          showEditPen={true}
        />

        <SingleProfileDetail
          cardIcon={<FaChurch fontSize={19} className="fachurch" />}
          editForm={<EditableChurch user={userProfile?.churchId?.name} />}
          profileProp="Church"
          profileValue={userProfile?.churchId?.name}
          showEditPen={false}
        />

        <SingleProfileDetail
          cardIcon={<FaBirthdayCake fontSize={19} className="fauser" />}
          editForm={
            <EditableDateOfBirth
              defaultValue={userProfile?.userId?.dateOfBirth}
              name="dob"
              handleChange={handleChange}
            />
          }
          profileProp="Birthday"
          profileValue={userProfile?.userId?.dateOfBirth}
          showEditPen={true}
        />

        <SingleProfileDetail
          cardIcon={<FaEnvelope fontSize={19} className="fauser" />}
          editForm={
            <EditableEmail
              defaultValue={userProfile?.userId?.email}
              handleChange={handleChange}
              name="email"
            />
          }
          profileProp="Email"
          profileValue={userProfile?.userId?.email}
          showEditPen={false}
        />

        <SingleProfileDetail
          cardIcon={<FaPhoneAlt fontSize={19} className="fauser" />}
          editForm={
            <EditablePhone
              defaultValue={userProfile?.userId?.phoneNo}
              name="phoneNo"
              handleChange={handleChange}
            />
          }
          profileProp="Phone"
          profileValue={userProfile?.userId?.phoneNo}
          showEditPen={true}
        />
      </>
      <>
        <GroupContainer>
          <FirstSection>
            <p className="groups">Groups</p>
            <p className="joingroup">Join Groups</p>
          </FirstSection>

          {joinedGroups?.itemsList?.map((group: any) => (
            <SecondSection>
              <ActiveGroupContainer>
                <p>{group?.name}</p>
                <p>Member</p>
              </ActiveGroupContainer>
              <BsBoxArrowRight
                color="gray"
                fontSize={25}
                fontWeight={600}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/groups");
                  dispatch(
                    getUserGroupDetails({
                      name: "churchGroupId",
                      value: group?.churchGroupId,
                    })
                  );
                }}
              />
            </SecondSection>
          ))}
        </GroupContainer>
      </>
    </UserProfileContainer>
  );
};

export default UserProfile;
