import AuthMain from "../AuthMain";
import { AuthLogo, AuthTitle, AuthSubTitle } from "../style";
import FormWrapper from "../../../Layouts/FormWrapper";
import TextInput from "../../../Components/Form/inputs/TextInput";
import { useAppDispatch, useAppSelector } from "../../../ReduxStore/app/hooks";
import {
  getAuthFormDetails,
  selectAuth,
} from "../../../ReduxStore/features/authSlice";
import PrimaryButton from "../../../Components/Form/Buttons/PrimaryButton";
import { verifyEmail } from "../../../Apis/Auth/signupApis";
import { useState } from "react";
import ErrorMessage from "../../../Components/Reusables/MessagePopups/ErrorMessage";
const AdminVerifyOTP = () => {
  const dispatch = useAppDispatch();
  const { email, otp, verifyingEmail } = useAppSelector(selectAuth);
  const [errors, setErrors] = useState<any>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAuthFormDetails({ name, value }));
  };

  const handleSubmit = (e: any) => {
    setErrors([]);
    e.preventDefault();
    if (!email) {
      setErrors([...errors, "Email cannot be empty"]);
    } else if (!otp) {
      setErrors([...errors, "OTP cannot be empty"]);
    } else {
      verifyEmail(email, otp, "admin");
    }
  };

  return (
    <AuthMain>
      <AuthLogo src="/assets/images/png/logo.png" />
      <AuthTitle>Verify OTP</AuthTitle>
      <AuthSubTitle>Verify your otp to complete signup</AuthSubTitle>
      <FormWrapper>
        <TextInput
          placeholder="Enter email"
          handleChange={handleChange}
          name="email"
          type="text"
          value={email}
        />
        <TextInput
          placeholder="Enter OTP"
          handleChange={handleChange}
          name="otp"
          type="text"
          value={otp}
        />
        {errors?.length > 0 && (
          <ErrorMessage
            message={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {errors?.map((error: any) => (
                  <span key={error}>{error}</span>
                ))}
              </div>
            }
          />
        )}
        <PrimaryButton
          text="Verify"
          handleClick={handleSubmit}
          loading={verifyingEmail}
        />
      </FormWrapper>
    </AuthMain>
  );
};

export default AdminVerifyOTP;
