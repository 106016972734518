import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  setChurchEvents,
  setCreatingActivity,
  setCreatingEvent,
  setCreatingEventLocation,
  setCreatingEventMedium,
  setDeletingEventLocation,
  setDeletingEventMedium,
  setEditingEventLocation,
  setEditingEventMedium,
  setEventLocations,
  setEventMediums,
  setGettingChurchEvents,
  setGettingEventLocation,
  setGettingEventMedium,
  setGettingChurchActivities,
  setChurchActivities,
  setDeletingChurchActivity,
  setDeletingChurchEvent,
  setGettingAdminChurch,
  setAdminChurch,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const getEventMedium = async () => {
  store.dispatch(setGettingEventMedium(true));
  await api
    .get("admin/eventMedium")
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setEventMediums(data));
      store.dispatch(setGettingEventMedium(false));
    })
    .catch((err) => {
      store.dispatch(setGettingEventMedium(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const AddEventMedium = async (name: string) => {
  store.dispatch(setCreatingEventMedium(true));
  const body: any = {
    name,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .post("admin/eventMedium", body)
    .then(() => {
      store.dispatch(setCreatingEventMedium(false));
      store.dispatch(handleModal(0));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setCreatingEventMedium(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const deleteEventMedium = async (eventMediumId: string) => {
  store.dispatch(setDeletingEventMedium(true));
  const body: any = {
    eventMediumId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .delete("admin/eventMedium", { data: body })
    .then(() => {
      store.dispatch(setDeletingEventMedium(false));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setDeletingEventMedium(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const editEventMedium = async (_id: string, name: string) => {
  store.dispatch(setEditingEventMedium(true));
  const body: any = {
    eventMediumId: _id,
    name,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .put("admin/eventMedium", body)
    .then(() => {
      store.dispatch(setEditingEventMedium(false));
      store.dispatch(handleModal(0));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setEditingEventMedium(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getSingleEventMedium = async (eventMediumId: string) => {
  store.dispatch(setGettingEventMedium(true));
  const params = {
    eventMediumId,
  };
  await api
    .get("admin/eventMedium", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setEventMediums(data));
      store.dispatch(setGettingEventMedium(false));
    })
    .catch((err) => {
      store.dispatch(setGettingEventMedium(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getEventLocations = async (churchId: string) => {
  store.dispatch(setGettingEventLocation(true));
  const params: any = {
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/eventLocation")
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setEventLocations(data));
      store.dispatch(setGettingEventLocation(false));
    })
    .catch((err) => {
      store.dispatch(setGettingEventLocation(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const CreateEventLocation = async (
  address: string,
  medium: string,
  churchId: string
) => {
  store.dispatch(setCreatingEventLocation(true));
  const body: any = {
    address,
    medium,
    churchId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .post("admin/eventLocation", body)
    .then(() => {
      store.dispatch(handleModal(0));
      store.dispatch(setCreatingEventLocation(false));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setCreatingEventLocation(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const deleteEventLocation = async (eventLocationId: string) => {
  store.dispatch(setDeletingEventLocation(true));
  const body: any = {
    eventLocationId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .delete("admin/eventLocation", { data: body })
    .then(() => {
      store.dispatch(setDeletingEventLocation(false));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setDeletingEventLocation(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const editEventLocation = async (
  address: string,
  medium: string,
  eventLocationId: string
) => {
  store.dispatch(setEditingEventLocation(true));
  const body: any = {
    address,
    medium,
    eventLocationId,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .put("admin/eventLocation", body)
    .then(() => {
      store.dispatch(handleModal(0));
      store.dispatch(setEditingEventLocation(false));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setEditingEventLocation(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getSingleEventLocation = async (eventLocationId: string) => {
  store.dispatch(setGettingEventLocation(true));
  const params = {
    eventLocationId,
  };
  await api
    .get("admin/eventLocation", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setEventLocations(data));
      store.dispatch(setGettingEventLocation(false));
    })
    .catch((err) => {
      store.dispatch(setGettingEventLocation(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const CreateEvent = async (
  title: string,
  message: string,
  startDate: number,
  endDate: number,
  allDay: boolean,
  repeat: boolean,
  location: string,
  liveStream: boolean,
  fileUrl: string,
  groupIds: string[],
  inviteType: any
) => {
  store.dispatch(setCreatingEvent(true));
  const body: any = {
    title,
    message,
    startDate,
    endDate,
    allDay,
    repeat,
    location,
    liveStream,
    fileUrl,
    groupIds,
    bannerUrl: fileUrl,
    inviteType,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .post(`admin/church-events`, body)
    .then(() => {
      store.dispatch(setCreatingEvent(false));
      store.dispatch(handleModal(0));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setCreatingEvent(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const editEvent = async (
  title: string,
  message: string,
  startDate: number,
  endDate: number,
  allDay: boolean,
  repeat: boolean,
  location: string,
  liveStream: boolean,
  fileUrl: string,
  groupIds: string[],
  inviteType: any,
  id: string
) => {
  store.dispatch(setCreatingEvent(true));
  const body: any = {
    title,
    message,
    startDate,
    endDate,
    allDay,
    repeat,
    location,
    liveStream,
    fileUrl,
    groupIds,
    bannerUrl: fileUrl,
    inviteType,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .patch(`admin/church-events/${id}`, body)
    .then(() => {
      store.dispatch(setCreatingEvent(false));
      store.dispatch(handleModal(0));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setCreatingEvent(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const CreateActivity = async (
  title: string,
  message: string,
  startDate: number,
  endDate: number,
  allDay: boolean,
  repeat: boolean,
  location: string,
  liveStream: boolean,
  fileUrl: string,
  groupIds: string[],
  inviteType: string
) => {
  store.dispatch(setCreatingActivity(true));
  const body: any = {
    title,
    message,
    startDate,
    endDate,
    allDay,
    repeat,
    location,
    liveStream,
    fileUrl,
    groupIds,
    inviteType,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .post("admin/church-activities", body)
    .then(() => {
      store.dispatch(setCreatingActivity(false));
      store.dispatch(handleModal(0));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setCreatingActivity(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const editActivity = async (
  title: string,
  message: string,
  startDate: number,
  endDate: number,
  allDay: boolean,
  repeat: boolean,
  location: string,
  liveStream: boolean,
  fileUrl: string,
  groupIds: string[],
  inviteType: string,
  id: string
) => {
  store.dispatch(setCreatingActivity(true));
  const body: any = {
    title,
    message,
    startDate,
    endDate,
    allDay,
    repeat,
    location,
    liveStream,
    fileUrl,
    groupIds,
    inviteType,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });
  await api
    .patch(`admin/church-activities/${id}`, body)
    .then(() => {
      store.dispatch(setCreatingActivity(false));
      store.dispatch(handleModal(0));
      toast?.success("Successful");
    })
    .catch((err) => {
      store.dispatch(setCreatingActivity(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getChurchEvents = async () => {
  store.dispatch(setGettingChurchEvents(true));
  await api
    .get("admin/church-events")
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setChurchEvents(data));
      store.dispatch(setGettingChurchEvents(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchEvents(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getChurchActivities = async () => {
  store.dispatch(setGettingChurchActivities(true));
  await api
    .get("admin/church-activities")
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setChurchActivities(data));
      store.dispatch(setGettingChurchActivities(false));
    })
    .catch((err) => {
      store.dispatch(setGettingChurchActivities(false));
      if (err?.response)
        toast.error(err?.response?.data?.data || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const deleteChurchActivity = async (activityId: string) => {
  store.dispatch(setDeletingChurchActivity(true));
  await api
    .delete(`admin/church-activities/${activityId}`)
    .then(() => {
      store.dispatch(setDeletingChurchActivity(false));
      toast.success(`Successful`);
    })
    .catch((err) => {
      store.dispatch(setDeletingChurchActivity(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const deleteChurchEvent = async (eventId: string) => {
  store.dispatch(setDeletingChurchEvent(true));
  await api
    .delete(`admin/church-events/${eventId}`)
    .then(() => {
      store.dispatch(setDeletingChurchEvent(false));
      toast.success(`Successful`);
    })
    .catch((err) => {
      store.dispatch(setDeletingChurchEvent(false));
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const getAdminChurch = async () => {
  store.dispatch(setGettingAdminChurch(true));
  await api
    .get("admin/church/my-church")
    .then((res) => {
      console.log(res);
      const { data } = res?.data;
      store.dispatch(setAdminChurch(data));
      store.dispatch(setGettingAdminChurch(false));
    })
    .catch((err) => {
      store.dispatch(setGettingAdminChurch(false));
      if (err?.response)
        toast.error(err?.response?.data?.data || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};
