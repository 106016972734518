import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { inviteGroupMemberInput } from "../../Utils/authInput";
import { TextArea, selectStyles } from "../../Components/Form/inputs/styles";
import Select from "react-select";
import { getAdminChurcheMembers } from "../../Apis/AdminApis/ChurchManager";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import {
  getGroupDetails,
  selectAdminGroups,
} from "../../ReduxStore/features/Admin/GroupSlice";
import { inviteMember } from "../../Apis/AdminApis/churchGroup";
import { getUserActiveChurch } from "../../Apis/UserApis/userProfile";

const InviteGroupMember = () => {
  const { inviteeEmail, invitingMember, groupDetails } =
    useAppSelector(selectAdminGroups);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getGroupDetails({ name, value }));
  };

  const handleMultiSelect = (value: any, name: any) => {
    dispatch(getGroupDetails({ name, value: value?.value }));
  };

  useEffect(() => {
    getUserActiveChurch();
  }, []);

  useEffect(() => {
    getAdminChurcheMembers(userProfile?.churchId?._id);
  }, [userProfile]);

  return (
    <CustomModal
      id={26}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            // alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            {inviteGroupMemberInput?.map((input: any) => (
              <>
                {input?.type === "textarea" ? (
                  <TextArea
                    placeholder={input.placeholder}
                    name={input?.name}
                    onChange={handleTextAreaChange}
                    // value={input?.defaultValue()}
                  />
                ) : input?.type === "select" ? (
                  <Select
                    placeholder={input?.placeholder}
                    options={input?.options()}
                    name={input?.name}
                    onChange={(e: any) => handleMultiSelect(e, input?.name)}
                    styles={selectStyles}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <TextInput
                      placeholder={input.placeholder}
                      type={input?.type}
                      name={input?.name}
                      handleChange={handleChange}
                      //   defaultValue={input?.defaultValue()}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
          <PrimaryButton
            text="Send invite"
            handleClick={(e: any) => {
              e.preventDefault();
              inviteeEmail &&
                groupDetails?.churchGroupDetails?._id &&
                inviteMember(
                  inviteeEmail,
                  groupDetails?.churchGroupDetails?._id
                );
            }}
            loading={invitingMember}
          />
        </FormWrapper>
      }
    />
  );
};

export default InviteGroupMember;
