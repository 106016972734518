import { useEffect } from "react";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectNav } from "../../../../ReduxStore/features/navSlice";
import { sermonConfigInnerNav } from "../../../../Utils/innerHeaderNavs";
import WallHeader from "./WallHeader";
import WrittenSermon from "./WrittenSermon";
import VideoSermon from "./VideoSermon";
import AudioSermon from "./AudioSermon";
import { IndexContainer } from "./styles";
import { getSermons } from "../../../../Apis/AdminApis/Media";
import { selectAdminChurchSermon } from "../../../../ReduxStore/features/Admin/SermonSlice";

const PrayerWall = () => {
  const { currentInnerNav } = useAppSelector(selectNav);
  const { pageId, perPage } = useAppSelector(selectAdminChurchSermon);

  useEffect(() => {
    getSermons("", pageId, perPage);
  }, [pageId]);

  return (
    <IndexContainer>
      <WallHeader listItems={sermonConfigInnerNav} />
      {currentInnerNav === 1 ? (
        <WrittenSermon />
      ) : currentInnerNav === 2 ? (
        <VideoSermon />
      ) : (
        <AudioSermon />
      )}
    </IndexContainer>
  );
};

export default PrayerWall;
