import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import navReducer from "../features/navSlice";
import formReducer from "../features/formSlice";
import paymentReducer from "../features/paymentSlice";
import superAdminOverviewReducer from "./../features/SuperAdmin/OverviewSlice";
import superAdminUserManagerReducer from "./../features/SuperAdmin/userManagerSlice";
import superAdminSubscriptionsReducer from "./../features/SuperAdmin/subcriptionsSlice";
import superAdminManageChurchesReducer from "../features/SuperAdmin/manageChurchesSlice";
import superAdminWalletReducer from "../features/SuperAdmin/WalletSlice";
import superAdminConfigurationsReducer from "../features/SuperAdmin/ConfigurationsSlice";
import superAdminTransactionReducer from "../features/SuperAdmin/TransactionSlice";
import adminDonationsReducer from "./../features/Admin/DonationsSlice";
import userAnnouncementsReducer from "../features/User/AnnouncementSlice";
import userPrayerWallReducer from "../features/User/PrayerWallSlice";
import userProfileReducer from "../features/User/UserProfileSlice";
import userChurchActivitiesReducer from "../features/User/ChurchActivitiesSlice";
import userDailyScripturesReducer from "../features/User/DailyScripturesSlice";
import userDailyReflectionsReducer from "../features/User/DailyReflectionSlice";
import UserGroupReducer from "../features/User/UserGroupSlice";
import UserPostsReducer from "../features/User/UserPostSlice";
import dontaionsReducer from "../features/User/DonationSlice";
import BibleReducer from "../features/BibleSlice";
import authReducer from "../features/authSlice";
import adminAnnouncementsReducer from "../features/Admin/AnnouncementSlice";
import adminChurchPrayerWallReducer from "../features/Admin/ChurchPrayerWallSlice";
import adminWalletReducer from "../features/Admin/WalletSlice";
import adminUserManagerReducer from "../features/Admin/UserManagerSlice";
import adminManageChurchesReducer from "../features/Admin/manageChurchesSlice";
import adminChurchGroupReducer from "../features/Admin/GroupSlice";
import adminActivitiesReducer from "../features/Admin/ActivitiesSlice";
import adminChurchSermonReducer from "../features/Admin/SermonSlice";
import adminChurchAdvertReducer from "../features/Admin/AdvertSlice";
import adminLiveStreamsReducer from "../features/Admin/LivestreamSlice";
import adminBookingReducer from "../features/Admin/BookingSlice";
import adminPostReducer from "../features/Admin/AdminPostSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    nav: navReducer,
    form: formReducer,
    payment: paymentReducer,
    superAdminOverview: superAdminOverviewReducer,
    superAdminUserManager: superAdminUserManagerReducer,
    superAdminSubscriptions: superAdminSubscriptionsReducer,
    superAdminManageChurches: superAdminManageChurchesReducer,
    superAdminWallet: superAdminWalletReducer,
    superAdminConfigurations: superAdminConfigurationsReducer,
    superAdminTransactions: superAdminTransactionReducer,
    adminDonations: adminDonationsReducer,
    userAnnouncements: userAnnouncementsReducer,
    userPrayerWall: userPrayerWallReducer,
    userProfile: userProfileReducer,
    userChurchActivities: userChurchActivitiesReducer,
    userDailyScriptures: userDailyScripturesReducer,
    userDailyReflections: userDailyReflectionsReducer,
    userGroup: UserGroupReducer,
    userPosts: UserPostsReducer,
    donations: dontaionsReducer,
    Bible: BibleReducer,
    auth: authReducer,
    adminAnnouncements: adminAnnouncementsReducer,
    adminChurchPrayerWall: adminChurchPrayerWallReducer,
    adminWallet: adminWalletReducer,
    adminUserManager: adminUserManagerReducer,
    adminManageChurches: adminManageChurchesReducer,
    adminChurchGroup: adminChurchGroupReducer,
    adminActivities: adminActivitiesReducer,
    adminChurchSermon: adminChurchSermonReducer,
    adminChurchAdvert: adminChurchAdvertReducer,
    adminLiveStreams: adminLiveStreamsReducer,
    adminBooking: adminBookingReducer,
    adminPosts: adminPostReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
