import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AnnouncementsState {
  gettingAnnouncements: boolean;
  creatingAnnouncement: boolean;
  editingAnnouncement: boolean;
  deletingAnnouncement: boolean;
  announcements: any;
  selectedAnnouncement: any;

  //   states
  groupId: string;
  announcementTitle: string;
  announcementText: string;
}

const initialState: AnnouncementsState = {
  gettingAnnouncements: false,
  creatingAnnouncement: false,
  editingAnnouncement: false,
  deletingAnnouncement: false,
  announcements: null,
  selectedAnnouncement: null,

  //   state
  groupId: "",
  announcementTitle: "",
  announcementText: "",
};

export const AnnouncementsSlice = createSlice({
  name: "Announcements",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingAnnouncements: (state, action) => {
      state.gettingAnnouncements = action.payload;
    },
    setCreatingAnnouncement: (state, action) => {
      state.creatingAnnouncement = action.payload;
    },
    setEditingAnnouncements: (state, action) => {
      state.editingAnnouncement = action.payload;
    },
    setDeletingAnnouncements: (state, action) => {
      state.deletingAnnouncement = action.payload;
    },
    setAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    setSelectedAnnouncements: (state, action) => {
      state.announcementTitle = action.payload.title;
      state.announcementText = action.payload.message;
      state.selectedAnnouncement = action.payload;
    },
    getAdminAnnouncementFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingAnnouncements,
  setAnnouncements,
  setCreatingAnnouncement,
  setDeletingAnnouncements,
  setEditingAnnouncements,
  getAdminAnnouncementFormDetails,
  setSelectedAnnouncements,
} = AnnouncementsSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminAnnouncements = (state: RootState) =>
  state.adminAnnouncements;

export default AnnouncementsSlice.reducer;
