import { HiMenuAlt3 } from "react-icons/hi";
import { TbBell } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../ReduxStore/app/hooks";
import { selectNav, SidebarToggle } from "../../ReduxStore/features/navSlice";
import {
  HeaderContainer,
  HeaderLeftContent,
  HeaderLogo,
  HeaderRightContent,
  HeaderPageTitle,
  Avatar,
  Menu,
} from "./styles";
import { selectUserProfile } from "../../ReduxStore/features/User/UserProfileSlice";
import { selectUser } from "../../ReduxStore/features/userSlice";

const Header = () => {
  const { pathname } = useLocation();
  const { toggleSidebar } = useAppSelector(selectNav);
  const { userProfile } = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(selectUser);

  return (
    <HeaderContainer>
      <HeaderLeftContent>
        <HeaderLogo src="/assets/images/png/logo.png" />
        <HeaderPageTitle>
          {currentUser?.userType === "churchAdmin" && (
            <>
              {" "}
              {pathname !== "/"
                ? pathname.substring(1, pathname.length).replace(/-/g, " ")
                : `${userProfile?.churchId?.name} Dashboard`}
            </>
          )}
          {/* {currentUser?.userType === "user" && (
            <>
              {pathname !== "/"
                ? pathname.substring(1, pathname.length).replace(/-/g, " ")
                : `${
                    userProfile?.userId?.firstName +
                    " " +
                    userProfile?.userId?.lastName
                  } Dashboard`}
            </>
          )} */}
        </HeaderPageTitle>
      </HeaderLeftContent>
      <HeaderRightContent>
        <TbBell />
        <Avatar></Avatar>
        <Menu onClick={() => dispatch(SidebarToggle(!toggleSidebar))}>
          <HiMenuAlt3 />
        </Menu>
      </HeaderRightContent>
    </HeaderContainer>
  );
};

export default Header;
