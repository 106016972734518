import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { addLocationInputs } from "../../Utils/authInput";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import {
  getAdminActivitiesDetails,
  selectAdminActivities,
} from "../../ReduxStore/features/Admin/ActivitiesSlice";
import { selectStyles } from "../../Components/Form/inputs/styles";
import Select from "react-select";
import { editEventLocation } from "../../Apis/AdminApis/ChurchActivities";

const EditEventLocationModal = () => {
  const { locationAddress, locationMedium, editingEventLocation, locationId } =
    useAppSelector(selectAdminActivities);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminActivitiesDetails({ name, value }));
  };

  const handleSelect = (e: any, name: any) => {
    dispatch(getAdminActivitiesDetails({ name, value: e.value }));
  };

  console.log(
    locationAddress,
    locationMedium,
    editingEventLocation,
    locationId
  );

  return (
    <CustomModal
      id={20}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {addLocationInputs?.map((input: any) => (
            <>
              {input?.type === "select" ? (
                <Select
                  options={input?.options()}
                  name={input?.name}
                  onChange={(e: any) => handleSelect(e, input?.name)}
                  styles={selectStyles}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              editEventLocation(locationAddress, locationMedium, locationId);
            }}
            loading={editingEventLocation}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditEventLocationModal;
