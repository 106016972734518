import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  setApprovedUserPost,
  setCommentingUserPost,
  setCreatingUserPost,
  setGettingApprovedUserPost,
  setGettingHashTagPosts,
  setGettingMyPosts,
  setGettingSinglePost,
  setGettingTrendingUserPost,
  setGettingUserPostByChurch,
  setHashtagPosts,
  setLikingAndDislikingUserPost,
  setMyPosts,
  setSinglePost,
  setTrendingUserPost,
  setUpdatingUserPost,
  setUserPostByChurch,
} from "../../ReduxStore/features/User/UserPostSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const createPost = async (
  contentType: string,
  title: string,
  description: string,
  hashtags: any,
  file: any
) => {
  store.dispatch(setCreatingUserPost(true));

  const body = {
    contentType,
    title,
    description,
    hashtags,
    file,
  };
  await api
    .post(`user/post/create`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      toast.success("Successful");
      store.dispatch(setCreatingUserPost(false));
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingUserPost(false));
    });
};

export const updatePost = async (
  postId: string,
  contentType: string,
  title: string,
  description: string,
  hashtags: string[],
  file: any
) => {
  store.dispatch(setUpdatingUserPost(true));
  const params = {
    postId,
  };
  const body = {
    contentType,
    title,
    description,
    hashtags,
    file,
  };
  await api
    .put(`user/post/update`, body, { params })
    .then(() => {
      toast.success("Successful");
      store.dispatch(setUpdatingUserPost(false));
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingUserPost(false));
    });
};

export const likeAndDislikePOst = async (postId: string, action: string) => {
  store.dispatch(setLikingAndDislikingUserPost(true));
  const params = {
    postId,
    action,
  };
  await api
    .put(`user/post/like-dislike?postId=${postId}&action=${action}`, {
      params,
    })
    .then(() => {
      //   toast.success("Successful");
      store.dispatch(setLikingAndDislikingUserPost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLikingAndDislikingUserPost(false));
    });
};

export const commentPOst = async (postId: string, comment: string) => {
  store.dispatch(setCommentingUserPost(true));

  const body = {
    postId,
    body: comment,
  };
  await api
    .put(`user/post/comment`, body)
    .then(() => {
      toast.success("Successful");
      store.dispatch(setCommentingUserPost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCommentingUserPost(false));
    });
};

export const getApprovedPosts = async (pageSize: number, page: number) => {
  store.dispatch(setGettingApprovedUserPost(true));

  const params = {
    page,
    pageSize,
  };
  await api
    .get(`user/post`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setApprovedUserPost(data));
      store.dispatch(setGettingApprovedUserPost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingApprovedUserPost(false));
    });
};

export const getPostsByChurch = async (
  churchId: string,
  pageSize: number,
  page: number
) => {
  store.dispatch(setGettingUserPostByChurch(true));

  const params = {
    churchId,
    page,
    pageSize,
  };
  await api
    .get(`user/post/bychurch`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setUserPostByChurch(data));
      store.dispatch(setGettingUserPostByChurch(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingUserPostByChurch(false));
    });
};

export const getTrendingPosts = async (pageSize: number, page: number) => {
  store.dispatch(setGettingTrendingUserPost(true));
  const params = {
    page,
    pageSize,
  };
  await api
    .get(`user/post/trending`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setTrendingUserPost(data));
      store.dispatch(setGettingTrendingUserPost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingTrendingUserPost(false));
    });
};

export const getPostsHashtag = async (
  hashtag: string,
  pageSize: number,
  page: number
) => {
  store.dispatch(setGettingHashTagPosts(true));

  const params = {
    hashtag,
    page,
    pageSize,
  };
  await api
    .get(`user/post/hashtag`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setHashtagPosts(data));
      store.dispatch(setGettingHashTagPosts(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHashTagPosts(false));
    });
};

export const getMyPosts = async (
  churchId: string,
  pageSize: number,
  page: number
) => {
  store.dispatch(setGettingMyPosts(true));
  const params = {
    churchId,
    page,
    pageSize,
  };
  await api
    .get(`user/post/my-post`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setMyPosts(data));
      store.dispatch(setGettingMyPosts(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingMyPosts(false));
    });
};

export const getSinglePost = async (postId: string) => {
  store.dispatch(setGettingSinglePost(true));

  const params = {
    postId,
  };
  await api
    .get(`user/post/byId`, { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setSinglePost(data));
      store.dispatch(setGettingSinglePost(false));
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSinglePost(false));
    });
};
