import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ChurchPrayerWallState {
  gettingChurchPrayerRequest: boolean;
  ChurchPrayerRequest: any;
  gettingChurchTestimonies: boolean;
  ChurchTestimonies: any;
  updatingChurchPrayers: boolean;
  gettingChurchPrayers: boolean;
  approvingTestimony: boolean;
  churchPrayers: any;
  selectedAnnouncement: any;
  gettingChurchReflections: boolean;
  churchReflections: any;
  updatingChurchReflection: boolean;

  //   states
  title: string;
  prayer: string;
  status: string;

  // reflection states
  reflectionTitle: string;
  reflectionDate: string;
  reflectionStatus: string;
  studyVerses: any;
  text: string;
  reflectionId: string;

  // pagination
  page: number;
  pageSize: number;
}

const initialState: ChurchPrayerWallState = {
  gettingChurchPrayerRequest: false,
  ChurchPrayerRequest: null,
  gettingChurchTestimonies: false,
  ChurchTestimonies: null,
  gettingChurchPrayers: false,
  updatingChurchPrayers: false,
  approvingTestimony: false,
  churchPrayers: null,
  selectedAnnouncement: null,
  gettingChurchReflections: false,
  churchReflections: null,
  updatingChurchReflection: false,

  //   state
  title: "",
  prayer: "",
  status: "",

  // reflection states
  reflectionTitle: "",
  reflectionDate: "",
  reflectionStatus: "",
  studyVerses: [],
  text: "",
  reflectionId: "",

  // pagination
  page: 1,
  pageSize: 1,
};

export const ChurchPrayerWallSlice = createSlice({
  name: "ChurchPrayerWall",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingChurchPrayerRequest: (state, action) => {
      state.gettingChurchPrayerRequest = action.payload;
    },
    setChurchPrayerRequest: (state, action) => {
      state.ChurchPrayerRequest = action.payload;
    },
    setGettingChurchTestimonies: (state, action) => {
      state.gettingChurchTestimonies = action.payload;
    },
    setChurchTestimonies: (state, action) => {
      state.ChurchTestimonies = action.payload;
    },
    setUpdatingChurchPrayers: (state, action) => {
      state.updatingChurchPrayers = action.payload;
    },
    setGettingChurchPrayers: (state, action) => {
      state.gettingChurchPrayers = action.payload;
    },
    setChurchPrayers: (state, action) => {
      state.churchPrayers = action.payload;
    },
    setApprovingTestimony: (state, action) => {
      state.approvingTestimony = action.payload;
    },
    setSelectedChurchPrayerWall: (state, action) => {
      state.selectedAnnouncement = action.payload;
    },
    setGettingChurchReflections: (state, action) => {
      state.gettingChurchReflections = action.payload;
    },
    setChurchReflection: (state, action) => {
      state.churchReflections = action.payload;
    },
    setUpdatingChurchReflection: (state, action) => {
      state.updatingChurchReflection = action.payload;
    },
    reflectionToEdit: (state, action) => {
      state.reflectionTitle = action.payload?.title;
      state.reflectionStatus = action.payload?.status;
      state.text = action.payload?.text;
      state.studyVerses = action?.payload?.studyVerses;
      state.reflectionId = action.payload._id;
    },
    addToStudyVerses: (state, action) => {
      const existing = state.studyVerses?.filter(
        (x: any) => x === action.payload
      );
      if (!existing?.length) {
        state.studyVerses = [...state.studyVerses, action.payload];
      }
    },
    removeFromStudyVerses: (state, action) => {
      state.studyVerses = state.studyVerses?.filter(
        (x: any) => x !== action.payload
      );
    },
    getADminChurchPrayerFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  getADminChurchPrayerFormDetails,
  setSelectedChurchPrayerWall,
  setGettingChurchPrayerRequest,
  setChurchTestimonies,
  setChurchPrayerRequest,
  setGettingChurchTestimonies,
  setGettingChurchPrayers,
  setChurchPrayers,
  setApprovingTestimony,
  setUpdatingChurchPrayers,
  setChurchReflection,
  setGettingChurchReflections,
  setUpdatingChurchReflection,
  addToStudyVerses,
  removeFromStudyVerses,
  reflectionToEdit,
} = ChurchPrayerWallSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminChurchPrayerWall = (state: RootState) =>
  state.adminChurchPrayerWall;

export default ChurchPrayerWallSlice.reducer;
