import { FC } from "react";
import {
  EditButton,
  NameContainer,
  ProfileDetailContainer,
  ProfileProp,
  ProfileValueContainer,
} from "./styles";
import { FaPen } from "react-icons/fa";
import { useState } from "react";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { selectUserProfile } from "../../../../ReduxStore/features/User/UserProfileSlice";
import { updateUserProfile } from "../../../../Apis/UserApis/userProfile";
import Loader from "../../../../Components/Loaders/MoonLoader";

interface ProfileCardProps {
  profileProp: string;
  profileValue: any;
  cardIcon: any;
  editForm: any;
  showEditPen?: boolean;
}

const SingleProfileDetail: FC<ProfileCardProps> = ({
  profileProp,
  profileValue,
  cardIcon,
  editForm,
  showEditPen,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { firstName, lastName, phoneNo, dob, gender, profileUrl, editingUserProfile } =
    useAppSelector(selectUserProfile);

  const handleSaveProfile = () => {
    updateUserProfile(firstName, lastName, phoneNo, dob, gender, profileUrl);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  return (
    <NameContainer>
      {cardIcon}
      <ProfileDetailContainer>
        <ProfileProp>{profileProp}</ProfileProp>
        <ProfileValueContainer>
          <p className="completename">{!isEditing && <>{profileValue}</>}</p>
          <>
            {isEditing && editForm}
            {isEditing && (
              <>
                <EditButton onClick={handleSaveProfile}>
                  {editingUserProfile ? <Loader size={2} /> : "Save"} 
                </EditButton>
                <EditButton onClick={handleCancelClick}>Cancel</EditButton>
              </>
            )}
          </>
          {!isEditing && showEditPen && (
            <FaPen fontSize={20} onClick={handleEditClick} className="fapen" />
          )}
        </ProfileValueContainer>
      </ProfileDetailContainer>
    </NameContainer>
  );
};

export default SingleProfileDetail;
