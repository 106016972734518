import { useEffect, useState } from "react";
import AudioSermonCard from "./AudioSermonCard";
import { AudioSermonContainer } from "./styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getSermonDetails,
  selectAdminChurchSermon,
} from "../../../../ReduxStore/features/Admin/SermonSlice";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import Paginator from "../../../../Components/Reusables/Pagintaor";

const AudioSermon = () => {
  const { gettingAdminChurchSermons, adminChurchSermons } = useAppSelector(
    selectAdminChurchSermon
  );
  const [audioSermons, setAudioSermons] = useState<any>();
  const dispatch = useAppDispatch();

  const handlePagination = (e: any) => {
    dispatch(getSermonDetails({ name: "pageId", value: e.selected + 1 }));
  };

  useEffect(() => {
    const audioSermonFilter = adminChurchSermons?.itemsList?.filter(
      (x: any) => x.sermonType === "audio"
    );
    setAudioSermons(audioSermonFilter);
  }, [adminChurchSermons]);

  return (
    <>
      <AudioSermonContainer>
        {gettingAdminChurchSermons ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : audioSermons?.length > 0 ? (
          audioSermons?.map((item: any) => (
            <AudioSermonCard audioTitle={item?.title} audioSrc={item?.link} />
          ))
        ) : (
          <NoRecordDisplay
            message="Audio Sermon not Available"
            src="/assets/images/svg/working-remotly.svg"
          />
        )}
      </AudioSermonContainer>
      <Paginator
        currentPage={adminChurchSermons?.paginator?.currentPage}
        pageCount={adminChurchSermons?.paginator?.pageCount}
        itemCount={adminChurchSermons?.paginator?.itemCount}
        hasNextPage={adminChurchSermons?.paginator?.hasNextPage}
        hasPrevPage={adminChurchSermons?.paginator?.hasPrevPage}
        handlePageChange={handlePagination}
      />
    </>
  );
};

export default AudioSermon;
