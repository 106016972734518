import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setAnnouncements,
  setCreatingAnnouncement,
  setDeletingAnnouncements,
  setEditingAnnouncements,
} from "../../ReduxStore/features/Admin/AnnouncementSlice";
import api from "../baseUrl";
import { setGettingAnnouncements } from "../../ReduxStore/features/User/AnnouncementSlice";
import { handleModal } from "../../ReduxStore/features/navSlice";

export const CreateAnnouncement = async (
  churchId: string,
  groupId: string,
  title: string,
  message: string,
) => {
  store.dispatch(setCreatingAnnouncement(true));
  const body: any = {
    churchId,
    groupId,
    title,
    message,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null || body[key] === "") {
      delete body[key];
    }
  });
  await api
    .post("user/church/announcement/create", body)
    .then((res) => {
      store.dispatch(setCreatingAnnouncement(false));
      toast?.success("Successful");
      store.dispatch(handleModal(0));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingAnnouncement(false));
    });
};

export const getAnnouncements = async (churchId: string) => {
  store.dispatch(setGettingAnnouncements(true));
  const params: any = {
    churchId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/announcement", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setAnnouncements(data?.data));
      store.dispatch(setGettingAnnouncements(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAnnouncements(false));
    });
};

export const editAnnouncement = async (
  id: string,
  title: string,
  message: string,
) => {
  store.dispatch(setEditingAnnouncements(true));
  const body: any = {
    id,
    title,
    message,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .put("user/church/announcement/update", body)
    .then((res) => {
      store.dispatch(setEditingAnnouncements(false));
      store.dispatch(handleModal(0));
      toast.success("Successful");
    })
    .catch((err) => {
      if (err?.message === "Network Error") toast.error(err?.message);
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      store.dispatch(setEditingAnnouncements(false));
    });
};

export const deleteAnnouncement = async (id: string) => {
  store.dispatch(setDeletingAnnouncements(true));
  const body: any = {
    id,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .delete("user/church/announcement/delete", { data: body })
    .then((res) => {
      store.dispatch(setDeletingAnnouncements(false));
      toast.success("Successful");
    })
    .catch((err) => {
      if (err?.message === "Network Error") toast.error(err?.message);
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data || err?.response?.data?.data,
        );
      store.dispatch(setEditingAnnouncements(false));
    });
};
