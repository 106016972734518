import styled from "styled-components";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import CreateActivityModal from "../../../../Modals/Admin/CreateActivityModal";
import ChurchActivitiesTable from "../../../../Components/Tables/Admin/ChurchActivitiesTable";
import EditActivityModal from "../../../../Modals/Admin/EditActivityModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 0;
`;

const ManageEvents = () => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <PrimaryButton
        text="Create Activity"
        handleClick={(e: any) => {
          e.preventDefault();
          dispatch(handleModal(22));
        }}
      />
      <ChurchActivitiesTable />
      <CreateActivityModal />
      <EditActivityModal />
    </Container>
  );
};

export default ManageEvents;
