import styled from "styled-components";
import { media } from "./../../../../Screens";
import { theme } from "../../../../theme";

export const UserProfileContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);

  ${media.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  height: auto;

  ${media.phone} {
    gap: 10px;
    width: 100%;
  }

  .fauser {
    margin-top: 5px;
  }
`;

export const IndexWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const NameInputContainer = styled.div`
  width: 100%;
  .nameinput {
    margin-top: 3px;
    border: none;
    background: rgb(2514, 252, 254);
    font-size: 15px;
    width: 100%;
    font-weight: 400;
    padding: 10px 5px;

    &:focus {
      border: none;
    }
  }
`;

export const ChurchContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 20px;
  align-items: center;

  //   height: 40px;

  ${media.phone} {
    gap: 10px;
  }

  .fachurch {
    margin-top: 5px;
  }
`;

export const EditableChurchContainer = styled.select`
  background: white;
  width: 100%;
  padding: 10px 5px;
  border: none;

  .option {
    background: #b603c9;
    color: white;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 340px;
  margin-left: 70px;
  margin-top: 30px;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  ${media.phone} {
    margin-left: 0px;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: 30px;
  gap: 50px;

  .joingroup {
    background: #b603c9;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }
  .groups {
    color: #b603c9;
  }
`;
export const SecondSection = styled.div`
  display: flex;
  margin-top: 40px;
  margin-left: 30px;
  width: 280px;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px gray;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
`;

export const ActiveGroupContainer = styled.div``;

export const ThirdSection = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 30px;
  width: 280px;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px gray;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
`;

export const EventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  position: absolute;
  margin-left: 600px;
  margin-top: -20px;
  padding: 40px 30px;
  border-radius: 5px;
  ${media.phone} {
    display: none;
  }
`;

export const EventCaption = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -20px;
`;

export const EventIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventTopic = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const EventName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;
export const Sponsor = styled.div`
  position: absolute;
  margin-left: 600px;
  margin-top: 170px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;

  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 610px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: 205px;
  ${media.phone} {
    display: none;
  }
`;
export const Avatar = styled.div`
  // margin-left: 260px;
`;

export const AvatarGlass = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  background: #b603c9;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  div.useravatar {
    display: flex;
    flex-direction: column;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  .avatarusername {
    display: flex;
    margin-top: 10px;
    margin-left: -10px;
    font-size: 9px;
    width: 100px;
  }
`;

export const SwitchChurch = styled.div`
  display: flex;
  margin-top: 50px;
  position: relative;

  .switchchurchbutton {
    display: flex;
    // margin-top: 70px;
    border: none;
    border-radius: 7px;
    color: white;
    cursor: pointer;
    background: #b603c9;
    padding: 5px 10px;
  }
`;
export const ChurchListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 100%;
  background-color: #fff;
  min-height: 100px;
  margin-top: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  .option {
    background: #b603c9;
    color: white;
  }
  .fatime {
    margin-left: 10px;
    cursor: pointer;
  }

  select {
    min-width: 300px;
    padding: 10px;
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 2px;
    outline: none;
  }
`;

export const ProfileDetailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px gray;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  padding: 20px;
  width: 400px;
  gap: 20px;
  .fapen {
    cursor: pointer;
    color: gray;
  }

  ${media.phone} {
    width: 100%;
  }
`;

export const ProfileProp = styled.p`
  font-weight: bold;
`;
export const ProfileValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
`;

export const EditButton = styled.button`
  display: flex;
  border: none;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  background: #b603c9;
  padding: 5px 10px;
`;
