import React, { useState } from "react";
import { AvatarGlass } from "./styles";
import Button from "./Button";

interface UserProfileProps {
  name: string;
  avatarUrl: string;
  handleChange?: any;
}

const UserProfile: React.FC<UserProfileProps> = ({ name, avatarUrl }) => {
  const [avatar, setAvatar] = useState(avatarUrl);
  const [avatarUpload, setAvatarUpload] = useState(false);

  const handleAvatarClick = () => {
    // Code to change avatar when clicked goes here
    // For this example, we'll just toggle between two avatar images
    if (avatar === avatarUrl) {
      setAvatar("assets/images/CamIcon.png");
    } else {
      setAvatarUpload(true);
    }
  };

  return (
    <AvatarGlass>
      <div className="useravatar">
        <img
          className="avatar"
          src={avatar}
          alt={name}
          onClick={() => {
            handleAvatarClick();
          }}
        />
        {avatarUpload && <Button setState={setAvatarUpload} />}
        <p
          className="avatarusername"
          onClick={() => {
            setAvatarUpload(false);
          }}
        >
          {name}
        </p>
      </div>
    </AvatarGlass>
  );
};

export default UserProfile;
