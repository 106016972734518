import CustomModal from "../CustomModal";
import FormWrapper from "../../Layouts/FormWrapper";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { TextArea } from "../../Components/Form/inputs/styles";
import { adminEditAnnouncementInput } from "../../Utils/authInput";
import SelectBox from "../../Components/Form/inputs/SelectBox";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import {
  getAdminAnnouncementFormDetails,
  selectAdminAnnouncements,
} from "../../ReduxStore/features/Admin/AnnouncementSlice";
import { editAnnouncement } from "../../Apis/AdminApis/churchAnnouncement";

const EditAnnouncementModal = () => {
  const {
    announcementTitle,
    announcementText,
    editingAnnouncement,
    selectedAnnouncement,
  } = useAppSelector(selectAdminAnnouncements);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminAnnouncementFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminAnnouncementFormDetails({ name, value }));
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAdminAnnouncementFormDetails({ name, value }));
  };

  return (
    <CustomModal
      id={9}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {adminEditAnnouncementInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  defaultValue={input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <SelectBox
                  options={input?.options}
                  name={input?.name}
                  // defaultValue={input?.defaultValue()}
                  handleSelect={handleSelect}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Update"
            handleClick={(e: any) => {
              e.preventDefault();
              editAnnouncement(
                selectedAnnouncement?._id,
                announcementTitle,
                announcementText
              );
            }}
            loading={editingAnnouncement}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditAnnouncementModal;
