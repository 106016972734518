import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setChurchDonations,
  setCreatingChurchDonation,
  setGettingChurchDonations,
} from "../../ReduxStore/features/Admin/DonationsSlice";
import api from "../baseUrl";

export const getDonation = async (
  status?: string,
  typeOfDonation?: string,
  currencyId?: string,
  churchId?: string,
  bankAccountId?: string,
) => {
  store.dispatch(setGettingChurchDonations(true));
  const params: any = {
    status,
    typeOfDonation,
    currencyId,
    churchId,
    bankAccountId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("admin/donations", { params })
    .then((res) => {
      const { data } = res;
      if (data) store.dispatch(setChurchDonations(data?.data));
      store.dispatch(setGettingChurchDonations(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingChurchDonations(false));
    });
};
export const editDonation = async (
  donationId: string,
  bankAccountId: string,
  currencyId: string,
  churchGroupId: string,
  description: string,
  targetAmount: string,
  currentAmount: string,
  typeOfDonation: string,
  status: string,
) => {
  const body = {
    donationId,
    bankAccountId,
    currencyId,
    churchGroupId,
    description,
    targetAmount,
    currentAmount,
    typeOfDonation,
    status,
  };
  await api
    .put("admin/donations", body)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteDonation = async (donationId: string) => {
  const params = {
    donationId,
  };
  await api
    .delete("admin/donations", { params })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const AddDonation = async (
  title: string,
  typeOfDonation: string,
  description: string,
  targetAmount: number,
  currencyId: string,
  bankAccountId: string,
  churchGroupId: string,
) => {
  store.dispatch(setCreatingChurchDonation(true));
  const body = {
    title,
    typeOfDonation,
    description,
    targetAmount,
    currencyId,
    bankAccountId,
    churchGroupId,
  };
  await api
    .post("admin/donations", body)
    .then((res) => {
      store.dispatch(setCreatingChurchDonation(false));
      toast.success(res?.data?.message);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.errorDetails || err?.response?.data?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCreatingChurchDonation(false));
    });
};
