import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface BibleState {
  gettingBibleNames: boolean;
  bibleNames: any;
  gettingBibleChapters: boolean;
  bibleChapters: any;
  gettingBibleReading: boolean;
  bibleReading: any;

  // bible states
  book: string;
  bookId: string;
  chapter: string;
}

const initialState: BibleState = {
  gettingBibleNames: false,
  bibleNames: null,
  gettingBibleChapters: false,
  bibleChapters: null,
  gettingBibleReading: false,
  bibleReading: null,
  // bible states
  book: "",
  chapter: "1",
  bookId: "1",
};

export const BibleSlice = createSlice({
  name: "Bible",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingBibleNames: (state, action) => {
      state.gettingBibleNames = action.payload;
    },
    setBibleNames: (state, action) => {
      state.bibleNames = action.payload;
    },
    setGettingBibleChapters: (state, action) => {
      state.gettingBibleChapters = action.payload;
    },
    setBibleChapters: (state, action) => {
      state.bibleChapters = action.payload;
    },
    setGettingBibleReading: (state, action) => {
      state.gettingBibleReading = action.payload;
    },
    setBibleReading: (state, action) => {
      state.bibleReading = action.payload;
    },
    getBibleFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingBibleNames,
  setBibleNames,
  setGettingBibleChapters,
  setBibleChapters,
  setGettingBibleReading,
  setBibleReading,
  getBibleFormDetails,
} = BibleSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBible = (state: RootState) => state.Bible;

export default BibleSlice.reducer;
