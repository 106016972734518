import React, { useEffect, useState } from "react";
// import ActivityButton from "./ActivityButton";
// import EventButton from "./EventButton";
// import { TopItems } from "./style";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
// import { setCurrentTab } from "../../../../ReduxStore/features/navSlice";
import { selectAdminActivities } from "../../../../ReduxStore/features/Admin/ActivitiesSlice";
import { getChurchActivities, getChurchEvents } from "../../../../Apis/AdminApis/ChurchActivities";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import styled from "styled-components";
import { theme } from "../../../../theme";

const Activities = () => {
  const { churchEvents, churchActivities } = useAppSelector(selectAdminActivities);
  const [activities, setActivities] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventData, setEventData] = useState([]);
  // const dispatch = useAppDispatch();

  useEffect(() => {
    const sortedEvents = churchEvents?.itemsList?.map((item: any) => {
      return {
        title: item?.title,
        start: moment(item?.startDate).toDate(),
        end: moment(item?.endDate).toDate(),
      };
    });
    setEvents(sortedEvents);

    const sortedActivities = churchActivities?.itemsList?.map((item: any) => {
      return {
        title: item?.title,
        start: moment(item?.startDate).toDate(),
        end: moment(item?.endDate).toDate(),
      };
    });
    setActivities(sortedActivities);
  }, [churchActivities, churchEvents]);

  useEffect(() => {
    getChurchActivities();
    getChurchEvents();
  }, []);

  useEffect(() => {
    events && activities && setEventData(activities?.concat(events));
  }, [activities, events]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "20px",
      }}
    >
      {/* <TopItems>
        <ActivityButton
          handleClick={(e: any) => {
            e.preventDefault();
            dispatch(setCurrentTab(2));
          }}
          text="Add church activity"
        />
        <EventButton
          handleClick={(e: any) => {
            e.preventDefault();
            dispatch(setCurrentTab(3));
          }}
          text="Add church event"
        />
      </TopItems> */}

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={eventData?.length > 0 ? eventData : []}
        headerToolbar={{ left: "prev,next today", center: "title", right: "dayGridMonth,timeGridWeek,timeGridDay" }}
        eventContent={renderEventContent}
        eventBackgroundColor="transparent"
      />
    </div>
  );
};

export default Activities;

function renderEventContent(eventInfo: any) {
  const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${theme?.colors?.primaryColor};
    width: 100%;
    flex-wrap: wrap;
  `;

  return (
    <Container>
      <b>{eventInfo?.timeText}</b>
      <b>{eventInfo?.event?.title}</b>
    </Container>
  );
}
