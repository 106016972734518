import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import {
  setEditingUserProfile,
  setGettingUserProfile,
  setUserProfile,
} from "../../ReduxStore/features/User/UserProfileSlice";
import api from "./../baseUrl";

export const updateUserDateOfBirth = async (birthday: string) => {
  const body = {
    birthday,
  };
  await api
    .post("user/profile/update-dob", body)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateUserProfile = async (
  firstName: string,
  lastName: string,
  phoneNo: string,
  dob: string,
  gender: string,
  profileUrl: string
) => {
  store.dispatch(setEditingUserProfile(true));
  const body: any = {
    firstName,
    lastName,
    phoneNo,
    dob,
    gender,
    profileUrl,
  };
  Object.keys(body)?.forEach((key) => {
    if (body[key] === null) {
      delete body[key];
    }
  });
  await api
    .patch("user/profile", body)
    .then(() => {
      store.dispatch(setEditingUserProfile(false));
      toast?.success("Profile Saved");
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setEditingUserProfile(false));
    });
};

export const getUserActiveChurch = async () => {
  store.dispatch(setGettingUserProfile(true));
  await api
    .get("user/profile/active-church")
    .then((res) => {
      const { data } = res;
      store.dispatch(setUserProfile(data?.data));
      store.dispatch(setGettingUserProfile(false));
    })
    .catch((err) => {
      // console.log(err);
      store.dispatch(setGettingUserProfile(false));
    });
};

export const checkIfUserJoinedChurch = async () => {
  const res = await api.get("user/profile/active-church");
  return res;
};

export const changeChurch = async (churchId: string) => {
  store.dispatch(setGettingUserProfile(true));
  const body = {
    churchId,
  };
  await api
    .post("user/profile/switch-church", body)
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingUserProfile(false));
      toast.success(data?.message);
      window.location.reload();
    })
    .catch((err) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingUserProfile(false));
    });
};
