import React, { FC } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "styled-components";
import { theme } from "../theme";
import { media } from "../Screens";
import { useAppDispatch, useAppSelector } from "../ReduxStore/app/hooks";
import {
  selectAdminWallet,
  setSelectedWallet,
} from "../ReduxStore/features/Admin/WalletSlice";
import Loader from "../Components/Loaders/MoonLoader";
import { deleteBankAccount } from "../Apis/AdminApis/Wallet";
import { handleModal } from "../ReduxStore/features/navSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  min-height: 206px;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.102));
  padding: 20px;
  border-radius: 10px;
  border-top: 2px solid ${theme.colors.primaryColor};

  ${media.phone} {
    width: 90vw;
  }
`;
const TopItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const DestinationAccount = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.secondaryColor};
`;
const Detail = styled.div`
  line-height: 23px;
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.secondaryColor};
`;

const BottomItems = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;

interface IAccountCard {
  accountyDetails?: any;
}

const AccountCard: FC<IAccountCard> = ({ accountyDetails }) => {
  const { deletingWallet } = useAppSelector(selectAdminWallet);
  const dispatch = useAppDispatch();

  return (
    <Container>
      <TopItems>
        <DestinationAccount>{accountyDetails?.accountName}</DestinationAccount>
        <Detail>Account Number: {accountyDetails?.accountNumber}</Detail>
        <Detail>Bank: {accountyDetails?.bankName}</Detail>
      </TopItems>
      <BottomItems>
        <FiEdit
          onClick={() => {
            dispatch(handleModal(12));
            dispatch(setSelectedWallet(accountyDetails));
          }}
        />
        {deletingWallet ? (
          <Loader />
        ) : (
          <RiDeleteBin6Line
            onClick={() => deleteBankAccount(accountyDetails?._id)}
          />
        )}
      </BottomItems>
    </Container>
  );
};
export default AccountCard;
