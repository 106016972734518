import React from "react";
import { Books } from "./styles";
import styled from "styled-components";
import { theme } from "../../../../theme";

// import { BibleVerse } from "../Home/styles";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../ReduxStore/app/hooks";
import {
  getBibleFormDetails,
  selectBible,
} from "../../../../ReduxStore/features/BibleSlice";

const BibleChaptersContainer = styled.ul`
  display: block;
  padding: 10px;
  max-height: 300px;
  width: 300px;
  border-radius: 10px;
  background: ${theme.colors.bgWhite};
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

interface I {
  setSelectedName: any;
  close: any;
}

const BibleApp: React.FC<I> = ({ setSelectedName, close }) => {
  const { bibleNames } = useAppSelector(selectBible);
  const dispatch = useAppDispatch();

  return (
    <Books>
      <BibleChaptersContainer>
        {bibleNames?.itemsList.map((chapter: any) => (
          <li
            className="list"
            key={chapter?.bookId}
            onClick={() => {
              setSelectedName(chapter?.book);
              close(false);
              dispatch(
                getBibleFormDetails({ name: "book", value: chapter?._id }),
              );
              dispatch(
                getBibleFormDetails({ name: "bookId", value: chapter?.bookId }),
              );
            }}
            style={{ listStyle: "none" }}
          >
            {chapter.book}
          </li>
        ))}
      </BibleChaptersContainer>
    </Books>
  );
};

export default BibleApp;
