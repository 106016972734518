import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface WalletState {
  gettingWallets: boolean;
  Wallets: any;
  gettingBankAccounts: boolean;
  bankAccounts: any;
  editingWallet: boolean;
  creatingAccount: boolean;
  deletingWallet: boolean;
  selectedWallet: any;
  gettingAdminFinancialReport: any;
  adminFinancialReport: any;

  // editing form states: any;
  visibility: string;

  // adding account states
  accountNumber: string;
  bankName: string;
  accountName: string;
  accountDescription: string;
  accountVisibility: string;
}

const initialState: WalletState = {
  gettingWallets: false,
  Wallets: null,
  gettingBankAccounts: false,
  bankAccounts: null,
  editingWallet: false,
  creatingAccount: false,
  deletingWallet: false,
  selectedWallet: null,
  gettingAdminFinancialReport: false,
  adminFinancialReport: null,

  // editing form states
  visibility: "",

  // adding account state
  accountNumber: "",
  bankName: "",
  accountName: "",
  accountDescription: "",
  accountVisibility: "visible",
};

export const WalletSlice = createSlice({
  name: "Wallet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGettingWallet: (state, action) => {
      state.gettingWallets = action.payload;
    },
    setWallet: (state, action) => {
      state.Wallets = action.payload;
    },
    setGettingBankAccounts: (state, action) => {
      state.gettingBankAccounts = action.payload;
    },
    setBankAccounts: (state, action) => {
      state.bankAccounts = action.payload;
    },
    setEditingWallet: (state, action) => {
      state.editingWallet = action.payload;
    },
    setGettingAdminFinancialReport: (state, action) => {
      state.gettingAdminFinancialReport = action.payload;
    },
    setAdminFinancialReport: (state, action) => {
      state.adminFinancialReport = action.payload;
    },
    setSelectedWallet: (state, action) => {
      state.selectedWallet = action.payload;
      state.accountNumber = action.payload.accountNumber;
      state.accountName = action.payload.accountName;
      state.bankName = action.payload.bankName;
      state.accountVisibility = action.payload.visibility;
      state.accountDescription = action.payload.description;
    },
    setDeletingWallet: (state, action) => {
      state.deletingWallet = action.payload;
    },
    setCreatingAccount: (state, action) => {
      state.creatingAccount = action.payload;
    },
    getWalletFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingWallet,
  setWallet,
  setGettingBankAccounts,
  setBankAccounts,
  setEditingWallet,
  setCreatingAccount,
  setDeletingWallet,
  setSelectedWallet,
  getWalletFormDetails,
  setGettingAdminFinancialReport,
  setAdminFinancialReport,
} = WalletSlice.actions;

// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAdminWallet = (state: RootState) => state.adminWallet;

export default WalletSlice.reducer;
