import React, { useEffect } from "react";
import FormWrapper from "../Layouts/FormWrapper";
import CustomModal from "./CustomModal";
import { CreateRoleInput } from "./../Utils/authInput";
import { TextArea } from "./../Components/Form/inputs/styles";
import TextInput from "./../Components/Form/inputs/TextInput";
import PrimaryButton from "./../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../ReduxStore/app/hooks";
import {
  getConfigurationsFormDetails,
  selectSuperAdminConfigurations,
} from "../ReduxStore/features/SuperAdmin/ConfigurationsSlice";
import { useDispatch } from "react-redux";
// import SelectBox from "./../Components/Form/inputs/SelectBox";
import { createAppRole } from "./../Apis/SuperAdminApis/settings";

const CreateRoleModal = () => {
  const { roleTitle, roleDescription, creatingRole } = useAppSelector(
    selectSuperAdminConfigurations
  );
  const dispatch = useDispatch();

  useEffect(() => {}, [roleTitle, roleDescription]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getConfigurationsFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getConfigurationsFormDetails({ name, value }));
  };

  // const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   dispatch(getConfigurationsFormDetails({ name, value }));
  // };

  return (
    <CustomModal
      id={1}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {CreateRoleInput?.map((input) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  defaultValue={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                  />
                </div>
              )}
            </>
          ))}
          <PrimaryButton
            text="Create"
            handleClick={(e: any) => {
              e.preventDefault();
              createAppRole(roleTitle, roleDescription);
            }}
            loading={creatingRole}
          />
        </FormWrapper>
      }
    />
  );
};

export default CreateRoleModal;
