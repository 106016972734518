import { toast } from "react-toastify";
import { store } from "../../ReduxStore/app/store";
import api from "../baseUrl";
import {
  setChurchReflection,
  setGettingChurchReflections,
  setUpdatingChurchReflection,
} from "../../ReduxStore/features/Admin/ChurchPrayerWallSlice";

export const getChurchDailyReflection = async (
  day?: string,
  month?: string,
  year?: string,
  status?: string,
  page?: number,
  pageSize?: number
) => {
  store.dispatch(setGettingChurchReflections(true));
  const params: any = {
    day,
    month,
    year,
    status,
    page,
    pageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || params[key] === "") {
      delete params[key];
    }
  });
  await api
    .get("admin/Church-reflections", { params })
    .then((res) => {
      const { data } = res?.data;
      store.dispatch(setChurchReflection(data));
      store.dispatch(setGettingChurchReflections(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingChurchReflections(false));
    });
};

export const createChurchDailyReflection = async (
  title: string,
  date: string,
  studyVerses: string[],
  text: string,
  status?: string
) => {
  store.dispatch(setUpdatingChurchReflection(true));
  const body: any = {
    day: new Date(date).getDay(),
    month: new Date(date).getMonth(),
    year: new Date(date).getFullYear(),
    status,
    title,
    studyVerses,
    text,
  };
  await api
    .post("admin/Church-reflections", body)
    .then(() => {
      toast.success("Successful");
      store.dispatch(setUpdatingChurchReflection(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingChurchReflection(false));
    });
};

export const editChurchDailyReflection = async (
  id: string,
  title: string,
  studyVerses: string[],
  text: string,
  status?: string,
  day?: string,
  month?: string,
  year?: string
) => {
  store.dispatch(setUpdatingChurchReflection(true));
  const body: any = {
    day,
    month,
    year,
    status,
    title,
    studyVerses,
    text,
  };
  await api
    .patch(`admin/Church-reflections/${id}`, body)
    .then((res) => {
      const { data } = res?.data;
      toast.success(data?.message);
      store.dispatch(setUpdatingChurchReflection(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingChurchReflection(false));
    });
};

export const deleteChurchDailyReflection = async (id: string) => {
  store.dispatch(setUpdatingChurchReflection(true));
  const params: any = {
    id,
  };
  await api
    .delete(`admin/Church-reflections/${id}`, { params })
    .then((res) => {
      const { data } = res?.data;
      toast.success(data?.message);
      store.dispatch(setUpdatingChurchReflection(false));
    })
    .catch((err: any) => {
      if (err?.response)
        toast.error(
          err?.response?.data?.errorDetails || err?.response?.data?.data
        );
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingChurchReflection(false));
    });
};
