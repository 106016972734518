/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
// import { BsFillChatFill } from "react-icons/bs";
import styled from "styled-components";
import { theme } from "../theme";
import { media } from "../Screens";
import Loader from "../Components/Loaders/MoonLoader";
import { getLikes } from "../Apis/UserApis/prayerRequests";
import { useAppSelector } from "../ReduxStore/app/hooks";
import { selectUserPrayerWall } from "../ReduxStore/features/User/PrayerWallSlice";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: auto !important;
  background: ${theme.colors.bgWhite};
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.051));
  padding: 20px;
  border-radius: 5px;
  position: relative;
  ${media.phone} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const TopItems = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Date = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.secondaryColor};
`;
const User = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: #151c4d;
`;

const Reading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #151c4d;
  margin-bottom: 25px;
`;

const PrayerPoint = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #151c4d;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
  bottom: 0;
  position: absolute;
  padding: 10px;
  width: 90%;
`;

const BottomAction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;

  svg {
    cursor: pointer;
    font-size: 18px;
  }
`;

const UserProfilePhoto = styled.span`
  display: flex;
  align-items: center;
`;

const UserProfilePhoto2 = styled.div`
  margin-left: -10px;
  border: 3px solid #fff;
  border-radius: 50%;
`;

const PeoplePrayed = styled.div`
  margin-left: 10px;
`;

const PrayerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
  cursor: pointer;
`;

const SmallActionText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  text-decoration-line: underline;
  text-decoration-color: ${theme.colors.primaryColor};
  color: ${theme.colors.primaryColor};
  cursor: pointer;
`;

interface IRequestCard {
  user?: any;
  date?: any;
  prayerPoint?: string;
  reading?: string;
  userProfilePhoto?: any;
  userProfilePhoto2?: any;
  peoplePrayed?: number;
  prayerIcon?: any;
  setAdd?: any;
  setEdit?: any;
  handleApprove?: any;
  handleDiscard?: any;
  status?: string;
  updating?: boolean;
  requestId?: string;
  visible?: boolean;
  editAction?: any;
  deleteAction?: any;
}

const RequestCard: FC<IRequestCard> = ({
  user,
  date,
  prayerPoint,
  reading,
  userProfilePhoto,
  userProfilePhoto2,
  peoplePrayed,
  prayerIcon,
  handleApprove,
  handleDiscard,
  status,
  updating,
  requestId,
  visible,
  editAction,
  deleteAction,
}) => {
  const [likes, setLikes] = useState<any>(0);
  const { likingPrayerRequests } = useAppSelector(selectUserPrayerWall);

  const getRequestLikes = async () => {
    const likesData: any = requestId && (await getLikes(requestId));
    setLikes(likesData?.data?.totalLikesCounts);
  };

  useEffect(() => {
    getRequestLikes();
  }, [requestId, likingPrayerRequests]);

  return (
    <Container>
      <TopItems>
        {user && <User>{user}</User>}
        <Date>{date}</Date>
      </TopItems>
      <PrayerPoint>{prayerPoint}</PrayerPoint>
      <Reading>{reading}</Reading>
      {(userProfilePhoto || peoplePrayed) && (
        <BottomSection>
          <UserProfilePhoto>
            <>
              <UserProfilePhoto2>{userProfilePhoto}</UserProfilePhoto2>
              <UserProfilePhoto2>{userProfilePhoto2}</UserProfilePhoto2>
            </>
            {peoplePrayed && (
              <PeoplePrayed>
                {likes || 0} {likes === 1 ? "Person" : "people"} prayed
              </PeoplePrayed>
            )}
          </UserProfilePhoto>
          <PrayerIcon>{prayerIcon}</PrayerIcon>
        </BottomSection>
      )}

      <BottomSection style={{ justifyContent: "flex-start" }}>
        {handleApprove && (
          <>
            {visible || status === `active` ? (
              <SmallActionText
                style={{ color: "#058228", textDecoration: "none" }}
              >
                Approved
              </SmallActionText>
            ) : (
              <>
                {updating ? (
                  <Loader color={theme?.colors.primaryColor} size={8} />
                ) : (
                  <SmallActionText onClick={handleApprove}>
                    Approve
                  </SmallActionText>
                )}
              </>
            )}
          </>
        )}
        {handleDiscard && (
          <>
            {!visible || status === "declined" || status === "pending" ? (
              <SmallActionText
                style={{ color: "#fb0606", textDecoration: "none" }}
              >
                Unapproved
              </SmallActionText>
            ) : (
              <>
                {updating ? (
                  <Loader color={theme?.colors.primaryColor} size={8} />
                ) : (
                  <SmallActionText onClick={handleDiscard}>
                    Discard
                  </SmallActionText>
                )}
              </>
            )}
          </>
        )}
        <BottomAction>
          {editAction && <FaEdit onClick={editAction} />}
          {deleteAction && <MdDelete onClick={deleteAction} />}
        </BottomAction>
      </BottomSection>
    </Container>
  );
};

export default RequestCard;
