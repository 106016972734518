import styled from "styled-components";
import { media } from "./../../../../Screens";
import { theme } from "../../../../theme";

export const ReflectionContainer = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  // margin-left: -20%;
  width: 100%;
  gap: 2 0px;

  .left-content {
    flex: 0.8;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    ${media.tablet} {
      flex: 1;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .right-content {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${media.phone} {
      display: none;
    }
  }

  ${media.phone} {
    // margin-left: -50px;
  }

  div.ad {
    // position: absolute;
    // margin-left: 1200px;
    // margin-top: -80px;
    border: 2px solid;
    height: 173px;
    width: 173px;
    border-radius: 5%;

    ${media.phone} {
      display: none;
    }
  }
  img.adimage {
    border-radius: 5px;
  }
`;

export const Date = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #151c4d;
  // margin-left: -50px;
  margin-top: 20px;
  .fasortdown {
    font-size: 15px;
    margin-left: 15px;
    cursor: pointer;
  }
`;

export const Church = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 21px;
  color: #151c4d;
  margin-left: -120px;
  .fasortdown {
    font-size: 15px;
    margin-left: 15px;
    cursor: pointer;
  }
`;

export const Slate = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  background: white;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 100, 0.2);
  // margin-left: 170px;
  // margin-top: 25px;
  padding: 15px 15px;
  margin-top: 20px;

  ${media.phone} {
    // margin-left: 90px;
  }
`;

export const TopItems = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RightItems = styled.div``;

export const StudyVerse = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #b603c9;
  cursor: pointer;
`;

export const BibleBook = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #151c4d;
`;

export const LeftItems = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 5px;
  .fashare {
    font-size: 10px;
    opacity: 0.6;
    cursor: pointer;
  }
  .famedical {
    font-size: 10px;
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const ReflectionDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  // line-height: 13px;
  color: #151c4d;
  padding-right: 10px;

  ${media.phone} {
    width: 300px;
  }
`;

export const EventNote = styled.div`
  background: #b603c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 174px;
  // position: absolute;
  // margin-left: 1200px;
  // margin-top: -500px;
  padding: 40px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  ${media.phone} {
    display: none;
  }
`;

export const EventCaption = styled.div`
  display: flex;
  gap: 5px;
  // margin-top: -20px;
`;

export const EventIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventTopic = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const Activity = styled.div`
  margin-top: 10px;
  border-bottom: 1px solid white;
  width: 100%;
`;

export const EventName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;

export const EventDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 15px;
  color: #ffffff;
`;
export const Sponsor = styled.div`
  position: absolute;
  margin-left: 1080px;
  margin-top: -290px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #151c4d;

  ${media.phone} {
    display: none;
  }
`;

export const AdText = styled.div`
  position: absolute;
  margin-left: 1100px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  margin-top: -220px;
  ${media.phone} {
    display: none;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  position: static;
  bottom: 10px;
  svg {
    cursor: pointer;
    color: ${theme.colors.borderColor};
  }
`;


export const ReflectionTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`;