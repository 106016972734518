import { store } from "../../ReduxStore/app/store";
import {
  setBibleChapters,
  setBibleNames,
  setBibleReading,
  setGettingBibleChapters,
  setGettingBibleNames,
  setGettingBibleReading,
} from "../../ReduxStore/features/BibleSlice";
import api from "../baseUrl";

export const getBibleNames = async (pageId?: number, perPage?: number) => {
  store.dispatch(setGettingBibleNames(true));
  const params: any = {
    pageId,
    perPage,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/dailyscripture/biblenames", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleNames(data?.data));
      store.dispatch(setGettingBibleNames(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleNames(false));
    });
};

export const getBibleChapters = async (bookNameId?: string) => {
  store.dispatch(setGettingBibleChapters(true));
  const params: any = {
    bookNameId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/church/dailyscripture/bookchapter", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleChapters(data?.data));
      store.dispatch(setGettingBibleChapters(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleChapters(false));
    });
};

export const getBibleReading = async (book?: any, chapter?: any) => {
  store.dispatch(setGettingBibleReading(true));
  const params: any = {
    book,
    chapter,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("user/bible/chapter", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setBibleReading(data?.data));
      store.dispatch(setGettingBibleReading(false));
    })
    .catch((err) => {
      store.dispatch(setGettingBibleReading(false));
    });
};
