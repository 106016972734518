import { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { selectBible } from "../../../../ReduxStore/features/BibleSlice";
import { useAppSelector } from "../../../../ReduxStore/app/hooks";
import { getBibleReading } from "../../../../Apis/UserApis/Bible";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { media } from "../../../../Screens";

const Container = styled.div`
  display: flex;
  background-color: ${theme.colors.bgWhite};
  border-radius: 10px;
  padding: 10px;
  height: 80vh;
  width: 100%;
  overflow-y: auto;

  ${media.phone} {
    width: 95vw;
  }
`;

const ChaptersContainer = styled.ul`
  display: flex;
  list-style-type: none;
  flex-direction: column;
  gap: 20px;
`;

const Chapter = styled.li`
  color: ${theme.colors.secondaryColor};
  font-size: 14px;
`;

const BibleReading = () => {
  const { bookId, chapter, gettingBibleReading, bibleReading } =
    useAppSelector(selectBible);

  useEffect(() => {
    bookId && chapter && getBibleReading(bookId, chapter);
  }, [bookId, chapter]);

  return (
    <Container>
      {gettingBibleReading ? (
        <Loader size={20} color={theme.colors.primaryColor} />
      ) : (
        <ChaptersContainer>
          {bibleReading?.map((item: any) => (
            <Chapter>
              <span style={{ fontWeight: "bold", marginRight: "15px" }}>
                {item?.verse}
              </span>
              {item?.text}
            </Chapter>
          ))}
        </ChaptersContainer>
      )}
    </Container>
  );
};

export default BibleReading;
