import { useSelector } from "react-redux";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import GroupCardLayout from "../../../../Layouts/GroupCardLayout";
import GroupDetails from "./GroupDetails";
import { GroupCardContainer, GroupContainer } from "./style";
import { selectAdminGroups, setSelectedGroup } from "../../../../ReduxStore/features/Admin/GroupSlice";
import { useEffect } from "react";
import { getGroupList } from "../../../../Apis/AdminApis/churchGroup";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import Loader from "../../../../Components/Loaders/MoonLoader";
import { theme } from "../../../../theme";
import { useAppDispatch } from "../../../../ReduxStore/app/hooks";
import { handleModal } from "../../../../ReduxStore/features/navSlice";

function App() {
  const { gettingChurchGroups, gettingGroupDetails, churchGroups, selectedGroup, creatingChurchGroup } = useSelector(selectAdminGroups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getGroupList();
  }, [creatingChurchGroup]);

  return (
    <>
      <PrimaryButton text="Create Group" size="medium" handleClick={() => dispatch(handleModal(16))} />
      <GroupContainer>
        <div className="left-content" style={selectedGroup && { flexDirection: `column`, flex: 0.3 }}>
          {gettingChurchGroups ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader color={theme?.colors.primaryColor} size={20} />
            </div>
          ) : (
            <>
              {churchGroups?.length > 0 ? (
                <GroupCardContainer>
                  {churchGroups?.map((group: any) => (
                    <GroupCardLayout groupName={group.name} groupStatus={group.status} memberCount={group.membersCount} handleClick={() => dispatch(setSelectedGroup(group))} />
                  ))}
                </GroupCardContainer>
              ) : (
                <NoRecordDisplay message="No Groups at the moment" src="/assets/images/svg/working-remotly.svg" />
              )}
            </>
          )}
        </div>
        <div className="right-content">
          <>
            {selectedGroup && (
              <>
                {gettingGroupDetails ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader color={theme?.colors.primaryColor} size={20} />
                  </div>
                ) : (
                  <GroupDetails />
                )}
              </>
            )}
          </>
        </div>
      </GroupContainer>
    </>
  );
}

export default App;
