import { useState } from "react";
import FormWrapper from "../../Layouts/FormWrapper";
import CustomModal from "../CustomModal";
import { editChurchReflectionInput } from "../../Utils/authInput";
import { TextArea, selectStyles } from "../../Components/Form/inputs/styles";
import TextInput from "../../Components/Form/inputs/TextInput";
import PrimaryButton from "../../Components/Form/Buttons/PrimaryButton";
import { useAppSelector } from "../../ReduxStore/app/hooks";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  addToStudyVerses,
  getADminChurchPrayerFormDetails,
  removeFromStudyVerses,
  selectAdminChurchPrayerWall,
} from "../../ReduxStore/features/Admin/ChurchPrayerWallSlice";
import {
  editChurchDailyReflection,
} from "../../Apis/AdminApis/DailyReflection";
import { FiMinusCircle } from "react-icons/fi";

const EditDailyReflectionModal = () => {
  const [verse, setVerse] = useState("");

  const {
    // reflectionDate,
    reflectionStatus,
    reflectionTitle,
    text,
    studyVerses,
    updatingChurchReflection,
    reflectionId,
  } = useAppSelector(selectAdminChurchPrayerWall);
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getADminChurchPrayerFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getADminChurchPrayerFormDetails({ name, value }));
  };

  const handleSelect = (value: any, name: string) => {
    dispatch(getADminChurchPrayerFormDetails({ name, value: value?.value }));
  };

  return (
    <CustomModal
      id={12}
      content={
        <FormWrapper
          style={{
            // justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {editChurchReflectionInput?.map((input: any) => (
            <>
              {input?.type === "textarea" ? (
                <TextArea
                  placeholder={input.placeholder}
                  name={input?.name}
                  onChange={handleTextAreaChange}
                  defaultValue={input?.defaultValue()}
                />
              ) : input?.type === "select" ? (
                <Select
                  placeholder={input.placeholder}
                  name={input?.name}
                  styles={selectStyles}
                  options={input?.options()}
                  onChange={(value: any) => handleSelect(value, input?.name)}
                  defaultValue={input?.defaultValue()}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextInput
                    placeholder={input.placeholder}
                    type={input?.type}
                    name={input?.name}
                    handleChange={handleChange}
                    defaultValue={input?.defaultValue()}
                  />
                </div>
              )}
            </>
          ))}

          <TextInput
            placeholder={"Add Verse"}
            type="text"
            handleChange={(e: any) => setVerse(e.target.value)}
            value={verse}
          />
          <PrimaryButton
            text="Add Verse"
            handleClick={(e: any) => {
              e.preventDefault();
              dispatch(addToStudyVerses(verse));
            }}
            size="small"
          />
          {studyVerses?.map((verse: string) => (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p>{verse}</p>
              <FiMinusCircle
                onClick={(e: any) => {
                  e.preventDefault();
                  dispatch(removeFromStudyVerses(verse));
                }}
              />
            </div>
          ))}
          <PrimaryButton
            text="Edit"
            handleClick={(e: any) => {
              e.preventDefault();
              editChurchDailyReflection(
                reflectionId,
                reflectionTitle,
                studyVerses,
                text,
                reflectionStatus
              );
            }}
            loading={updatingChurchReflection}
          />
        </FormWrapper>
      }
    />
  );
};

export default EditDailyReflectionModal;
