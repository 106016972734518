import React, { useEffect } from "react";
import { LocationCardContainer, LocationMediumContainer, SectionTitle } from "./style";
import PrimaryButton from "../../../../Components/Form/Buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../../ReduxStore/app/hooks";
import Loader from "../../../../Components/Loaders/MoonLoader";
import NoRecordDisplay from "../../../../Components/Messages/NoRecordDisplay";
import { selectAdminLiveStream, setSelectedLiveStreamEvent } from "../../../../ReduxStore/features/Admin/LivestreamSlice";
import { theme } from "../../../../theme";
import CreateLivestreamEvent from "../../../../Modals/Admin/CreateLIvestreamEvent";
import { handleModal } from "../../../../ReduxStore/features/navSlice";
import EditLivestreamLink from "../../../../Modals/Admin/EditLivestreamLink";
import EditLiveStreamEvent from "../../../../Modals/Admin/EditLivestreamEvent";
import CreateLiveStreamLink from "../../../../Modals/Admin/CreateLivestreamLink";
import { deleteLivestreamEvent, getLivestreamEvent, getLivestreamLinks } from "../../../../Apis/AdminApis/LiveStream";
import LiveStreamCard from "../../../../Layouts/LivestreamEventCard";

const ManageLiveStreams = () => {
  const {
    gettingLiveStreamLinks,
    LiveStreamLinks,
    gettingLiveStreamEvent,
    LiveStreamEvents,
    editingLiveStreamEvent,
    deletingLiveStreamEvent,
    creatingLiveStreamEvent,
  } = useAppSelector(selectAdminLiveStream);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getLivestreamLinks();
    getLivestreamEvent();
  }, [editingLiveStreamEvent, deletingLiveStreamEvent, creatingLiveStreamEvent]);

  return (
    <LocationMediumContainer>
      <div className="top-content">
        <SectionTitle>LiveStream Links</SectionTitle>
        <PrimaryButton
          text="Add Link"
          size="medium"
          handleClick={() => {
            dispatch(handleModal(23));
          }}
        />
        {gettingLiveStreamLinks ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : (
          <>
            {LiveStreamLinks?.itemsList?.length > 0 ? (
              <LocationCardContainer>
                {LiveStreamLinks?.itemsList?.map(
                  (link: any, index: any) =>
                    // <RoleCard
                    //   title={
                    //     eventMediums?.itemsList?.filter(
                    //       (item: any) => item?._id === location?.medium
                    //     )[0]?.name
                    //   }
                    //   desc={location?.address}
                    //   status="active"
                    //   setDelete={() => {
                    //     deleteEventLocation(location?._id);
                    //   }}
                    //   setEdit={() => {
                    //     dispatch(setSelectedEventLocation(location));
                    //     dispatch(handleModal(20));
                    //   }}
                    // />
                    ""
                )}
              </LocationCardContainer>
            ) : (
              <NoRecordDisplay message="No Links Yet" />
            )}
          </>
        )}
      </div>
      <div className="bottom-content">
        <SectionTitle>LiveStream Events</SectionTitle>
        <PrimaryButton text="Add Event" size="medium" handleClick={() => dispatch(handleModal(25))} />
        {gettingLiveStreamEvent ? (
          <Loader color={theme.colors.primaryColor} size={20} />
        ) : (
          <>
            {LiveStreamEvents?.itemsList?.length > 0 ? (
              <LocationCardContainer>
                {LiveStreamEvents?.itemsList?.map((event: any) => (
                  <LiveStreamCard
                    title={event?.title}
                    desc={event?.description}
                    status={event?.visibility}
                    setDelete={() => {
                      deleteLivestreamEvent(event?._id);
                    }}
                    setEdit={() => {
                      dispatch(handleModal(24));
                      dispatch(setSelectedLiveStreamEvent(event));
                    }}
                  />
                ))}
              </LocationCardContainer>
            ) : (
              <NoRecordDisplay message="No Event Yet" />
            )}
          </>
        )}
        <EditLiveStreamEvent />
        <EditLivestreamLink />
        <CreateLiveStreamLink />
        <CreateLivestreamEvent />
      </div>
    </LocationMediumContainer>
  );
};

export default ManageLiveStreams;
